<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.pMS.editmode">Create Price Model</h2>
				<h2 *ngIf="this.pMS.editmode">Edit Price Model</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.pMS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/pricemodel">View Price Models</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Price Model</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.pMS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/pricemodel">View Price Models</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Create Price Model</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">

				<mat-form-field *ngIf="this.pMS.editmode">
    			<mat-label>Price Model - Edit</mat-label>
			    <mat-select placeholder="Price Model - Edit" [(ngModel)]="this.pMS.pricemodeledit" (keydown.tab)="this.pMS.loadForEdit()">
			      <mat-option *ngFor="let model of this.pMS.dDDS.dropdown.priceModelListAll" (click)="this.pMS.loadForEdit()" value="{{ model.modelno }}">{{ model.model }}</mat-option>
			    </mat-select>
			  </mat-form-field>

		  	<br *ngIf="this.pMS.editmode && editSelected()">

				<mat-form-field *ngIf="editSelected()">
    			<mat-label>Price Model Name</mat-label>
			    <input matInput placeholder="Price Model Name" maxlength="45" [(ngModel)]="this.pMS.pricemodelname">
			  </mat-form-field>

			  <mat-form-field>
    			<mat-label>Description</mat-label>
			    <input matInput placeholder="Description" maxlength="45"  [(ngModel)]="this.pMS.note">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Season</mat-label>
			    <input matInput type="number" placeholder="Season" [(ngModel)]="this.pMS.season">
			  </mat-form-field>

			  <mat-slide-toggle *ngIf="editSelected()" [disabled]="!this.pMS.canChangeDefault" class="slider-margin slider-label" [(ngModel)]="this.pMS.default">
	        {{ (this.pMS.canChangeDefault ? "Set as Default for the " + this.pMS.season + " season?" : "Default for the " + this.pMS.season + " season. Change under Season settings" ) }}
	      </mat-slide-toggle>

			  <mat-slide-toggle *ngIf="editSelected() && !this.pMS.canChangeDefault" class="slider-margin slider-label" [(ngModel)]="this.pMS.defaultDuplicate">For Duplicate ONLY - Set as Default for the {{ this.pMS.season }} season?
	      </mat-slide-toggle>

			  <ng-container *ngIf="editSelected()">

				  <hr class="thick">

				  <mat-form-field>
    				<mat-label>Pricing Format</mat-label>
				    <mat-select placeholder="Pricing Format" [(ngModel)]="this.pMS.format" (selectionChange)="this.pMS.changeMethod()">
				      <mat-option value="0">By Sample Only</mat-option>
				      <mat-option value="2">By Production Only</mat-option>
				      <mat-option value="1">By Production with Sample Estimate</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="this.pMS.format!==undefined">
    				<mat-label>Pricing Method</mat-label>
				    <mat-select placeholder="Pricing Method" [(ngModel)]="this.pMS.method" (selectionChange)="this.pMS.changeMethod()">
				      <mat-option *ngIf="this.pMS.format=='0'" value="8">Flat Rate /kg DNIS</mat-option>
				      <mat-option *ngIf="this.pMS.format=='0'" value="4">SKR</mat-option>
				      <mat-option *ngIf="this.pMS.format=='0'" value="6">SKR - Sized DNIS</mat-option>
				      <mat-option *ngIf="this.pMS.format=='0'" value="2">Styles (S0-S8)</mat-option>
				      <mat-option *ngIf="this.pMS.format!=='0'" value="5">Styles (S0-S8)</mat-option>
				      <mat-option *ngIf="this.pMS.format=='0'" value="3">Basic Styles (Wholes-Halves-Pieces)</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="this.pMS.format=='1'">
    				<mat-label>Estimated Pricing Method</mat-label>
				    <mat-select placeholder="Estimated Pricing Method" [(ngModel)]="this.pMS.estimated">
				      <!--<mat-option value="0">Not Applicable</mat-option>-->
				      <mat-option value="1">SKR</mat-option>
				      <!--<mat-option value="1">Styles (S0-S8)</mat-option>-->
				      <!--<mat-option value="2">Basic Styles (Wholes-Halves-Pieces)</mat-option>-->
				    </mat-select>
				  </mat-form-field>

				  <ng-container *ngIf="this.pMS.method!==undefined">

				  	<ng-container *ngIf="+this.pMS.method!=8">

					  	<hr class="thick">

					  	<div class="row justify-content-between">
								<div class="col" style="display: inline-block;">
						  		<h5 class="mt-2 mb-3">Gross Kernel Pricing</h5>
						  	</div>
								<div style="display: inline-block;">
								  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.grossbool">
						        Show
						      </mat-slide-toggle>
						    </div>
						  </div>

						  <mat-form-field>
						    <mat-label>Gross Price Currency</mat-label>
					    	<mat-select placeholder="Gross Price Currency" [(ngModel)]="this.pMS.grosspricecurrency">
						      <mat-option value="ZAR">ZAR</mat-option>
						      <mat-option value="USD">USD</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <ng-container *ngIf="this.pMS.grossbool && +this.pMS.method==6">

						    <hr>
								<h5 class="mt-2 mb-3">DNIS >25mm</h5>

								<div *ngFor="let item of this.pMS.grossdnis25; let itemindex=index">

						      <hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field *ngIf="this.pMS.method=='6'" [class.errorcheck]="checkEntry(skr.value,'grossdnis25',itemindex,'skr')">
										    <mat-label>SKR % (Greater than) [DNIS >25mm]</mat-label>
					    					<input matInput #skr placeholder="SKR % (Greater than) [DNIS >25mm]" [ngModel]="this.pMS.grossdnis25[itemindex]['skr'] | percent:'1.1'" (blur)="changeAmount(skr.value,'grossdnis25',itemindex,'skr',true)">
										  </mat-form-field>

											<ng-container *ngIf="this.pMS.grossdnis25[itemindex]['style']!==null && this.pMS.grossdnis25[itemindex]['style']!==undefined">
										  	<ng-container *ngIf="this.pMS.grossdnis25[itemindex]['style'].slice(0,1)=='N'">

												  <mat-form-field *ngIf="this.pMS.grossdnis25[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisskr.value,'grossdnis25',itemindex,'style')">
												    <mat-label>SKR % (Greater than)</mat-label>
					    							<input matInput #nisskr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.grossdnis25[itemindex]['skr'] | percent:'1.0'" (blur)="changeAmountCode(nisskr.value,'grossdnis25',itemindex,'skr',true)">
												  </mat-form-field>

												  <mat-form-field *ngIf="this.pMS.grossdnis25[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisuskr.value,'grossdnis25',itemindex,'style')">
												    <mat-label>USKR % (Lesser than)</mat-label>
					   								<input matInput #nisuskr placeholder="USKR % (Lesser than)" [ngModel]="this.pMS.grossdnis25[itemindex]['uskr'] | percent:'1.0'" (blur)="changeAmountCode(nisuskr.value,'grossdnis25',itemindex,'uskr',true)">
												  </mat-form-field>

												</ng-container>
											</ng-container>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('grossdnis25','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
							
										  <mat-form-field [class.errorcheck]="checkEntry(rate.value,'grossdnis25',itemindex,'rate',false,true)">
										    <mat-label>Rate</mat-label>
					    					<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.grossdnis25[itemindex]['rate'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'grossdnis25',itemindex,'rate',false,true)">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.grossdnis25.length>1" (click)="this.pMS.removeItem('grossdnis25',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>

						    <hr>
								<h5 class="mt-2 mb-3">DNIS >22mm</h5>

								<div *ngFor="let item of this.pMS.grossdnis22; let itemindex=index">

						      <hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field *ngIf="this.pMS.method=='6'" [class.errorcheck]="checkEntry(skr.value,'grossdnis22',itemindex,'skr')">
										    <mat-label>SKR % (Greater than) [DNIS >22mm]</mat-label>
					    					<input matInput #skr placeholder="SKR % (Greater than) [DNIS >22mm]" [ngModel]="this.pMS.grossdnis22[itemindex]['skr'] | percent:'1.1'" (blur)="changeAmount(skr.value,'grossdnis22',itemindex,'skr',true)">
										  </mat-form-field>

											<ng-container *ngIf="this.pMS.grossdnis22[itemindex]['style']!==null && this.pMS.grossdnis22[itemindex]['style']!==undefined">
										  	<ng-container *ngIf="this.pMS.grossdnis22[itemindex]['style'].slice(0,1)=='N'">

												  <mat-form-field *ngIf="this.pMS.grossdnis22[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisskr.value,'grossdnis22',itemindex,'style')">
												    <mat-label>SKR % (Greater than)</mat-label>
					    							<input matInput #nisskr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.grossdnis22[itemindex]['skr'] | percent:'1.0'" (blur)="changeAmountCode(nisskr.value,'grossdnis22',itemindex,'skr',true)">
												  </mat-form-field>

												  <mat-form-field *ngIf="this.pMS.grossdnis22[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisuskr.value,'grossdnis22',itemindex,'style')">
												    <mat-label>USKR % (Lesser than)</mat-label>
					   								<input matInput #nisuskr placeholder="USKR % (Lesser than)" [ngModel]="this.pMS.grossdnis22[itemindex]['uskr'] | percent:'1.0'" (blur)="changeAmountCode(nisuskr.value,'grossdnis22',itemindex,'uskr',true)">
												  </mat-form-field>

												</ng-container>
											</ng-container>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('grossdnis22','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
							
										  <mat-form-field [class.errorcheck]="checkEntry(rate.value,'grossdnis22',itemindex,'rate',false,true)">
										    <mat-label>Rate</mat-label>
					    					<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.grossdnis22[itemindex]['rate'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'grossdnis22',itemindex,'rate',false,true)">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.grossdnis22.length>1" (click)="this.pMS.removeItem('grossdnis22',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>
						     
						    <hr>
								<h5 class="mt-2 mb-3">DNIS >20mm</h5>
								
								<div *ngFor="let item of this.pMS.grossdnis20; let itemindex=index">

						      <hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field *ngIf="this.pMS.method=='6'" [class.errorcheck]="checkEntry(skr.value,'grossdnis20',itemindex,'skr')">
										    <mat-label>SKR % (Greater than) [DNIS >20mm]</mat-label>
					    					<input matInput #skr placeholder="SKR % (Greater than) [DNIS >20mm]" [ngModel]="this.pMS.grossdnis20[itemindex]['skr'] | percent:'1.1'" (blur)="changeAmount(skr.value,'grossdnis20',itemindex,'skr',true)">
										  </mat-form-field>

											<ng-container *ngIf="this.pMS.grossdnis20[itemindex]['style']!==null && this.pMS.grossdnis20[itemindex]['style']!==undefined">
										  	<ng-container *ngIf="this.pMS.grossdnis20[itemindex]['style'].slice(0,1)=='N'">

												  <mat-form-field *ngIf="this.pMS.grossdnis20[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisskr.value,'grossdnis20',itemindex,'style')">
												    <mat-label>SKR % (Greater than)</mat-label>
					    							<input matInput #nisskr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.grossdnis20[itemindex]['skr'] | percent:'1.0'" (blur)="changeAmountCode(nisskr.value,'grossdnis20',itemindex,'skr',true)">
												  </mat-form-field>

												  <mat-form-field *ngIf="this.pMS.grossdnis20[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisuskr.value,'grossdnis20',itemindex,'style')">
												    <mat-label>USKR % (Lesser than)</mat-label>
					   								<input matInput #nisuskr placeholder="USKR % (Lesser than)" [ngModel]="this.pMS.grossdnis20[itemindex]['uskr'] | percent:'1.0'" (blur)="changeAmountCode(nisuskr.value,'grossdnis20',itemindex,'uskr',true)">
												  </mat-form-field>

												</ng-container>
											</ng-container>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('grossdnis20','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
							
										  <mat-form-field [class.errorcheck]="checkEntry(rate.value,'grossdnis20',itemindex,'rate',false,true)">
										    <mat-label>Rate</mat-label>
					    					<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.grossdnis20[itemindex]['rate'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'grossdnis20',itemindex,'rate',false,true)">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.grossdnis20.length>1" (click)="this.pMS.removeItem('grossdnis20',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>

						    <hr>
								<h5 class="mt-2 mb-3">DNIS >18mm</h5>
								
								<div *ngFor="let item of this.pMS.grossdnis18; let itemindex=index">

						      <hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field *ngIf="this.pMS.method=='6'" [class.errorcheck]="checkEntry(skr.value,'grossdnis18',itemindex,'skr')">
										    <mat-label>SKR % (Greater than) [DNIS >18mm]</mat-label>
					    					<input matInput #skr placeholder="SKR % (Greater than) [DNIS >18mm]" [ngModel]="this.pMS.grossdnis18[itemindex]['skr'] | percent:'1.1'" (blur)="changeAmount(skr.value,'grossdnis18',itemindex,'skr',true)">
										  </mat-form-field>

											<ng-container *ngIf="this.pMS.grossdnis18[itemindex]['style']!==null && this.pMS.grossdnis18[itemindex]['style']!==undefined">
										  	<ng-container *ngIf="this.pMS.grossdnis18[itemindex]['style'].slice(0,1)=='N'">

												  <mat-form-field *ngIf="this.pMS.grossdnis18[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisskr.value,'grossdnis18',itemindex,'style')">
												    <mat-label>SKR % (Greater than)</mat-label>
					    							<input matInput #nisskr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.grossdnis18[itemindex]['skr'] | percent:'1.0'" (blur)="changeAmountCode(nisskr.value,'grossdnis18',itemindex,'skr',true)">
												  </mat-form-field>

												  <mat-form-field *ngIf="this.pMS.grossdnis18[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisuskr.value,'grossdnis18',itemindex,'style')">
												    <mat-label>USKR % (Lesser than)</mat-label>
					   								<input matInput #nisuskr placeholder="USKR % (Lesser than)" [ngModel]="this.pMS.grossdnis18[itemindex]['uskr'] | percent:'1.0'" (blur)="changeAmountCode(nisuskr.value,'grossdnis18',itemindex,'uskr',true)">
												  </mat-form-field>

												</ng-container>
											</ng-container>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('grossdnis18','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
							
										  <mat-form-field [class.errorcheck]="checkEntry(rate.value,'grossdnis18',itemindex,'rate',false,true)">
										    <mat-label>Rate</mat-label>
					    					<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.grossdnis18[itemindex]['rate'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'grossdnis18',itemindex,'rate',false,true)">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.grossdnis18.length>1" (click)="this.pMS.removeItem('grossdnis18',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>

						  </ng-container>

						  <ng-container *ngIf="this.pMS.grossbool && +this.pMS.method!=6">

							  <div *ngFor="let item of this.pMS.grossprices; let itemindex=index">

						      <hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field *ngIf="this.pMS.method=='4'" [class.errorcheck]="checkEntry(skr.value,'grossprices',itemindex,'skr')">
										    <mat-label>SKR % (Greater than)</mat-label>
					    					<input matInput #skr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.grossprices[itemindex]['skr'] | percent:'1.1'" (blur)="changeAmount(skr.value,'grossprices',itemindex,'skr',true)">
										  </mat-form-field>
										  
										  <mat-form-field *ngIf="this.pMS.method=='3'" [class.errorcheck]="checkEntry(grosssplit.value,'grossprices',itemindex,'style')">
										    <mat-label>Style</mat-label>
					    					<mat-select placeholder="Style" #grosssplit [(ngModel)]="this.pMS.grossprices[itemindex]['style']">
										      <mat-option value="0">Wholes</mat-option>
										      <mat-option value="1">Halves</mat-option>
										      <mat-option value="2">Bits & Pieces</mat-option>
										      <mat-option value="3">Darks</mat-option>
										      <mat-option value="4">S7</mat-option>
										      <mat-option value="5">S8</mat-option>
										    </mat-select>
										  </mat-form-field>
										  
										  <mat-form-field *ngIf="this.pMS.method=='2' || this.pMS.method=='5'" [class.errorcheck]="checkEntry(grossstyle.value,'grossprices',itemindex,'style')">
										    <mat-label>Style</mat-label>
					    					<mat-select placeholder="Style" #grossstyle [(ngModel)]="this.pMS.grossprices[itemindex]['style']">
										      <mat-option value="S0">S0</mat-option>
										      <mat-option value="S1L">S1L</mat-option>
										      <mat-option value="S1">S1</mat-option>
										      <mat-option value="S1S">S1S</mat-option>
										      <mat-option value="S2">S2</mat-option>
										      <mat-option value="S4L">S4L</mat-option>
										      <mat-option value="S4">S4</mat-option>
										      <mat-option value="S4R">S4R</mat-option>
										      <mat-option value="S4AR">S4AR</mat-option>
										      <mat-option value="S4S">S4S</mat-option>
										      <mat-option value="S5">S5</mat-option>
										      <mat-option value="S6">S6</mat-option>
										      <mat-option value="S7">S7</mat-option>
										      <mat-option value="S8">S8</mat-option>
										      <mat-option value="COM">COM</mat-option>
										      <mat-option (click)="setStyleCode(itemindex)" value="N20">NIS (>20mm)</mat-option>
										      <mat-option (click)="setStyleCode(itemindex)" value="N22">NIS (>22mm)</mat-option>
										      <mat-option (click)="setStyleCode(itemindex)" value="N24">NIS (>24mm)</mat-option>
										      <mat-option (click)="setStyleCode(itemindex)" value="N26">NIS (>26mm)</mat-option>
										    </mat-select>
										  </mat-form-field>

										  <ng-container *ngIf="this.pMS.grossprices[itemindex]['style']!==null && this.pMS.grossprices[itemindex]['style']!==undefined">
										  	<ng-container *ngIf="this.pMS.grossprices[itemindex]['style'].slice(0,1)=='N'">

												  <mat-form-field *ngIf="this.pMS.grossprices[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisskr.value,'grossprices',itemindex,'style')">
												    <mat-label>SKR % (Greater than)</mat-label>
					    							<input matInput #nisskr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.grossprices[itemindex]['skr'] | percent:'1.0'" (blur)="changeAmountCode(nisskr.value,'grossprices',itemindex,'skr',true)">
												  </mat-form-field>

												  <mat-form-field *ngIf="this.pMS.grossprices[itemindex]['style'].slice(0,1)=='N'" [class.errorcheck]="checkEntry(nisuskr.value,'grossprices',itemindex,'style')">
												    <mat-label>USKR % (Lesser than)</mat-label>
					   								<input matInput #nisuskr placeholder="USKR % (Lesser than)" [ngModel]="this.pMS.grossprices[itemindex]['uskr'] | percent:'1.0'" (blur)="changeAmountCode(nisuskr.value,'grossprices',itemindex,'uskr',true)">
												  </mat-form-field>

												</ng-container>
											</ng-container>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('grossprices','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
							
										  <mat-form-field [class.errorcheck]="checkEntry(rate.value,'grossprices',itemindex,'rate',false,true)">
										    <mat-label>Rate</mat-label>
					    					<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.grossprices[itemindex]['rate'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'grossprices',itemindex,'rate',false,true)">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.grossprices.length>1" (click)="this.pMS.removeItem('grossprices',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>

							</ng-container>

						</ng-container>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Flat Rate Gross Price Override by Cultivar</h5>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.mincultivarbool">
			        Flat Rate Gross Price Override by Cultivar Active?
			      </mat-slide-toggle>

			      <ng-container *ngIf="this.pMS.mincultivarbool">

						  <div *ngFor="let item of this.pMS.mincultivarskr; let itemindex=index">

				      	<hr *ngIf="editSelected()">

								<div class="col-9" style="display: inline-block;">
									<div class="form-container">
									  
									  <mat-form-field>
									    <mat-label>Cultivar</mat-label>
				    					<mat-select placeholder="Cultivar" [(ngModel)]="this.pMS.mincultivarskr[itemindex]['cultivar']">
				    						<mat-option value="0" >All Cultivars</mat-option>
			      						<mat-option *ngFor="let cult of this.pMS.dDDS.dropdown.cultivarList" value="{{ cult.idcultivar }}">{{ cult.cultivar }}</mat-option>
									    </mat-select>
									  </mat-form-field>

									</div>
								</div>
									 
								<div class="col-3" style="display: inline-block;">
									<div class="form-container">
									  
										<button mat-icon-button class="col" (click)="this.pMS.addItem('mincultivarskr','mincultivar')"><i class="material-icons addbutton">add_circle_outline</i></button>

									</div>
								</div>

								<div class="col-9" style="display: inline-block;">
									<div class="form-container">
									
									  <mat-form-field>
									    <mat-label>Cultivar SKR Cut-off (applies above)</mat-label>
			    						<input matInput placeholder="Cultivar SKR Cut-off (applies above)" #skr [ngModel]="+this.pMS.mincultivarskr[itemindex]['skr'] | percent:'1.0'" (blur)="changeAmount(skr.value,'mincultivarskr',itemindex,'skr',true,true)">
									  </mat-form-field>

									</div>
								</div>
									 
								<div class="col-3" style="display: inline-block;">
									<div class="form-container">

										<button mat-icon-button class="col" *ngIf="this.pMS.mincultivarskr.length>1" (click)="this.pMS.removeItem('mincultivarskr',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

									</div>
								</div>

								<div class="col-9" style="display: inline-block;">
									<div class="form-container">
									
									  <mat-form-field>
									    <mat-label>Cultivar Minimum Price</mat-label>
			    						<input matInput placeholder="Cultivar Minimum Price" #min [ngModel]="+this.pMS.mincultivarskr[itemindex]['min'] | currency:this.pMS.mincultivarskr[itemindex]['currency']:'symbol-narrow'" (blur)="changeAmount(min.value,'mincultivarskr',itemindex,'min',false,false)">
									  </mat-form-field>

									</div>
								</div>
									 
								<div class="col-3" style="display: inline-block;">
									<div class="form-container">
									  
									  <mat-form-field>
									    <mat-label>Currency</mat-label>
								    	<mat-select placeholder="Currency" [(ngModel)]="this.pMS.mincultivarskr[itemindex]['currency']">
									      <mat-option value="ZAR">ZAR</mat-option>
									      <mat-option value="USD">USD</mat-option>
									    </mat-select>
									  </mat-form-field>

									</div>
								</div>

							</div>

						</ng-container>

				  	<ng-container *ngIf="+this.pMS.method!=8">

							<hr class="thick">

						  <h5 class="mt-2 mb-3">Cultivar Bonus</h5>

						  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.cultivarbool">
				        Cultivar Bonus Applies?
				      </mat-slide-toggle>

				      <ng-container *ngIf="this.pMS.cultivarbool">

					      <br>

							  <mat-form-field>
							    <mat-label>Cultivar</mat-label>
					    		<mat-select placeholder="Cultivar" [(ngModel)]="this.pMS.cultbonustype" (keydown.tab)="this.pMS.updateCultBType()">
							      <mat-option value="nominal" (click)="this.pMS.updateCultBType()">Nominal</mat-option>
							      <mat-option value="percentage" (click)="this.pMS.updateCultBType()">Percentage</mat-option>
							    </mat-select>
							  </mat-form-field>

							  <div *ngFor="let item of this.pMS.cultivarbonus; let itemindex=index">

					      	<hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field>
										    <mat-label>Cultivar</mat-label>
					    					<mat-select placeholder="Cultivar" [(ngModel)]="this.pMS.cultivarbonus[itemindex]['cultivar']">
				      						<mat-option *ngFor="let cult of this.pMS.dDDS.dropdown.cultivarList" value="{{ cult.idcultivar }}">{{ cult.cultivar }}</mat-option>
										    </mat-select>
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('cultivarbonus','cultivar')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
											
											<ng-container *ngIf="this.pMS.cultbonustype=='nominal'">
											  <mat-form-field>
											    <mat-label>Cultivar Bonus</mat-label>
					    						<input matInput placeholder="Cultivar Bonus" #bonus [ngModel]="+this.pMS.cultivarbonus[itemindex]['bonus'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(bonus.value,'cultivarbonus',itemindex,'bonus',false,false)">
											  </mat-form-field>
											</ng-container>
											
											<ng-container *ngIf="this.pMS.cultbonustype!='nominal'">
											  <mat-form-field>
											    <mat-label>Cultivar Bonus (% of Gross Fee)</mat-label>
					    						<input matInput placeholder="Cultivar Bonus (% of Gross Fee)" #bonus [ngModel]="+this.pMS.cultivarbonus[itemindex]['bonus'] | percent:'1.1'" (blur)="changeAmount(bonus.value,'cultivarbonus',itemindex,'bonus',true,false)">
											  </mat-form-field>
											</ng-container>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.cultivarbonus.length>1" (click)="this.pMS.removeItem('cultivarbonus',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>

							</ng-container>

							<hr class="thick">

						  <ng-container *ngIf="this.pMS.method=='4'">

							  <h5 class="mt-2 mb-3">Gross Base Price Override (not applicable to Cultivars with a Bonus)</h5>

							  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.baseoverridebool">
					        Base Price Override Active?
					      </mat-slide-toggle>

					      <ng-container *ngIf="this.pMS.baseoverridebool">

								  <mat-form-field>
								    <mat-label>Base Price Adjustment</mat-label>
					    			<input matInput #baseoverride placeholder="Base Price Adjustment" [ngModel]="this.pMS.baseoverride | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(baseoverride.value,'baseoverride',null,null,false,false)">
								  </mat-form-field>
								  
								  <mat-form-field>
								    <mat-label>SKR Trigger Min (SKR % greater than)</mat-label>
					    			<input matInput #baseoverridelow placeholder="SKR Trigger Min (SKR % greater than)" [ngModel]="this.pMS.baseoverridelow | percent:'1.1'" (blur)="changeAmount(baseoverridelow.value,'baseoverridelow',null,null,true)">
								  </mat-form-field>
								  
								  <mat-form-field>
								    <mat-label>SKR Trigger Max (SKR % less than or equal to)</mat-label>
					    			<input matInput #baseoverridehigh placeholder="SKR Trigger Max (SKR % less than or equal to)" [ngModel]="this.pMS.baseoverridehigh | percent:'1.1'" (blur)="changeAmount(baseoverridehigh.value,'baseoverridehigh',null,null,true)">
								  </mat-form-field>

								</ng-container>

					  		<hr class="thick">

							</ng-container>

						  <ng-container *ngIf="this.pMS.format=='0'">

							  <h5 class="mt-2 mb-3">Darks - Gross Price</h5>

							  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.darkspricebool">
					        Smalls Pricing?
					      </mat-slide-toggle>

					      <ng-container *ngIf="this.pMS.darkspricebool">
								  
								  <mat-form-field>
								    <mat-label>Darks Price Currency</mat-label>
					    			<mat-select placeholder="Darks Price Currency" [(ngModel)]="this.pMS.darkscurrency">
								      <mat-option value="ZAR">ZAR</mat-option>
								      <mat-option value="USD">USD</mat-option>
								    </mat-select>
								  </mat-form-field>	

								  <mat-form-field>
								    <mat-label>Darks Rate (/kg Dark Kernel)</mat-label>
					    			<input matInput #smalls placeholder="Darks Rate (/kg Dark Kernel)" [ngModel]="this.pMS.darksrate | currency:this.pMS.darkscurrency:'symbol-narrow'" (blur)="changeAmount(smalls.value,'darksrate')">
								  </mat-form-field>

								</ng-container>

					  		<hr class="thick">

							</ng-container>

						  <ng-container *ngIf="isAuthenticated('mod-smalldelivery') && this.pMS.format=='0'">

							  <h5 class="mt-2 mb-3">Smalls - Gross Price</h5>

							  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.smallspricebool">
					        Smalls Pricing?
					      </mat-slide-toggle>

					      <ng-container *ngIf="this.pMS.smallspricebool">
								  
								  <mat-form-field>
								    <mat-label>Smalls Price Currency</mat-label>
					    			<mat-select placeholder="Smalls Price Currency" [(ngModel)]="this.pMS.smallscurrency">
								      <mat-option value="ZAR">ZAR</mat-option>
								      <mat-option value="USD">USD</mat-option>
								    </mat-select>
								  </mat-form-field>	

								  <mat-form-field>
								    <mat-label>Smalls Rate - Less than 18mm (/kg DNIS)</mat-label>
					    			<input matInput #smalls placeholder="Smalls Rate - Less than 18mm (/kg DNIS)" [ngModel]="this.pMS.smallsrate | currency:this.pMS.smallscurrency:'symbol-narrow'" (blur)="changeAmount(smalls.value,'smallsrate')">
								  </mat-form-field>
								  
								  <mat-form-field>
								    <mat-label>Limit for Smalls Payment (USKR % less than)</mat-label>
					    			<input matInput #smallsuskrlimit placeholder="Limit for Smalls Payment (USKR % less than)" [ngModel]="this.pMS.smallsuskrlimit | percent:'1.2'" (blur)="changeAmount(smallsuskrlimit.value,'smallsuskrlimit',null,null,true)">
								  </mat-form-field>
								  
								  <mat-form-field>
								    <mat-label>Default Kernel Moisture for Smalls (%)</mat-label>
					    			<input matInput #smallsmoisture placeholder="Default Kernel Moisture for Smalls (%)" [ngModel]="this.pMS.smallsmoisture | percent:'1.2'" (blur)="changeAmount(smallsmoisture.value,'smallsmoisture',null,null,true)">
								  </mat-form-field>
								  
								  <mat-form-field>
								    <mat-label>Default Whole Nut Moisture for Smalls (%)</mat-label>
					    			<input matInput #smallstotalmoisture placeholder="Default Whole Nut Moisture for Smalls (%)" [ngModel]="this.pMS.smallstotalmoisture | percent:'1.2'" (blur)="changeAmount(smallstotalmoisture.value,'smallstotalmoisture',null,null,true)">
								  </mat-form-field>

								</ng-container>

							</ng-container>

						</ng-container>

					  <ng-container *ngIf="isAuthenticated('mod-selectouts') && this.pMS.format=='0'">

					  	<hr class="thick">

						  <h5 class="mt-2 mb-3">Select-Outs - Gross Price</h5>

						  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.selectoutpricebool">
				        Select-Out Pricing?
				      </mat-slide-toggle>

				      <ng-container *ngIf="this.pMS.selectoutpricebool">
							  
							  <mat-form-field>
							    <mat-label>Select-Outs Currency</mat-label>
				    			<mat-select placeholder="Select-Outs Currency" [(ngModel)]="this.pMS.selectoutscurrency">
							      <mat-option value="ZAR">ZAR</mat-option>
							      <mat-option value="USD">USD</mat-option>
							    </mat-select>
							  </mat-form-field>	

							  <mat-form-field>
							    <mat-label>Select-Out Rate - (/kg DNIS)</mat-label>
				    			<input matInput #selectout placeholder="Select-Out Rate - (/kg DNIS)" [ngModel]="this.pMS.selectoutrate | currency:this.pMS.selectoutscurrency:'symbol-narrow'" (blur)="changeAmount(selectout.value,'selectoutrate')">
							  </mat-form-field>
							  
							  <mat-form-field>
							    <mat-label>Default Kernel Moisture for Select-Outs (%)</mat-label>
				    			<input matInput #selectoutmoisture placeholder="Default Kernel Moisture for Select-Outs (%)" [ngModel]="this.pMS.selectoutmoisture | percent:'1.2'" (blur)="changeAmount(selectoutmoisture.value,'selectoutmoisture',null,null,true,true)">
							  </mat-form-field>
							  
							  <mat-form-field>
							    <mat-label>Default Whole Nut Moisture for Select-Outs (%)</mat-label>
				    			<input matInput #selectouttotalmoisture placeholder="Default Whole Nut Moisture for Select-Outs (%)" [ngModel]="this.pMS.selectouttotalmoisture | percent:'1.2'" (blur)="changeAmount(selectouttotalmoisture.value,'selectouttotalmoisture',null,null,true,true)">
							  </mat-form-field>

							  <mat-slide-toggle class="slider-margin slider-label my-3" [(ngModel)]="this.pMS.processlimitsobool">
					        Select-Out Auto-set?
					      </mat-slide-toggle>

					      <ng-container *ngIf="this.pMS.processlimitsobool">

								  <mat-form-field>
								    <mat-label>USKR Trigger Min (Auto set to selectout)</mat-label>
				    				<input matInput #processlimitso placeholder="USKR Trigger Min (Auto set to selectout)" [ngModel]="this.pMS.processlimitso | percent:'1.1'" (blur)="changeAmount(processlimitso.value,'processlimitso',null,null,true)">
								  </mat-form-field>

								</ng-container>

							</ng-container>

				  	</ng-container>

				  	<ng-container *ngIf="+this.pMS.method!=8">

				  		<hr class="thick">

						  <ng-container *ngIf="this.pMS.format=='1'">

						  	<div class="row justify-content-between">
									<div class="col" style="display: inline-block;">
							  		<h5 class="mt-2 mb-3">Gross Kernel Pricing - For Estimate</h5>
							  	</div>
									<div class="" style="display: inline-block;">
									  <mat-slide-toggle class="slider-margin slider-label my-3" [(ngModel)]="this.pMS.grossestbool">
							        Show
							      </mat-slide-toggle>
							    </div>
							  </div>

							  <ng-container *ngIf="this.pMS.grossestbool">

								  <div *ngFor="let item of this.pMS.estgross; let itemindex=index">

						      	<hr *ngIf="editSelected() && itemindex>0">

										<div class="col-9" style="display: inline-block;">
											<div class="form-container">
											  
											  <mat-form-field *ngIf="this.pMS.estimated=='1'" [class.errorcheck]="checkEntry(estskr.value,'estgross',itemindex,'skr')">
											    <mat-label>SKR % (Greater than)</mat-label>
					    						<input matInput #estskr placeholder="SKR % (Greater than)" [ngModel]="this.pMS.estgross[itemindex]['skr'] | percent:'1.1'" (blur)="changeAmount(estskr.value,'estgross',itemindex,'skr',true)">
											  </mat-form-field>
											  
											  <mat-form-field *ngIf="this.pMS.estimated=='3'">
											    <mat-label>Style</mat-label>
					    						<mat-select placeholder="Style" [(ngModel)]="this.pMS.estgross[itemindex]['style']">
											      <mat-option value="0">Wholes</mat-option>
											      <mat-option value="1">Halves</mat-option>
											      <mat-option value="2">Bits & Pieces</mat-option>
											      <mat-option value="3">Darks</mat-option>
											      <mat-option value="4">S7</mat-option>
											      <mat-option value="5">S8</mat-option>
											    </mat-select>
											  </mat-form-field>
											  
											  <mat-form-field *ngIf="this.pMS.estimated=='2'">
											    <mat-label>Style</mat-label>
					    						<mat-select placeholder="Style" [(ngModel)]="this.pMS.estgross[itemindex]['style']">
											      <mat-option value="S0">S0</mat-option>
											      <mat-option value="S1">S1</mat-option>
											      <mat-option value="S1S">S1S</mat-option>
											      <mat-option value="S2">S2</mat-option>
											      <mat-option value="S4L">S4L</mat-option>
											      <mat-option value="S4">S4</mat-option>
											      <mat-option value="S4R">S4R</mat-option>
											      <mat-option value="S4AR">S4AR</mat-option>
											      <mat-option value="S4S">S4S</mat-option>
											      <mat-option value="S5">S5</mat-option>
											      <mat-option value="S6">S6</mat-option>
											      <mat-option value="S7">S7</mat-option>
											      <mat-option value="S8">S8</mat-option>
											      <mat-option value="COM">COM</mat-option>
											    </mat-select>
											  </mat-form-field>

											</div>
										</div>
											 
										<div class="col-3" style="display: inline-block;">
											<div class="form-container">
											  
												<button mat-icon-button class="col" (click)="this.pMS.addItem('estgross','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

											</div>
										</div>

										<div class="col-9" style="display: inline-block;">
											<div class="form-container">
								
											  <mat-form-field>
											    <mat-label>Rate</mat-label>
					    						<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.estgross[itemindex]['rate'] | currency:this.pMS.grosspricecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'estgross',itemindex,'rate')">
											  </mat-form-field>

											</div>
										</div>
											 
										<div class="col-3" style="display: inline-block;">
											<div class="form-container">

												<button mat-icon-button class="col" *ngIf="this.pMS.estgross.length>1" (click)="this.pMS.removeItem('estgross',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

											</div>
										</div>

									</div>

								</ng-container>

						  	<hr class="thick">

							</ng-container>
					  	
					  	<div class="row justify-content-between">
								<div class="col" style="display: inline-block;">
						  		<h5 class="mt-2 mb-3">Unsound Processing Fees</h5>
						  	</div>
								<div class="" style="display: inline-block;">
								  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.modelnetunsound">
						        Unsound Processing Fees incl in Gross Price (Table format)?
						      </mat-slide-toggle>
						    </div>
								<div class="" style="display: inline-block;">
								  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.processbool">
						        Show
						      </mat-slide-toggle>
						    </div>
						  </div>

						  <mat-form-field>
						    <mat-label>Fee Currency</mat-label>
					    	<mat-select placeholder="Fee Currency" [(ngModel)]="this.pMS.feecurrency">
						      <mat-option value="ZAR">ZAR</mat-option>
						      <mat-option value="USD">USD</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field>
						    <mat-label>USKR Trigger Max (No Payment)</mat-label>
					    	<input matInput #processlimit placeholder="USKR Trigger Max (No Payment)" [ngModel]="this.pMS.processlimit | percent:'1.1'" (blur)="changeAmount(processlimit.value,'processlimit',null,null,true)">
						  </mat-form-field>

						  <ng-container *ngIf="this.pMS.processbool">

							  <div *ngFor="let item of this.pMS.processfees; let itemindex=index">

						      <hr *ngIf="editSelected()">

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
										  
										  <mat-form-field [class.errorcheck]="checkEntry(uskr.value,'processfees',itemindex,'uskr')">
										    <mat-label>USKR % (Greater than{{ (this.pMS.format=='0' && this.pMS.processfees[itemindex]['uskr']!=='0' ? ')' : ' or equal to)') }}</mat-label>
					    					<input matInput #uskr placeholder="USKR % (Greater than{{ (this.pMS.format=='0' && this.pMS.processfees[itemindex]['uskr']!=='0' ? ')' : ' or equal to)') }}" [ngModel]="this.pMS.processfees[itemindex]['uskr'] | percent:'1.1'" (blur)="changeAmount(uskr.value,'processfees',itemindex,'uskr',true)">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">
										  
											<button mat-icon-button class="col" (click)="this.pMS.addItem('processfees','pricing')"><i class="material-icons addbutton">add_circle_outline</i></button>

										</div>
									</div>

									<div class="col-9" style="display: inline-block;">
										<div class="form-container">
							
										  <mat-form-field>
										    <mat-label>Rate</mat-label>
					    					<input matInput placeholder="Rate" #rate [ngModel]="+this.pMS.processfees[itemindex]['rate'] | currency:this.pMS.feecurrency:'symbol-narrow'" (blur)="changeAmount(rate.value,'processfees',itemindex,'rate')">
										  </mat-form-field>

										</div>
									</div>
										 
									<div class="col-3" style="display: inline-block;">
										<div class="form-container">

											<button mat-icon-button class="col" *ngIf="this.pMS.processfees.length>1" (click)="this.pMS.removeItem('processfees',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

										</div>
									</div>

								</div>

							</ng-container>

					  	<hr class="thick">

							<ng-container *ngIf="this.pMS.format!=='0'">
							  
							  <h5 class="mt-2 mb-3">NIS Processing Fee</h5>
											  
							  <mat-form-field>
							    <mat-label>Processing (/kg of DNIS)</mat-label>
				    			<input matInput #processnis placeholder="Processing (/kg of DNIS)" [ngModel]="this.pMS.processnis | currency:this.pMS.feecurrency:'symbol-narrow'" (blur)="changeAmount(logistics.value,'processnis')">
							  </mat-form-field>

								<hr class="thick">
						
							</ng-container>

						  <h5 class="mt-2 mb-3">Marketing Fee</h5>
										  
						  <mat-form-field>
						    <mat-label>Marketing (% of Gross Price)</mat-label>
				    		<input matInput #marketing placeholder="Marketing (% of Gross Price)" [ngModel]="this.pMS.marketing | percent:'1.1'" (blur)="changeAmount(marketing.value,'marketing',null,null,true)">
						  </mat-form-field>

							<hr class="thick">

						  <h5 class="mt-2 mb-3">Logistics Fee</h5>
										  
						  <mat-form-field>
						    <mat-label>Logistics (/kg of SK{{ (this.pMS.format=='0' ? ')' : ' or Bagged DNIS)') }}</mat-label>
				    		<input matInput #logistics placeholder="Logistics (/kg of SK{{ (this.pMS.format=='0' ? ')' : ' or Bagged DNIS)') }}" [ngModel]="this.pMS.logistics | currency:this.pMS.feecurrency:'symbol-narrow'" (blur)="changeAmount(logistics.value,'logistics')">
						  </mat-form-field>

							<hr class="thick">

						  <h5 class="mt-2 mb-3">Packaging Fee</h5>
										  
						  <mat-form-field>
						    <mat-label>Packaging (/kg of DNIS Cracked)</mat-label>
				    		<input matInput #packaging placeholder="Logistics (/kg of DNIS Cracked)" [ngModel]="this.pMS.packaging | currency:this.pMS.feecurrency:'symbol-narrow'" (blur)="changeAmount(packaging.value,'packaging')">
						  </mat-form-field>

							<hr class="thick">

						  <h5 class="mt-2 mb-3">Kernel Processing Fee</h5>
										  
						  <mat-form-field>
						    <mat-label>Kernel Processing (/kg of DNIS Cracked)</mat-label>
				    		<input matInput #processing placeholder="Kernel Processing (/kg of DNIS Cracked)" [ngModel]="this.pMS.processing | currency:this.pMS.feecurrency:'symbol-narrow'" (blur)="changeAmount(packaging.value,'processing')">
						  </mat-form-field>

							<hr class="thick">

						  <h5 class="mt-2 mb-3">Transport-Shipping-Insurance Fee</h5>
										  
						  <mat-form-field>
						    <mat-label>Transport-Shipping-Insurance (/kg of DNIS Cracked)</mat-label>
				    		<input matInput #transshipins placeholder="Transport-Shipping-Insurance (/kg of DNIS Cracked)" [ngModel]="this.pMS.transshipins | currency:this.pMS.feecurrency:'symbol-narrow'" (blur)="changeAmount(packaging.value,'transshipins')">
						  </mat-form-field>

						</ng-container>
						
						<hr class="thick">

						<ng-container *ngIf="isAuthenticated('mod-dryfloor')">
					  	
					  	<h5 class="mt-2 mb-3">DNIS Moisture Level</h5>
										  
						  <mat-form-field>
						    <mat-label>DNIS: Kernel Moisture Level %</mat-label>
				    		<input matInput #dryfloor placeholder="DNIS: Kernel Moisture Level %" [ngModel]="this.pMS.dryfloor | percent:'1.2'" (blur)="changeAmount(dryfloor.value,'dryfloor',null,null,true)">
						  </mat-form-field>
										  
						  <mat-form-field>
						    <mat-label>DNIS: Whole Nut Moisture Level %</mat-label>
				    		<input matInput #dryfloornis placeholder="DNIS: Whole Nut Moisture Level %" [ngModel]="this.pMS.dryfloornis | percent:'1.2'" (blur)="changeAmount(dryfloornis.value,'dryfloornis',null,null,true)">
						  </mat-form-field>
										  
						  <mat-form-field *ngIf="isAuthenticated('mod-dryfloorextra')">
						    <mat-label>Stated DNIS Kernel Moisture %</mat-label>
				    		<input matInput #dryfloorextra placeholder="Stated DNIS Kernel Moisture %" [ngModel]="this.pMS.dryfloorextra | percent:'1.2'" (blur)="changeAmount(dryfloorextra.value,'dryfloorextra',null,null,true)">
						  </mat-form-field>

							<hr class="thick">

						</ng-container>

					  <h5 class="mt-2 mb-3">Drying Fees</h5>

					  <mat-form-field>
					    <mat-label>Drying Fee - Method</mat-label>
			    		<mat-select placeholder="Drying Fee - Method" [(ngModel)]="this.pMS.drymethod">
					      <mat-option value="none">No Fee</mat-option>
					      <mat-option value="highorlow">Charge above DNIS moisture level</mat-option>
					      <mat-option value="aboveonly">Charge above Threshold</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field *ngIf="this.pMS.drymethod!='none'">
					    <mat-label>Drying Fee Currency</mat-label>
			    		<mat-select placeholder="Drying Fee Currency" [(ngModel)]="this.pMS.drycurr">
					      <mat-option value="ZAR">ZAR</mat-option>
					      <mat-option value="USD">USD</mat-option>
					    </mat-select>
					  </mat-form-field>
									  
					  <mat-form-field *ngIf="this.pMS.drymethod=='aboveonly' || this.pMS.drymethod=='highorlow'">
					    <mat-label>Threshold Moisture Level %</mat-label>
			    		<input matInput #drythreshold placeholder="Threshold Moisture Level %" [ngModel]="this.pMS.drythreshold | percent:'1.1'" (blur)="changeAmount(drythreshold.value,'drydrythreshold',null,null,true)">
					  </mat-form-field>
									  
					  <mat-form-field *ngIf="this.pMS.drymethod=='aboveonly'">
					    <mat-label>Drying Rate (/kg moisture removed to Threshold)</mat-label>
			    		<input matInput #dryrate placeholder="Drying Rate (/kg moisture removed to Threshold)" [ngModel]="this.pMS.dryrate | currency:this.pMS.drycurr:'symbol-narrow'" (blur)="changeAmount(dryrate.value,'dryrate',null,null,false)">
					  </mat-form-field>
									  
					  <mat-form-field *ngIf="this.pMS.drymethod=='highorlow'">
					    <mat-label>Rate ABOVE Threshold (/kg moisture removed)</mat-label>
			    		<input matInput #dryfeehigh placeholder="Rate ABOVE Threshold (/kg moisture removed)" [ngModel]="this.pMS.dryfeehigh | currency:this.pMS.drycurr:'symbol-narrow'" (blur)="changeAmount(dryfeehigh.value,'dryfeehigh',null,null,false)">
					  </mat-form-field>
									  
					  <mat-form-field *ngIf="this.pMS.drymethod=='highorlow'">
					    <mat-label>Rate BELOW Threshold (/kg moisture removed)</mat-label>
			    		<input matInput #dryfeelow placeholder="Rate BELOW Threshold (/kg moisture removed)" [ngModel]="this.pMS.dryfeelow | currency:this.pMS.drycurr:'symbol-narrow'" (blur)="changeAmount(dryfeelow.value,'dryfeelow',null,null,false)">
					  </mat-form-field>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Transport Fee</h5>

					  <mat-form-field>
					    <mat-label>Transport Fee Currency</mat-label>
				    	<mat-select placeholder="Transport Fee Currency" [(ngModel)]="this.pMS.transportcurr">
					      <mat-option value="ZAR">ZAR</mat-option>
					      <mat-option value="USD">USD</mat-option>
					    </mat-select>
					  </mat-form-field>
									  
					  <mat-form-field>
					    <mat-label>Transport Base (per collection)</mat-label>
					    <input matInput #transportbase placeholder="Transport Base (per collection)" [ngModel]="this.pMS.transportbase | currency:this.pMS.transportcurr:'symbol-narrow'" (blur)="changeAmount(transportbase.value,'transportbase')">
					  </mat-form-field>
									  
					  <mat-form-field>
					    <mat-label>Transport (/kg of WNIS)</mat-label>
				    	<input matInput #transport placeholder="Transport (/kg of WNIS)" [ngModel]="this.pMS.transport | currency:this.pMS.transportcurr:'symbol-narrow'" (blur)="changeAmount(transport.value,'transport')">
					  </mat-form-field>
									  
					  <mat-form-field>
					    <mat-label>Transport (/km distance)</mat-label>
				    	<input matInput #transportkm placeholder="Transport (/km distance)" [ngModel]="this.pMS.transportkm | currency:this.pMS.transportcurr:'symbol-narrow'" (blur)="changeAmount(transportkm.value,'transportkm')">
					  </mat-form-field>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Depot Handling Fee</h5>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.transportdepotbool">
			        Depot Fee Applies?
			      </mat-slide-toggle>

			      <ng-container *ngIf="this.pMS.transportdepotbool">

						  <mat-form-field>
						    <mat-label>Transport Fee Currency</mat-label>
				    		<mat-select placeholder="Transport Fee Currency" [(ngModel)]="this.pMS.transportdepotcurr">
						      <mat-option value="ZAR">ZAR</mat-option>
						      <mat-option value="USD">USD</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <div *ngFor="let item of this.pMS.transportdepots; let itemindex=index">

				      	<hr *ngIf="editSelected()">

								<div class="col-9" style="display: inline-block;">
									<div class="form-container">
									  
									  <mat-form-field>
									    <mat-label>Depot</mat-label>
				    					<mat-select placeholder="Depot" #depotname [class.errorcheck]="checkEntry(depotname.value,'transportdepots',itemindex,'depotname')" [(ngModel)]="this.pMS.transportdepots[itemindex]['depotname']">
			      						<mat-option *ngFor="let depot of this.pMS.dDDS.dropdown.depotList" value="{{ depot.idlocation }}">{{ depot.name }}</mat-option>
									    </mat-select>
									  </mat-form-field>

									</div>
								</div>
									 
								<div class="col-3" style="display: inline-block;">
									<div class="form-container">
									  
										<button mat-icon-button class="col" (click)="this.pMS.addItem('transportdepots','depot')"><i class="material-icons addbutton">add_circle_outline</i></button>

									</div>
								</div>

								<div class="col-9" style="display: inline-block;">
									<div class="form-container">
										
									  <mat-form-field>
									    <mat-label>Depot Fee ( /kg of DNIS)</mat-label>
				    					<input matInput placeholder="Depot Fee ( /kg of DNIS)" #depotfee [class.errorcheck]="checkEntry(depotfee.value,'transportdepots',itemindex,'depotfee',false,true)" [ngModel]="+this.pMS.transportdepots[itemindex]['depotfee'] | currency:this.pMS.transportdepotcurr:'symbol-narrow'" (blur)="changeAmount(depotfee.value,'transportdepots',itemindex,'depotfee',false,false)">
									  </mat-form-field>

									</div>
								</div>
									 
								<div class="col-3" style="display: inline-block;">
									<div class="form-container">

										<button mat-icon-button class="col" *ngIf="this.pMS.transportdepots.length>1" (click)="this.pMS.removeItem('transportdepots',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

									</div>
								</div>

								<div class="col-9" style="display: inline-block;">
									<div class="form-container">
									  
									  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.transportdepots[itemindex]['depotdryfee']">
							        Drying Fees Covered by Depot Fee?
							      </mat-slide-toggle>

									</div>
								</div>
									 
								<div class="col-3" style="display: inline-block;">
									<div class="form-container">
									  
									</div>
								</div>

							</div>

						</ng-container>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">MRL / Maximum residue levels Fee (ex. VAT)</h5>
									  
					  <mat-form-field>
					    <mat-label>MRL</mat-label>
				    	<input matInput #mrl placeholder="MRL" [ngModel]="this.pMS.mrl | currency:'ZAR':'symbol-narrow'" (blur)="changeAmount(mrl.value,'mrl')">
					  </mat-form-field> 

					  <mat-form-field>
					    <mat-label>Period Type</mat-label>
				    	<mat-select placeholder="Period Type" [(ngModel)]="this.pMS.mrlsplit">
					      <mat-option value="pro-rata">Pro-rata</mat-option>
					      <mat-option value="upfront">Upfront</mat-option>
					    </mat-select>
					  </mat-form-field>

			      <hr class="thick">

					  <h5 class="mt-2 mb-3">SAMAC Fee (ex. VAT)</h5>
									  
					  <mat-form-field>
					    <mat-label>SAMAC (/kg of DNIS)</mat-label>
				    	<input matInput #samac placeholder="SAMAC (/kg of DNIS)" [ngModel]="this.pMS.samac | currency:'ZAR':'symbol-narrow'" (blur)="changeAmount(samac.value,'samac')">
					  </mat-form-field>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.modelnetsamac">
			        SAMAC included in Gross Pricing?
			      </mat-slide-toggle>

					  <hr class="thick">

					  <h5 class="mt-2 mb-3">Payment Scheduling</h5>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.schedulebool">
			        Show Payment Scheduling?
			      </mat-slide-toggle>

			      <ng-container *ngIf="this.pMS.schedulebool">

						  <hr class="thick">

						  <h5 class="mt-2 mb-3">Payment Scheduling</h5>

				      <div *ngFor="let item of this.pMS.newitems; let itemindex=index">

				      	<hr *ngIf="itemindex>0">

								<div class="row justify-content-between">
									<div class="col-xs-6 ml-3">
										<p>Payment - {{ itemindex + 1 }}</p>
									</div>
									<div *ngIf="this.portalService.portalMod()['batch'] && this.pMS.format!=='0'" class="mr-4 ml-3">
									  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.newitems[itemindex]['estimate']">
							        Based on estimate?
							      </mat-slide-toggle>  
									</div>
								</div>

								<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
									<div class="form-container">
									  
									  <mat-form-field>
		    							<mat-label>Period Type</mat-label>
									    <mat-select placeholder="Period Type" [(ngModel)]="this.pMS.newitems[itemindex]['type']">
									      <mat-option value="#">Specific Date</mat-option>
									      <mat-option value="m">Months</mat-option>
											  <mat-option value="w">Weeks</mat-option>
									      <mat-option value="d">Calendar Days</mat-option>
									      <mat-option value="b">Business Days</mat-option>
									    </mat-select>
									  </mat-form-field>

									  <mat-form-field *ngIf="this.pMS.newitems[itemindex]['type'] == 'm'">	
		    							<mat-label>Occurence in Month</mat-label>			    
									    <mat-select placeholder="Occurence in Month" [(ngModel)]="this.pMS.newitems[itemindex]['order']">
									      <mat-option value="f">First</mat-option>
									      <mat-option value="s">Second</mat-option>
									      <mat-option value="t">Third</mat-option>
									      <mat-option value="l">Last</mat-option>
												<mat-option value="x">Specific Day(s)</mat-option>
									    </mat-select>
									  </mat-form-field>
									  
									  <mat-form-field *ngIf="this.pMS.newitems[itemindex]['type'] == '#'">
		    							<mat-label>Date</mat-label>
									    <input matInput [matDatepicker]="specificdate" [(ngModel)]="this.pMS.newitems[itemindex]['timingDate']">
											<mat-datepicker-toggle matIconSuffix [for]="specificdate"></mat-datepicker-toggle>
									    <mat-datepicker #specificdate></mat-datepicker>
									  </mat-form-field>

									  <mat-form-field *ngIf="this.pMS.newitems[itemindex]['type'] != '#' && this.pMS.newitems[itemindex]['type'] != 'm'">
		    							<mat-label>Days after {{ this.pMS.newitems[itemindex]['datetype'] | titlecase }}</mat-label>
									    <input matInput type="number" min="0" step="1" placeholder="Days after {{ this.pMS.newitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.newitems[itemindex]['timing']">
									  </mat-form-field>

									  <mat-form-field *ngIf="this.pMS.newitems[itemindex]['type'] == 'm'">
		    							<mat-label>Months after {{ this.pMS.newitems[itemindex]['datetype'] | titlecase }}</mat-label>
									    <input matInput type="number" min="0" step="1" placeholder="Months after {{ this.pMS.newitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.newitems[itemindex]['timing']">
									  </mat-form-field>

									  <mat-form-field *ngIf="(this.pMS.newitems[itemindex]['type'] != '#' && this.pMS.newitems[itemindex]['type'] != 'm' && this.pMS.newitems[itemindex]['type'] != '')">
		  								<mat-label>Date Anchor</mat-label>
									    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.newitems[itemindex]['datetype']">
									      <mat-option value="delivery">From Delivery Date</mat-option>
									      <mat-option value="sample">From Sample Date</mat-option>
									      <ng-container *ngIf="itemindex>0">
									      	<ng-container *ngFor="let p of this.pMS.newitems; let pindex=index">
									      		<ng-container *ngIf="pindex<itemindex">
									      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
									      		</ng-container>
									      	</ng-container>
									      </ng-container>
									    </mat-select>
									  </mat-form-field>

									</div>
								</div>

								<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
									<div class="form-container">

									  <mat-form-field>
									    <mat-label>{{ (this.portalService.portalMod()['batch'] ? (this.pMS.newitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}</mat-label>
				    					<input matInput type="number" min="0" max="100" step="10" placeholder="{{ (this.portalService.portalMod()['batch'] ? (this.pMS.newitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}" [(ngModel)]="this.pMS.newitems[itemindex]['percent']">
									  </mat-form-field>

									  <mat-form-field *ngIf="(this.pMS.newitems[itemindex]['type'] == 'm' && this.pMS.newitems[itemindex]['order'] !== 'x') || this.pMS.newitems[itemindex]['type'] == 'b' || this.pMS.newitems[itemindex]['type'] == 'd' || this.pMS.newitems[itemindex]['type'] == 'w'">				    
		    							<mat-label>On Day</mat-label>
									    <mat-select placeholder="On Day" [(ngModel)]="this.pMS.newitems[itemindex]['weekday']">
											  <mat-option *ngIf="this.pMS.newitems[itemindex]['type'] != 'm'" value="0">Any</mat-option>
											  <mat-option *ngIf="this.pMS.newitems[itemindex]['type'] != '#' && this.pMS.newitems[itemindex]['type'] != 'm'" value="x">Specific Days of the Month</mat-option>
									      <mat-option value="1">Monday</mat-option>
									      <mat-option value="2">Tuesday</mat-option>
									      <mat-option value="3">Wednesday</mat-option>
									      <mat-option value="4">Thursday</mat-option>
									      <mat-option value="5">Friday</mat-option>
									      <mat-option value="6">Saturday</mat-option>
									      <mat-option value="7">Sunday</mat-option>
									    </mat-select>
									  </mat-form-field>

										 <mat-form-field *ngIf="this.pMS.newitems[itemindex]['type'] !== '#' && ((this.pMS.newitems[itemindex]['type']=='m' && this.pMS.newitems[itemindex]['order']=='x') || this.pMS.newitems[itemindex]['weekday']=='x')">
		  								<mat-label>Day(s) of Month (comma separated)</mat-label>
									    <input matInput placeholder="Day(s) of Month (comma separated)" [(ngModel)]="this.pMS.newitems[itemindex]['mdate']">
									  </mat-form-field>

									  <mat-form-field *ngIf="this.pMS.newitems[itemindex]['type'] == ''">
		  								<mat-label>Date Anchor</mat-label>
									    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.newitems[itemindex]['datetype']">
									      <mat-option value="delivery">From Delivery Date</mat-option>
									      <mat-option value="sample">From Sample Date</mat-option>
									      <ng-container *ngIf="itemindex>0">
									      	<ng-container *ngFor="let p of this.pMS.newitems; let pindex=index">
									      		<ng-container *ngIf="pindex<itemindex">
									      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
									      		</ng-container>
									      	</ng-container>
									      </ng-container>
									    </mat-select>
									  </mat-form-field>
									  
									  <div class="row">
										  <button mat-icon-button class="col" (click)="this.pMS.addItem('newitems')"><i class="material-icons addbutton">add_circle_outline</i></button>
										  <button mat-icon-button class="col" *ngIf="this.pMS.newitems.length>1" (click)="this.pMS.removeItem('newitems',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
										</div>

									</div>
								</div>
							</div>

				      <hr class="thick">

						  <h5 class="mt-2 mb-3">Late Payment Scheduling</h5>

						  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.latebool">
				        Payment Scheduling for Late Deliveries?
				      </mat-slide-toggle>

				      <ng-container *ngIf="this.pMS.latebool">
						  
							  <mat-form-field>
							    <mat-label>Late Date Cut-Off (from and onwards)</mat-label>
				    			<input matInput [matDatepicker]="latedate" [(ngModel)]="this.pMS.paydatecutoff">
									<mat-datepicker-toggle matIconSuffix [for]="latedate"></mat-datepicker-toggle>
							    <mat-datepicker #latedate></mat-datepicker>
							  </mat-form-field>

							  <br>

					      <div *ngFor="let item of this.pMS.lateitems; let itemindex=index">

					      	<hr *ngIf="itemindex>0">

									<div class="row justify-content-between">
										<div class="col-xs-6 ml-3">
											<p>Payment - {{ itemindex + 1 }}</p>
										</div>
										<div *ngIf="this.portalService.portalMod()['batch'] && this.pMS.format!=='0'" class="mr-4 ml-3">
										  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.lateitems[itemindex]['estimate']">
								        Based on estimate?
								      </mat-slide-toggle>  
										</div>
									</div>

									<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
										<div class="form-container">

										  <mat-form-field>
			    							<mat-label>Period Type</mat-label>
										    <mat-select placeholder="Period Type" [(ngModel)]="this.pMS.lateitems[itemindex]['type']">
										      <mat-option value="#">Specific Date</mat-option>
										      <mat-option value="m">Months</mat-option>
												  <mat-option value="w">Weeks</mat-option>
										      <mat-option value="d">Calendar Days</mat-option>
										      <mat-option value="b">Business Days</mat-option>
										    </mat-select>
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.lateitems[itemindex]['type'] == 'm'">	
			    							<mat-label>Occurence in Month</mat-label>			    
										    <mat-select placeholder="Occurence in Month" [(ngModel)]="this.pMS.lateitems[itemindex]['order']">
										      <mat-option value="f">First</mat-option>
										      <mat-option value="s">Second</mat-option>
										      <mat-option value="t">Third</mat-option>
										      <mat-option value="l">Last</mat-option>
													<mat-option value="x">Specific Day(s)</mat-option>
										    </mat-select>
										  </mat-form-field>
										  
										  <mat-form-field *ngIf="this.pMS.lateitems[itemindex]['type'] == '#'">
			    							<mat-label>Date</mat-label>
										    <input matInput [matDatepicker]="specificdate" [(ngModel)]="this.pMS.lateitems[itemindex]['timingDate']">
												<mat-datepicker-toggle matIconSuffix [for]="specificdate"></mat-datepicker-toggle>
										    <mat-datepicker #specificdate></mat-datepicker>
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.lateitems[itemindex]['type'] != '#' && this.pMS.lateitems[itemindex]['type'] != 'm'">
			    							<mat-label>Days after {{ this.pMS.lateitems[itemindex]['datetype'] | titlecase }}</mat-label>
										    <input matInput type="number" min="0" step="1" placeholder="Days after {{ this.pMS.lateitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.lateitems[itemindex]['timing']">
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.lateitems[itemindex]['type'] == 'm'">
			    							<mat-label>Months after {{ this.pMS.lateitems[itemindex]['datetype'] | titlecase }}</mat-label>
										    <input matInput type="number" min="0" step="1" placeholder="Months after {{ this.pMS.lateitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.lateitems[itemindex]['timing']">
										  </mat-form-field>

										  <mat-form-field *ngIf="(this.pMS.lateitems[itemindex]['type'] != '#' && this.pMS.lateitems[itemindex]['type'] != 'm' && this.pMS.lateitems[itemindex]['type'] != '')">
			  								<mat-label>Date Anchor</mat-label>
										    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.lateitems[itemindex]['datetype']">
										      <mat-option value="delivery">From Delivery Date</mat-option>
										      <mat-option value="sample">From Sample Date</mat-option>
										      <ng-container *ngIf="itemindex>0">
										      	<ng-container *ngFor="let p of this.pMS.lateitems; let pindex=index">
										      		<ng-container *ngIf="pindex<itemindex">
										      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
										      		</ng-container>
										      	</ng-container>
										      </ng-container>
										    </mat-select>
										  </mat-form-field>

										</div>
									</div>

									<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
										<div class="form-container">

										  <mat-form-field>
										    <mat-label>{{ (this.portalService.portalMod()['batch'] ? (this.pMS.lateitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}</mat-label>
					    					<input matInput type="number" min="0" max="100" step="10" placeholder="{{ (this.portalService.portalMod()['batch'] ? (this.pMS.lateitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}" [(ngModel)]="this.pMS.lateitems[itemindex]['percent']">
										  </mat-form-field>

										  <mat-form-field *ngIf="(this.pMS.lateitems[itemindex]['type'] == 'm' && this.pMS.lateitems[itemindex]['order'] !== 'x') || this.pMS.lateitems[itemindex]['type'] == 'b' || this.pMS.lateitems[itemindex]['type'] == 'd' || this.pMS.lateitems[itemindex]['type'] == 'w'">				    
			    							<mat-label>On Day</mat-label>
										    <mat-select placeholder="On Day" [(ngModel)]="this.pMS.lateitems[itemindex]['weekday']">
												  <mat-option *ngIf="this.pMS.lateitems[itemindex]['type'] != 'm'" value="0">Any</mat-option>
												  <mat-option *ngIf="this.pMS.lateitems[itemindex]['type'] != '#' && this.pMS.lateitems[itemindex]['type'] != 'm'" value="x">Specific Days of the Month</mat-option>
										      <mat-option value="1">Monday</mat-option>
										      <mat-option value="2">Tuesday</mat-option>
										      <mat-option value="3">Wednesday</mat-option>
										      <mat-option value="4">Thursday</mat-option>
										      <mat-option value="5">Friday</mat-option>
										      <mat-option value="6">Saturday</mat-option>
										      <mat-option value="7">Sunday</mat-option>
										    </mat-select>
										  </mat-form-field>

											 <mat-form-field *ngIf="this.pMS.lateitems[itemindex]['type'] !== '#' && ((this.pMS.lateitems[itemindex]['type']=='m' && this.pMS.lateitems[itemindex]['order']=='x') || this.pMS.lateitems[itemindex]['weekday']=='x')">
			  								<mat-label>Day(s) of Month (comma separated)</mat-label>
										    <input matInput placeholder="Day(s) of Month (comma separated)" [(ngModel)]="this.pMS.lateitems[itemindex]['mdate']">
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.lateitems[itemindex]['type'] == ''">
			  								<mat-label>Date Anchor</mat-label>
										    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.lateitems[itemindex]['datetype']">
										      <mat-option value="delivery">From Delivery Date</mat-option>
										      <mat-option value="sample">From Sample Date</mat-option>
										      <ng-container *ngIf="itemindex>0">
										      	<ng-container *ngFor="let p of this.pMS.lateitems; let pindex=index">
										      		<ng-container *ngIf="pindex<itemindex">
										      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
										      		</ng-container>
										      	</ng-container>
										      </ng-container>
										    </mat-select>
										  </mat-form-field>
										  
										  <div class="row">
											  <button mat-icon-button class="col" (click)="this.pMS.addItem('lateitems')"><i class="material-icons addbutton">add_circle_outline</i></button>
											  <button mat-icon-button class="col" *ngIf="this.pMS.lateitems.length>1" (click)="this.pMS.removeItem('lateitems',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
											</div>

										</div>
									</div>
								</div>

							</ng-container>

							<ng-container *ngIf="this.pMS.smallspricebool && this.pMS.format=='0'">

					      <hr class="thick">

							  <h5 class="mt-2 mb-3">Smalls Only - Payment Scheduling</h5>

							  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.smallsbool">
					        Payment Scheduling for Exclusively Smalls (<18mm)?
					      </mat-slide-toggle>

					      <ng-container *ngIf="this.pMS.smallsbool">

						      <div *ngFor="let item of this.pMS.smallitems; let itemindex=index">

						      	<hr *ngIf="itemindex>0">

										<div class="row justify-content-between">
											<div class="col-xs-6 ml-3">
												<p>Payment - {{ itemindex + 1 }}</p>
											</div>
											<div *ngIf="this.portalService.portalMod()['batch'] && this.pMS.format!=='0'" class="mr-4 ml-3">
											  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.smallitems[itemindex]['estimate']">
									        Based on estimate?
									      </mat-slide-toggle>  
											</div>
										</div>

										<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
											<div class="form-container">

											  <mat-form-field>
				    							<mat-label>Period Type</mat-label>
											    <mat-select placeholder="Period Type" [(ngModel)]="this.pMS.smallitems[itemindex]['type']">
											      <mat-option value="#">Specific Date</mat-option>
											      <mat-option value="m">Months</mat-option>
													  <mat-option value="w">Weeks</mat-option>
											      <mat-option value="d">Calendar Days</mat-option>
											      <mat-option value="b">Business Days</mat-option>
											    </mat-select>
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.smallitems[itemindex]['type'] == 'm'">	
				    							<mat-label>Occurence in Month</mat-label>			    
											    <mat-select placeholder="Occurence in Month" [(ngModel)]="this.pMS.smallitems[itemindex]['order']">
											      <mat-option value="f">First</mat-option>
											      <mat-option value="s">Second</mat-option>
											      <mat-option value="t">Third</mat-option>
											      <mat-option value="l">Last</mat-option>
														<mat-option value="x">Specific Day(s)</mat-option>
											    </mat-select>
											  </mat-form-field>
											  
											  <mat-form-field *ngIf="this.pMS.smallitems[itemindex]['type'] == '#'">
				    							<mat-label>Date</mat-label>
											    <input matInput [matDatepicker]="specificdate" [(ngModel)]="this.pMS.smallitems[itemindex]['timingDate']">
													<mat-datepicker-toggle matIconSuffix [for]="specificdate"></mat-datepicker-toggle>
											    <mat-datepicker #specificdate></mat-datepicker>
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.smallitems[itemindex]['type'] != '#' && this.pMS.smallitems[itemindex]['type'] != 'm'">
				    							<mat-label>Days after {{ this.pMS.smallitems[itemindex]['datetype'] | titlecase }}</mat-label>
											    <input matInput type="number" min="0" step="1" placeholder="Days after {{ this.pMS.smallitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.smallitems[itemindex]['timing']">
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.smallitems[itemindex]['type'] == 'm'">
				    							<mat-label>Months after {{ this.pMS.smallitems[itemindex]['datetype'] | titlecase }}</mat-label>
											    <input matInput type="number" min="0" step="1" placeholder="Months after {{ this.pMS.smallitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.smallitems[itemindex]['timing']">
											  </mat-form-field>

											  <mat-form-field *ngIf="(this.pMS.smallitems[itemindex]['type'] != '#' && this.pMS.smallitems[itemindex]['type'] != 'm' && this.pMS.smallitems[itemindex]['type'] != '')">
				  								<mat-label>Date Anchor</mat-label>
											    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.smallitems[itemindex]['datetype']">
											      <mat-option value="delivery">From Delivery Date</mat-option>
											      <mat-option value="sample">From Sample Date</mat-option>
											      <ng-container *ngIf="itemindex>0">
											      	<ng-container *ngFor="let p of this.pMS.smallitems; let pindex=index">
											      		<ng-container *ngIf="pindex<itemindex">
											      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
											      		</ng-container>
											      	</ng-container>
											      </ng-container>
											    </mat-select>
											  </mat-form-field>

											</div>
										</div>

										<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
											<div class="form-container">

											  <mat-form-field>
											    <mat-label>{{ (this.portalService.portalMod()['batch'] ? (this.pMS.smallitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}</mat-label>
						    					<input matInput type="number" min="0" max="100" step="10" placeholder="{{ (this.portalService.portalMod()['batch'] ? (this.pMS.smallitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}" [(ngModel)]="this.pMS.smallitems[itemindex]['percent']">
											  </mat-form-field>

											  <mat-form-field *ngIf="(this.pMS.smallitems[itemindex]['type'] == 'm' && this.pMS.smallitems[itemindex]['order'] !== 'x') || this.pMS.smallitems[itemindex]['type'] == 'b' || this.pMS.smallitems[itemindex]['type'] == 'd' || this.pMS.smallitems[itemindex]['type'] == 'w'">				    
				    							<mat-label>On Day</mat-label>
											    <mat-select placeholder="On Day" [(ngModel)]="this.pMS.smallitems[itemindex]['weekday']">
													  <mat-option *ngIf="this.pMS.smallitems[itemindex]['type'] != 'm'" value="0">Any</mat-option>
													  <mat-option *ngIf="this.pMS.smallitems[itemindex]['type'] != '#' && this.pMS.smallitems[itemindex]['type'] != 'm'" value="x">Specific Days of the Month</mat-option>
											      <mat-option value="1">Monday</mat-option>
											      <mat-option value="2">Tuesday</mat-option>
											      <mat-option value="3">Wednesday</mat-option>
											      <mat-option value="4">Thursday</mat-option>
											      <mat-option value="5">Friday</mat-option>
											      <mat-option value="6">Saturday</mat-option>
											      <mat-option value="7">Sunday</mat-option>
											    </mat-select>
											  </mat-form-field>

												 <mat-form-field *ngIf="this.pMS.smallitems[itemindex]['type'] !== '#' && ((this.pMS.smallitems[itemindex]['type']=='m' && this.pMS.smallitems[itemindex]['order']=='x') || this.pMS.smallitems[itemindex]['weekday']=='x')">
				  								<mat-label>Day(s) of Month (comma separated)</mat-label>
											    <input matInput placeholder="Day(s) of Month (comma separated)" [(ngModel)]="this.pMS.smallitems[itemindex]['mdate']">
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.smallitems[itemindex]['type'] == ''">
				  								<mat-label>Date Anchor</mat-label>
											    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.smallitems[itemindex]['datetype']">
											      <mat-option value="delivery">From Delivery Date</mat-option>
											      <mat-option value="sample">From Sample Date</mat-option>
											      <ng-container *ngIf="itemindex>0">
											      	<ng-container *ngFor="let p of this.pMS.smallitems; let pindex=index">
											      		<ng-container *ngIf="pindex<itemindex">
											      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
											      		</ng-container>
											      	</ng-container>
											      </ng-container>
											    </mat-select>
											  </mat-form-field>
											  
											  <div class="row">
												  <button mat-icon-button class="col" (click)="this.pMS.addItem('smallitems')"><i class="material-icons addbutton">add_circle_outline</i></button>
												  <button mat-icon-button class="col" *ngIf="this.pMS.smallitems.length>1" (click)="this.pMS.removeItem('smallitems',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
												</div>

											</div>
										</div>
									</div>

								</ng-container>

							</ng-container>

							<ng-container *ngIf="isAuthenticated('mod-selectouts') && this.pMS.selectoutpricebool && this.pMS.format=='0'">

					      <hr class="thick">

							  <h5 class="mt-2 mb-3">Select-Outs - Payment Scheduling</h5>

							  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.selectoutbool">
					        Payment Scheduling for Exclusively Select-Outs?
					      </mat-slide-toggle>

					      <ng-container *ngIf="this.pMS.selectoutbool">

						      <div *ngFor="let item of this.pMS.selectoutitems; let itemindex=index">

						      	<hr *ngIf="itemindex>0">

										<div class="row justify-content-between">
											<div class="col-xs-6 ml-3">
												<p>Payment - {{ itemindex + 1 }}</p>
											</div>
											<div *ngIf="this.portalService.portalMod()['batch'] && this.pMS.format!=='0'" class="mr-4 ml-3">
											  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.selectoutitems[itemindex]['estimate']">
									        Based on estimate?
									      </mat-slide-toggle>  
											</div>
										</div>

										<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
											<div class="form-container">

											  <mat-form-field>
				    							<mat-label>Period Type</mat-label>
											    <mat-select placeholder="Period Type" [(ngModel)]="this.pMS.selectoutitems[itemindex]['type']">
											      <mat-option value="#">Specific Date</mat-option>
											      <mat-option value="m">Months</mat-option>
													  <mat-option value="w">Weeks</mat-option>
											      <mat-option value="d">Calendar Days</mat-option>
											      <mat-option value="b">Business Days</mat-option>
											    </mat-select>
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.selectoutitems[itemindex]['type'] == 'm'">	
				    							<mat-label>Occurence in Month</mat-label>			    
											    <mat-select placeholder="Occurence in Month" [(ngModel)]="this.pMS.selectoutitems[itemindex]['order']">
											      <mat-option value="f">First</mat-option>
											      <mat-option value="s">Second</mat-option>
											      <mat-option value="t">Third</mat-option>
											      <mat-option value="l">Last</mat-option>
														<mat-option value="x">Specific Day(s)</mat-option>
											    </mat-select>
											  </mat-form-field>
											  
											  <mat-form-field *ngIf="this.pMS.selectoutitems[itemindex]['type'] == '#'">
				    							<mat-label>Date</mat-label>
											    <input matInput [matDatepicker]="specificdate" [(ngModel)]="this.pMS.selectoutitems[itemindex]['timingDate']">
													<mat-datepicker-toggle matIconSuffix [for]="specificdate"></mat-datepicker-toggle>
											    <mat-datepicker #specificdate></mat-datepicker>
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.selectoutitems[itemindex]['type'] != '#' && this.pMS.selectoutitems[itemindex]['type'] != 'm'">
				    							<mat-label>Days after {{ this.pMS.selectoutitems[itemindex]['datetype'] | titlecase }}</mat-label>
											    <input matInput type="number" min="0" step="1" placeholder="Days after {{ this.pMS.selectoutitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.selectoutitems[itemindex]['timing']">
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.selectoutitems[itemindex]['type'] == 'm'">
				    							<mat-label>Months after {{ this.pMS.selectoutitems[itemindex]['datetype'] | titlecase }}</mat-label>
											    <input matInput type="number" min="0" step="1" placeholder="Months after {{ this.pMS.selectoutitems[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.pMS.selectoutitems[itemindex]['timing']">
											  </mat-form-field>

											  <mat-form-field *ngIf="(this.pMS.selectoutitems[itemindex]['type'] != '#' && this.pMS.selectoutitems[itemindex]['type'] != 'm' && this.pMS.selectoutitems[itemindex]['type'] != '')">
				  								<mat-label>Date Anchor</mat-label>
											    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.selectoutitems[itemindex]['datetype']">
											      <mat-option value="delivery">From Delivery Date</mat-option>
											      <mat-option value="sample">From Sample Date</mat-option>
											      <ng-container *ngIf="itemindex>0">
											      	<ng-container *ngFor="let p of this.pMS.selectoutitems; let pindex=index">
											      		<ng-container *ngIf="pindex<itemindex">
											      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
											      		</ng-container>
											      	</ng-container>
											      </ng-container>
											    </mat-select>
											  </mat-form-field>

											</div>
										</div>

										<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
											<div class="form-container">

											  <mat-form-field>
											    <mat-label>{{ (this.portalService.portalMod()['batch'] ? (this.pMS.selectoutitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}</mat-label>
						    					<input matInput type="number" min="0" max="100" step="10" placeholder="{{ (this.portalService.portalMod()['batch'] ? (this.pMS.selectoutitems[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}" [(ngModel)]="this.pMS.selectoutitems[itemindex]['percent']">
											  </mat-form-field>

											  <mat-form-field *ngIf="(this.pMS.selectoutitems[itemindex]['type'] == 'm' && this.pMS.selectoutitems[itemindex]['order'] !== 'x') || this.pMS.selectoutitems[itemindex]['type'] == 'b' || this.pMS.selectoutitems[itemindex]['type'] == 'd' || this.pMS.selectoutitems[itemindex]['type'] == 'w'">				    
				    							<mat-label>On Day</mat-label>
											    <mat-select placeholder="On Day" [(ngModel)]="this.pMS.selectoutitems[itemindex]['weekday']">
													  <mat-option *ngIf="this.pMS.selectoutitems[itemindex]['type'] != 'm'" value="0">Any</mat-option>
													  <mat-option *ngIf="this.pMS.selectoutitems[itemindex]['type'] != '#' && this.pMS.selectoutitems[itemindex]['type'] != 'm'" value="x">Specific Days of the Month</mat-option>
											      <mat-option value="1">Monday</mat-option>
											      <mat-option value="2">Tuesday</mat-option>
											      <mat-option value="3">Wednesday</mat-option>
											      <mat-option value="4">Thursday</mat-option>
											      <mat-option value="5">Friday</mat-option>
											      <mat-option value="6">Saturday</mat-option>
											      <mat-option value="7">Sunday</mat-option>
											    </mat-select>
											  </mat-form-field>

												 <mat-form-field *ngIf="this.pMS.selectoutitems[itemindex]['type'] !== '#' && ((this.pMS.selectoutitems[itemindex]['type']=='m' && this.pMS.selectoutitems[itemindex]['order']=='x') || this.pMS.selectoutitems[itemindex]['weekday']=='x')">
				  								<mat-label>Day(s) of Month (comma separated)</mat-label>
											    <input matInput placeholder="Day(s) of Month (comma separated)" [(ngModel)]="this.pMS.selectoutitems[itemindex]['mdate']">
											  </mat-form-field>

											  <mat-form-field *ngIf="this.pMS.selectoutitems[itemindex]['type'] == ''">
				  								<mat-label>Date Anchor</mat-label>
											    <mat-select placeholder="Date Anchor" [(ngModel)]="this.pMS.selectoutitems[itemindex]['datetype']">
											      <mat-option value="delivery">From Delivery Date</mat-option>
											      <mat-option value="sample">From Sample Date</mat-option>
											      <ng-container *ngIf="itemindex>0">
											      	<ng-container *ngFor="let p of this.pMS.selectoutitems; let pindex=index">
											      		<ng-container *ngIf="pindex<itemindex">
											      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
											      		</ng-container>
											      	</ng-container>
											      </ng-container>
											    </mat-select>
											  </mat-form-field>
											  
											  <div class="row">
												  <button mat-icon-button class="col" (click)="this.pMS.addItem('selectoutitems')"><i class="material-icons addbutton">add_circle_outline</i></button>
												  <button mat-icon-button class="col" *ngIf="this.pMS.selectoutitems.length>1" (click)="this.pMS.removeItem('selectoutitems',itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
												</div>

											</div>
										</div>
									</div>

								</ng-container>

							</ng-container>

				      <hr class="thick">

						  <h5 class="mt-2 mb-3">Monthly Delivery Cut-Off</h5>

						  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.monthlycutoffbool">
				        Monthly Cut-Off? (move to the following month)
				      </mat-slide-toggle>

				      <ng-container *ngIf="this.pMS.monthlycutoffbool">

				      	<br>

				      	<div>
					      	<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
										<div class="form-container">

										  <mat-form-field>
										    <mat-label>Period Type</mat-label>
				    						<mat-select placeholder="Period Type" [(ngModel)]="this.pMS.mthitems[0]['type']">
										      <mat-option value="m">Specific Weekday / Month Day</mat-option>
										      <mat-option value="d">Calendar Days</mat-option>
										      <mat-option value="b">Business Days</mat-option>
										    </mat-select>
										  </mat-form-field>

										</div>
									</div>

									<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
										<div class="form-container">

										  <mat-form-field *ngIf="this.pMS.mthitems[0]['type'] != '#' && this.pMS.mthitems[0]['type'] != 'm'">
										    <mat-label>Day (from & onwards)</mat-label>
				    						<input matInput type="number" min="0" step="1" placeholder="Day (from & onwards)" [(ngModel)]="this.pMS.mthitems[0]['timing']">
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.mthitems[0]['type'] == 'm'">
										    <mat-label>Occurence in Month</mat-label>
				    						<mat-select placeholder="Occurence in Month" [(ngModel)]="this.pMS.mthitems[0]['order']">
										      <mat-option value="f">First</mat-option>
										      <mat-option value="s">Second</mat-option>
										      <mat-option value="t">Third</mat-option>
										      <mat-option value="l">Last</mat-option>
										      <mat-option value="x">Specific Day</mat-option>
										    </mat-select>
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.mthitems[0]['type'] == 'm' && this.pMS.mthitems[0]['order'] !== 'x'">
										    <mat-label>Weekday</mat-label>
				    						<mat-select placeholder="Weekday" [(ngModel)]="this.pMS.mthitems[0]['weekday']">
										      <mat-option value="1">Monday</mat-option>
										      <mat-option value="2">Tuesday</mat-option>
										      <mat-option value="3">Wednesday</mat-option>
										      <mat-option value="4">Thursday</mat-option>
										      <mat-option value="5">Friday</mat-option>
										      <mat-option value="6">Saturday</mat-option>
										      <mat-option value="7">Sunday</mat-option>
										    </mat-select>
										  </mat-form-field>

										  <mat-form-field *ngIf="this.pMS.mthitems[0]['order'] == 'x'">
										    <mat-label>Day of Month (from & onwards)</mat-label>
				    						<input matInput type="number" min="1" max="28" step="1" placeholder="Day of Month (from & onwards)" [(ngModel)]="this.pMS.mthitems[0]['mdate']">
										  </mat-form-field>

										</div>
									</div>

								</div>
							
							</ng-container>

							<hr class="thick">

						  <h5 class="mt-2 mb-3">First Possible Payment Date</h5>

			      	<br>

			      	<div class="col" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <div class="row">
										<div class="col main-header">
									  	<mat-form-field class="w-100">
										    <mat-label>Date</mat-label>
						    				<input matInput [matDatepicker]="minpaydate" [(ngModel)]="this.pMS.minpaydate">
												<mat-datepicker-toggle matIconSuffix [for]="minpaydate"></mat-datepicker-toggle>
										    <mat-datepicker #minpaydate></mat-datepicker>
										  </mat-form-field>
										</div>
										<div class="col-auto pr-0">
											<button mdbBtn class="btn btn-link" (click)="this.pMS.minpaydate=null">Clear</button>
										</div>
									</div>

								</div>
							</div>

						</ng-container>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">VAT</h5>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.vatable">
			        Vat Applied?
			      </mat-slide-toggle>

						<ng-container *ngIf="this.pMS.vatable">

						  <mat-form-field>
						    <mat-label>VAT Rate</mat-label>
				    		<input matInput #vatrate placeholder="VAT Rate" [ngModel]="this.pMS.vatrate | percent:'1.1'" (blur)="changeAmount(vatrate.value,'vatrate',null,null,true)">
						  </mat-form-field>
										  
						  <mat-form-field>
						    <mat-label>VAT Split</mat-label>
				    		<mat-select placeholder="VAT Split" [(ngModel)]="this.pMS.vatsplit" (keydown.tab)="this.pMS.setInvoicingMonthly()">
						      <mat-option value="all">Pro-rata</mat-option>
						      <mat-option (click)="this.pMS.setInvoicingMonthly()" value="firstonly">Upfront</mat-option>
						    </mat-select>
						  </mat-form-field>

						</ng-container>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Fees Payment Split</h5>

					  <mat-form-field>
					    <mat-label>Fee Split</mat-label>
				    	<mat-select placeholder="Fee Split" [(ngModel)]="this.pMS.upfrontfees">
					      <mat-option value="1">All fees charged upfront</mat-option>
					      <mat-option value="0">Fees charged pro-rata</mat-option>
					    </mat-select>
					  </mat-form-field>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Financing Terms</h5>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.financingbool">
			        Financing applies?
			      </mat-slide-toggle>

						<ng-container *ngIf="this.pMS.financingbool">

						  <mat-form-field>
						    <mat-label>Financing Type</mat-label>
					    	<mat-select placeholder="Financing Type" [(ngModel)]="this.pMS.financingtype">
						      <mat-option value="simple">Simple flat fee</mat-option>
						    </mat-select>
						  </mat-form-field>

						  <mat-form-field>
						    <mat-label>Finance Rate</mat-label>
				    		<input matInput #financingrate placeholder="Finance Rate" [ngModel]="this.pMS.financingrate | percent:'1.1'" (blur)="changeAmount(financingrate.value,'financingrate',null,null,true)">
						  </mat-form-field>
						
						</ng-container>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Currency</h5>

					  <mat-form-field>
					    <mat-label>FX Rate (ZAR/USD)</mat-label>
				    	<input matInput type="number" step="0.01" placeholder="FX Rate (ZAR/USD)" [(ngModel)]="this.pMS.fxrate">
					  </mat-form-field>

					  <mat-form-field>
					    <mat-label>Invoice Currency</mat-label>
				    	<mat-select placeholder="Invoice Currency" [(ngModel)]="this.pMS.invcurrency">
					      <mat-option value="ZAR">ZAR</mat-option>
					      <mat-option value="USD">USD</mat-option>
					    </mat-select>
					  </mat-form-field>

					  <mat-form-field>
					    <mat-label>Payment Currency</mat-label>
				    	<mat-select placeholder="Payment Currency" [(ngModel)]="this.pMS.paycurrency">
					      <mat-option value="ZAR">ZAR</mat-option>
					      <mat-option value="USD">USD</mat-option>
					    </mat-select>
					  </mat-form-field>

						<hr class="thick">

					  <h5 class="mt-2 mb-3">Invoice Generator</h5>

					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.pMS.invoicing">
			        Allow Batch Invoicing?
			      </mat-slide-toggle>

						<ng-container *ngIf="this.pMS.invoicing">
										  
						  <mat-form-field>
						    <mat-label>Grouped By</mat-label>
				    		<mat-select placeholder="Grouped By" [(ngModel)]="this.pMS.invoicetype" (keydown.tab)="this.pMS.setVATprorata()">
						      <mat-option value="multiple">Deliveries</mat-option>
						      <mat-option (click)="this.pMS.setVATprorata()" value="month">Instalments - Monthly</mat-option>
						      <mat-option (click)="this.pMS.setVATprorata()" value="week">Instalments - Weekly</mat-option>
						    </mat-select>
						  </mat-form-field>

						</ng-container>

					</ng-container>
				
				</ng-container>

			</div>

			<hr *ngIf="this.editSelected()">

			<button *ngIf="!this.pMS.editmode && canSave()" mdbBtn class="btn btn-primary" (click)="onCalculate()">Save</button>
			<button *ngIf="!this.pMS.editmode && !canSave()" mdbBtn class="btn btn-primary look-disabled" (click)="this.pMS.showError()">Save</button>

			<button *ngIf="this.pMS.editmode && this.editSelected() && canSave()" mdbBtn class="btn btn-primary" (click)="saveChanges()">Update</button>
			<button *ngIf="this.pMS.editmode && this.editSelected() && !canSave()" mdbBtn class="btn btn-primary look-disabled" (click)="this.pMS.showError()">Update</button>

			<button *ngIf="this.pMS.editmode && this.editSelected() && canUpdate()" mdbBtn class="btn btn-success" (click)="saveChanges(true)">Copy with Changes</button>
			<button *ngIf="this.pMS.editmode && this.editSelected() && !canUpdate()" mdbBtn class="btn btn-success look-disabled" (click)="this.pMS.showError()">Copy with Changes</button>

			<button *ngIf="this.pMS.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>
	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>