import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class SplitTermsService{
	public message: string;

	constructor(
		) {}

	addItem(list: any[], fields: any) {
		const thislist: any[] = list;
		thislist.push(fields);
		return thislist;
	}

	removeItem(list: any[], index: number) {
		list.splice(index, 1);
		return list;
	}

	splitTerms(paypercentU: string, paydayU: string, estimate:boolean = false) {
		var list = [];
  	var percents = (paypercentU==null ? null : paypercentU.split(";"));
  	var paydays = (paydayU==null ? null : paydayU.split(";"));
  	if (percents!==null) {
  		for (var i = 0; i < percents.length; i++) {
	  		if (percents[i].length == 0) {
	  			percents.splice(i, 1);
	  		}
	  	}
	  	for (var i = 0; i < paydays.length; i++) {
	  		if (paydays[i].length == 0) {
	  			paydays.splice(i, 1);
	  		}
	  	}
	  	for (var i = 0; i < percents.length; i++) {
	  		var dateanchor = 'delivery';
	  		if (paydays[i].substr(0,1)=='s') {
	  			dateanchor = 'sample';
	  			paydays[i] = paydays[i].substr(1);
	  		} else if (paydays[i].substr(0,1)=='p') {
	  			dateanchor = paydays[i].substr(0,2);
	  			paydays[i] = paydays[i].substr(2);
	  		}
	  		var type = paydays[i].substr(0,1);
				var timing = ''; 
				var timingDate = ''; 
				var position = '';
				var posadj = 0;
				var day = '';
				var mdate = '';
				var fnight = '0';
				var ext = [];
	  		if (type=='m' || type=='w') {
					timing = +paydays[i].substr(1, 2) + '';
					position = paydays[i].substr(3, 1);
					if (position=='w') {
						day = paydays[i].substr(4, 1);
						position = paydays[i].substr(5, 1);
						posadj = 2;
					}
					if (position=='x') {
						mdate = paydays[i].substr(4 + posadj);
						var exttemp = paydays[i].substr(4 + posadj);
						ext = exttemp.match(/.{1,2}/g);
					}
					if (position=='b') {
						fnight = paydays[i].substr(4 + posadj,1);
					} else {
						day = paydays[i].substr(4, 1);
					}
	  		} else if (type=='#') {
	  			timingDate = paydays[i].substr(1,4) + '-' + paydays[i].substr(5,2) + '-' + paydays[i].substr(7,2);
	  		} else {
	  			timing = +paydays[i].substr(1,3) + '';
					position = paydays[i].substr(4, 1);
					if (position=='x') {
						var exttemp = paydays[i].substr(5);
						ext = exttemp.match(/.{1,2}/g);
					} else if (position=='b') {
						fnight = paydays[i].substr(5, 1);
					} else {
						day = (paydays[i].length>4 ? +paydays[i].substr(5, 1) : 0) + '';
					}
	  		}
	  		if (ext.length>0) {
	  			mdate = '';
	  			day = 'x';
	  			for (var j=0; j<ext.length; j++) {
	  				mdate = mdate + (mdate.length>0 ? ',' : '') + ext[j];
	  			}
	  		}
	  		list.push({"estimate": (estimate ? 1 : 0), "type": type, "percent": +percents[i] * 100 + '', "timing": timing, "timingDate": timingDate, "weekday": day, "order": position, "mdate": mdate, "datetype": dateanchor, "ext": ext, "fnight": fnight });
  		}
  	}
  	return list;
  }

  combineTerms(list: any[]) {
  	var datetype = 'delivery';
  	var paypercent = '';
  	var payday = '';
  	var epaypercent = '';
  	var epayday = '';
  	var timing = '';
  	for (var i = 0; i < list.length; i++) {
  		if (list[i]['percent']>0 && list[i]['type']!==undefined) {
	  		if (list[i]['estimate']) {
		  		epaypercent = epaypercent + +list[i]['percent']/100 + ";";
		  		timing = (list[i]['type'] == '#' ? moment(list[i]['timingDate']).format('YYYYMMDD') : list[i]['timing'] );	
		  		var tempPayDay = list[i]['type'] + 
		  			((list[i]['type']=='b' || list[i]['type']=='d') && timing.toString().length<3 ? '0' : '') +
		  			(timing.toString().length==1 ? '0' + timing : timing) + 
		  			(list[i]['type']=='m' && list[i]['order']!='x' ? list[i]['order'] + list[i]['weekday'] : '' ) +
		  			(list[i]['type']=='w' && list[i]['order']=='w' ? 'w' + list[i]['weekday'] : '' ) +
		  			((list[i]['type']=='d' || list[i]['type']=='b') && list[i]['order']=='w' ? 'w' + list[i]['weekday'] : '' ) +
		  			(list[i]['weekday']=='x' && list[i]['mdate'].length>0 ? 'x' + this.checkDateString(list[i]['mdate']) : '' ) +
		  			(list[i]['order']=='b' && list[i]['fnight']>0 ? 'b' + list[i]['fnight'] : '' )
		  			;
		  		epayday = epayday + tempPayDay + ";";
	  		} else {
		  		paypercent = paypercent + +list[i]['percent']/100 + ";";
		  		timing = (list[i]['type'] == '#' ? moment(list[i]['timingDate']).format('YYYYMMDD') : list[i]['timing'] );
		  		var tempPayDay = list[i]['type'] + 
		  			((list[i]['type']=='b' || list[i]['type']=='d') && timing.toString().length<3 ? '0' : '') +
		  			(timing.toString().length==1 ? '0' + timing : timing) + 
		  			(list[i]['type']=='m' && list[i]['order']!='x' ? list[i]['order'] + list[i]['weekday'] : '' ) +
		  			(list[i]['type']=='w' && list[i]['order']=='w' ? 'w' + list[i]['weekday'] : '' ) +
		  			((list[i]['type']=='d' || list[i]['type']=='b') && list[i]['order']=='w' ? 'w' + list[i]['weekday'] : '' ) +
		  			(list[i]['weekday']=='x' && list[i]['mdate'].length>0 ? 'x' + this.checkDateString(list[i]['mdate']) : '' ) +
		  			(list[i]['order']=='b' && list[i]['fnight']>0 ? 'b' + list[i]['fnight'] : '' )
		  			;
		  		payday = payday + (list[i]['type']!='#' ? (list[i]['datetype']==undefined ? '' : (list[i]['datetype']=='sample' ? 's' : (list[i]['datetype'].substr(0, 1)=='p' ? list[i]['datetype'] : '' ) ) ) : '') + tempPayDay + ";";
		  	}
		  }
  	}
  	return {"paypercent": paypercent, "payday": payday, "epaypercent": epaypercent, "epayday": epayday};
  }

  checkTerms(list: [], modelset: boolean = true, alerts: boolean = true) {
  	this.message = null;
  	var tally = 0;
  	var etally = 0;
  	var timingsOk = true;
  	var percentOk = true;
  	for (var i = 0; i < list.length; i++) {
  		tally = tally + (list[i]['estimate'] ? 0 : +list[i]['percent']);
  		etally = etally + (list[i]['estimate'] ? +list[i]['percent'] : 0 );
  		if(+list[i]['percent'] <=  0 || +list[i]['percent'] >100) {
				percentOk = false;
  		}
  		if (list[i]['type'] == '') {
  			timingsOk = false;
  		}
  		if ((list[i]['type'] == 'b' || list[i]['type'] == 'd') && list[i]['timing']==undefined ) {
  			timingsOk = false;
  		}
  		if (list[i]['type'] == 'm' && ( list[i]['order'] === '' || list[i]['order'] == undefined ) ) {
  			if (list[i]['order']=='x' && ( list[i]['mdate'] === '' || list[i]['mdate'] == undefined ) ) {
					timingsOk = false;
				} else if (list[i]['weekday'] === '' || list[i]['weekday'] == undefined ) {
					timingsOk = false;
				}
  		}
  		if (list[i]['type'] == 'w') {
  			if (list[i]['weekday'] === '' || list[i]['weekday'] == undefined ) {
					timingsOk = false;
				}
  		}
  		if ((list[i]['type'] == '#' && (list[i]['timingDate'] === '' || list[i]['timingDate'] == undefined) ) || (list[i]['type'] != '#' && (list[i]['timing'] === ''  || list[i]['timing'] == undefined) ) ) {
  			timingsOk = false;
  		}
  	}
  	if (!percentOk) {
			if (alerts) {this.message = "One of the percentages paid isn't between 0% and 100%";}
		  return false;
  	} else {
	  	if (timingsOk) {
		  	if (((tally==0 && !modelset) || tally == 100) && (etally == 100 || etally == 0)) {
		  		return true;
		  	} else {
		  		if (alerts) {this.message = "Total payout does not equal 100%, please review before trying to save again.";}
		  		return false;
		  	}
		  } else {
		  	if (alerts) {this.message = "Payment schedules are incomplete.";}
		  	return false;
		  }
		}
  }

	transDay(paydayRaw) {
		var payday = [];
		var datetype = 'delivery';
		var dateanchor = 'delivery';
		for (var i = 0; i < paydayRaw.length; i++) {
			if (paydayRaw[i].substr(0,1)=='s') {
				datetype = 'sample';
				paydayRaw[i] = paydayRaw[i].substr(1);
			} else if (paydayRaw[i].substr(0,1)=='p') {
				datetype = paydayRaw[i].substr(0,2);
				paydayRaw[i] = paydayRaw[i].substr(2);
			}
			dateanchor = (datetype.substr(0,1)=='p' ? datetype.substr(1,1) + this.getOrdinal(datetype.substr(1,1)) + ' payment' : datetype);
			var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
			switch (paydayRaw[i].substr(0,1)) {
				case "#":
				// Date
					payday[i] = new Date(paydayRaw[i].substr(1,4), paydayRaw[i].substr(5,2) - 1, paydayRaw[i].substr(7,2)).toDateString();
					break;
				case "m":
					// Month
					var month = paydayRaw[i].substr(1, 2); 
					var position = paydayRaw[i].substr(3, 1);
					var day = paydayRaw[i].substr(4);
					switch (position) {
						case "x":
							pos = (day.length>2 ? 'Days ' : 'Day ') + this.splitDates(day);
							break;
						default:
							var weekday = weekdays[day - 1];
							switch (position) {
								case "l":
									var pos = "Last";
									break;
								case "f":
									var pos = "First";
									break;
								case "s":
									var pos = "Second";
									break;
								case "t":
									var pos = "Third";
									break;
								case "p":
									var pos = "Fourth";
									break;
							}
							pos = pos + ' ' + weekday + ', ';
							break;
						}
						payday[i] = pos + (+month == 0 ? ' of the month' : '') + (+month > 0 ? (+month > 1 ? +month + ' months' : ' the month') + ' after ' + dateanchor + ' date' : 'following ' + dateanchor + ' date');
						break;
				case "w":
					// Weeks
					var pos = this.dateExt(paydayRaw[i],3);
					payday[i] = pos + +paydayRaw[i].substr(1,2) + (+paydayRaw[i].substr(1,2) > 1 ? " weeks" : " week") + ' after ' + dateanchor + ' date';
					break;
				case "b": // Business days
				case "d": // Calendar days
					var pos = this.dateExt(paydayRaw[i],4);
					payday[i] = pos + +paydayRaw[i].substr(1,3) + (paydayRaw[i].substr(0,1)=='b' ? ' business' : '') + (+paydayRaw[i].substr(1,3) > 1 ? ' days' : ' day') + ' after ' + dateanchor + ' date';
					break;
			}
		}
		return payday;
	}

	splitDates(dates: string) {
		var dateTxt = '';
		for (var i=0; i< dates.length / 2; i++) {
			dateTxt = dateTxt + (dateTxt.length>0 ? ', ' : '') + dates.substr(i*2, 2); 
		}
		return dateTxt;
	}

	dateExt(paydayRaw: string,start: number) {
		var type = paydayRaw.substr(start, 1);
		var day = paydayRaw.substr(start + 1);
		var weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
		if (type=='w') {
			if (+day>0) {
				var weekday = weekdays[day];
				var pos = 'First ' + weekday + ' on/following ';
			} else {
				var pos = '';
			}
		} else if (type=='x') {
			var pos = 'On the ' + this.splitDates(day) + ' of the month, ';
		} else {
			var pos = '';
		}
		return pos;
	}

	checkDateString(datestr: string) {
    let numbers;
    if (datestr.includes(',')) {
			numbers = datestr.split(',')
        .map(num => num.trim())
        .filter(num => num.length > 0)
        .map(num => parseInt(num, 10));
		} else {
      numbers = datestr.match(/\d{1,2}/g) || [];
      numbers = numbers.map(num => parseInt(num, 10));
    }
		var newstring =  numbers
      .filter(num => num >= 1 && num <= 31)
      .sort((a, b) => a - b)
      .map(num => num.toString().padStart(2, '0'))
      .join('');
		return newstring;
	}

	getOrdinal(date: string) {
		switch (+date) {
	    case 1:  return "st";
	    case 2:  return "nd";
	    case 3:  return "rd";
	    default: return "th";
	  }
	}

}