<div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
  <svg id="contentToConvert"
   width="100%"
   height="100%"
   viewBox="0 0 1144 1701"
   xmlns="http://www.w3.org/2000/svg" 
   xmlns:xlink="http://www.w3.org/1999/xlink"  
   version="1.2" 
   baseProfile="tiny">
    <title>{{ this.pS.portalMod()['portal'] }} Remittance</title>
    <desc>Generated on the {{ this.pS.portalMod()['portal'] }} Portal</desc>
    <g fill="none" stroke="black" stroke-width="1" fill-rule="evenodd" stroke-linecap="square" stroke-linejoin="bevel" >
    </g>

    <g [attr.transform]="(this.rS.fees?.modelnetsamac > 0 ? 'translate(0,-13)' : 'translate(0,0)')">
      <g [attr.transform]="(this.rS.fees?.financingrate > 0 ? 'translate(0,-20)' : 'translate(0,0)')">

        <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-27)' : 'translate(0,0)')">

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="74.5" y="284" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >VAT Number: {{ this.pS.portalMod()['vat'] }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="248.5" y="284" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >      </text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="272.5" y="284" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >Registration Number: {{ this.pS.portalMod()['regno'] }}</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="300" y="311" font-family="Arial" font-size="15" font-weight="400" font-style="normal" text-anchor="middle"
           >{{ this.pS.portalMod()['fulladdress'] }}</text>
          </g>

          <g *ngIf="this.pS.portalMod()['invoicelogoformat']=='wide'" fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="41.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >T: {{ this.pS.portalMod()['tel'] }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="193.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >M: {{ this.pS.portalMod()['mobile'] }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="338.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >E: {{ this.pS.portalMod()['email'] }}</text>
          </g>

          <g *ngIf="this.pS.portalMod()['invoicelogoformat']=='square'" fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="52.5" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >T: {{ this.pS.portalMod()['tel'] }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="198" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >M: {{ this.pS.portalMod()['mobile'] }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="350" y="338" font-family="Arial" font-size="15" font-weight="400" font-style="normal" 
           >E: {{ this.pS.portalMod()['invoiceemail'] }}</text>
          </g>

        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
        >
        <!--<text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="719" y="108" font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
         >TAX INVOICE</text>-->
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="70" font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
         >REMITTANCE</text>
         <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="667" y="118" font-family="Arial" font-size="48" font-weight="700" font-style="normal" 
         >NOTIFICATION</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="176" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
         >Delivery Number</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="176" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ this.rS.loadedEntry?.iddelivery | delivery }}{{ ( this.rS.fees?.selectout ? ': Select-outs' : '') }}</text>
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="176" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         > </text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="203" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
         >Invoice Date</text>
        </g>

        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
        >
        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="203" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
         >{{ this.rS.loadedEntry?.paydate | date:'d MMMM yyyy, EEE' }}</text>
        </g>

        <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-27)' : 'translate(0,0)')">

         <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="230" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Sample Date</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ this.rS.loadedEntry?.sampledate | date:'d MMMM yyyy, EEE' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           > </text>
          </g>

         <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="257" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Delivery Date</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="257" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ this.rS.loadedEntry?.ddate | date:'d MMMM yyyy, EEE' }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="257" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           > </text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="284" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >WNIS Delivered (kg)</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="284" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ +this.rS.loadedEntry?.wnis + +this.rS.loadedEntry?.smalls | number:'1.2-2' }} kg</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="284" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           > </text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="311" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Cultivar</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="311" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ this.rS.loadedEntry?.cultivar }}</text>
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="311" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           > </text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="338" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
           >Paid Collection?</text>
          </g>

          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
          >
          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="338" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
           >{{ this.rS.loadedEntry?.collected | yesno }}</text>
          </g>

          <g [attr.transform]="(this.rS.fees?.financingrate > 0 ? 'translate(0,-20)' : 'translate(0,0)')">
            <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
            font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
            >
            <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,372 L1132,372, L1132,373, L19,373"/>
            </g>
          </g>
        
        </g>

        <g [attr.transform]="(this.rS.fees?.financingrate > 0 ? 'translate(0,-20)' : 'translate(0,0)')">
          <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-37)' : 'translate(0,0)')">

            <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
            font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
            >
            <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,575 L1132,575 L1132,576 L19,576 L19,576"/>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="403" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
             >PAYEE DETAILS</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Company Name</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.companyname : this.rS.loadedEntry?.pcompanyname ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >VAT Number</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="437" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.vat : this.rS.loadedEntry?.pvat ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Reference</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ this.rS.loadedEntry?.reference }}</text>
            </g>


            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Reg Number</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="467" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.regnumber : this.rS.loadedEntry?.pregnumber ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Address</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" id="address"
             >{{ this.rS.loadedEntry?.fulladdress }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Bank Name</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="497" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.bankname : this.rS.loadedEntry?.pbankname ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Email</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.email : this.rS.loadedEntry?.pemail ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Bank Account</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="527" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.bankaccount : this.rS.loadedEntry?.pbankaccount ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Tel</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="189" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.tel : this.rS.loadedEntry?.ptel ) }}</text>
            </g>

            <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
            font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
            >
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >Bank Sort Code</text>
            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="557" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
             >{{ (this.rS.useChild ? this.rS.loadedEntry?.banksortcode : this.rS.loadedEntry?.pbanksortcode ) }}</text>
            </g>

            <g transform="translate(0,-10)">

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="616" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
               >DELIVERY &amp; SAMPLE REPORT</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >WNIS Delivered (kg) excl. Smalls</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.wnis | number:'1.2-2' }} kg</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Total DNIS Delivered @ {{ this.rS.loadedEntry?.dryfloorlevel | percent:'1.1' }} k.m. (kg)</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="648" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.fees?.dnis + this.rS.fees?.smallsdnis | number:'1.2-2' }} kg</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Smalls Delivered (kg)</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.smalls | number:'1.2-2' }} kg</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >DNIS excl. Smalls (kg)</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ 1 | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="677" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="706" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Premium Kernel delivered</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="706" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ this.rS.loadedEntry?.skr | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="706" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.skr * +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="735" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Dark Kernel delivered</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="735" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="735" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis * +this.rS.fees?.dnis | number:'1.2-2' }} kg</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="746" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Wet Sample Weight</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="746" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.swnis) | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Wet Sample - Kernel Moisture (k.m.)</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.wetkernel | percent:'1.2' }}</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <!--
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Wet Sample - Total Nut Mositure</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.moisture | percent:'1.2' }}</text>
              -->
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Mould / Old</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.mould / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="773" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.mould | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Moisture removed to {{ this.rS.loadedEntry?.dryfloorlevel | percent:'1.1' }} k.m.</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.moisturerem ) | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.swnis - +this.rS.loadedEntry?.sdnis) | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Immature</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.immature / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="800" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.immature | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Dry Sample Weight @ {{ this.rS.loadedEntry?.dryfloorlevel | percent:'1.1' }} k.m.</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >100.00%</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.sdnis) | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >791 Spot</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.spot791 / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="827" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.spot791 | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Shell</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.shell / +this.rS.loadedEntry?.sdnis) | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ (this.rS.loadedEntry?.status=='smalls' ? 0 : +this.rS.loadedEntry?.shell) | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Early Stinkbug</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.earlystink / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="854" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.earlystink | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Total Kernel</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ ( +this.rS.loadedEntry?.sound + +this.rS.loadedEntry?.unsound + +this.rS.loadedEntry?.dark ) / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ ( +this.rS.loadedEntry?.sound + +this.rS.loadedEntry?.unsound + +this.rS.loadedEntry?.dark ) | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Late Stinkbug</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.latestink / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="881" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.latestink | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Premium Kernel</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.sound / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.sound | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Germination</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.germination / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="908" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.germination | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Unsound Kernel</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.unsound / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.unsound | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Nut Borer</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.nutborer / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="935" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.nutborer | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
              font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
              >
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Dark Kernel</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="306" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.dark / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="423" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.dark | number:'1.2-2' }} g</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="612" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >Discolouration</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="832" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.discolouration / +this.rS.loadedEntry?.sdnis | percent:'1.2' }}</text>
              <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="982" y="962" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
               >{{ +this.rS.loadedEntry?.discolouration | number:'1.2-2' }} g</text>
              </g>

              <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
              font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
              >
              <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,982 L1132,982 L1132,983 L19,983 L19,983"/>
              </g>
            
            </g>

            <g transform="translate(0,-20)">
              <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-57)' : 'translate(0,0)')">

                <g transform="translate(0,-20)">

                  <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
                  font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
                  >
                  <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1054 L1132,1054 L1132,1080 L19,1080 L19,1054"/>
                  </g>

                  <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                  font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                  >
                  <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1043" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                   >NET INCOME CALCULATION</text>
                  </g>

                  <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="205" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal">> 25mm</text>
                  <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="410" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal">> 22mm</text>
                  <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="610" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal">> 20mm</text>
                  <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="810" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal">> 18mm</text>
                  <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="1020" y="1072" font-family="Arial" font-size="16" font-weight="700" font-style="normal">Total</text>

                  <g transform="translate(0,5)">

                    <g fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="1" transform="translate(-113,-5)"
                    font-family="Helvetica" font-size="12" font-weight="400" font-style="normal">
                    <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M241,1055 L241,1079, L242,1079, L242,1055"/>
                    </g>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="30" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >Net/kg (table)</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="30" y="1230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >Sized DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="30" y="1255" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >Total Value</text>

                    <g transform="translate(235,1135)">
                      <path *ngFor="let slice of this.rS.svgdonut['dnis25']['donut'] | svgdonut:46:0:15; let index = index"
                        [attr.fill]="slice.color"
                        [attr.d]="slice.commands"
                        [attr.transform]="'rotate(' + slice.offset + ')'"
                      >
                      </path>
                      <text text-anchor="middle" x="0" y="6" font-size="18" font-family="Arial" fill="#646566">{{ this.rS.svgdonut['dnis25']['percent'] | percent:'1.1-1'}}</text>
                    </g>
                    <g transform="translate(95,-5)" fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="1"
                    font-family="Helvetica" font-size="12" font-weight="400" font-style="normal">
                    <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M241,1055 L241,1079, L242,1079, L242,1055"/>
                    </g>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="235" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis25']['rate'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/ kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="235" y="1230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis25']['kg'] | number:'1.2-2'}} kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="235" y="1255" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis25']['rate'] * this.rS.svgdonut['dnis25']['kg'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} Total</text>

                    <g transform="translate(440,1135)">
                      <path *ngFor="let slice of this.rS.svgdonut['dnis22']['donut'] | svgdonut:46:0:15; let index = index"
                        [attr.fill]="slice.color"
                        [attr.d]="slice.commands"
                        [attr.transform]="'rotate(' + slice.offset + ')'"
                      >
                      </path>
                      <text text-anchor="middle" x="0" y="6" font-size="18" font-family="Arial" fill="#646566">{{ this.rS.svgdonut['dnis22']['percent'] | percent:'1.1-1'}}</text>
                    </g>
                    <g fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="1" transform="translate(298,-5)"
                    font-family="Helvetica" font-size="12" font-weight="400" font-style="normal">
                    <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M241,1055 L241,1079, L242,1079, L242,1055"/>
                    </g>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="440" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis22']['rate'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/ kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="440" y="1230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis22']['kg'] | number:'1.2-2'}} kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="440" y="1255" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis22']['rate'] * this.rS.svgdonut['dnis22']['kg'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} Total</text>
                    
                    <g transform="translate(640,1135)">
                      <path *ngFor="let slice of this.rS.svgdonut['dnis20']['donut'] | svgdonut:46:0:15; let index = index"
                        [attr.fill]="slice.color"
                        [attr.d]="slice.commands"
                        [attr.transform]="'rotate(' + slice.offset + ')'"
                      >
                      </path>
                      <text text-anchor="middle" x="0" y="6" font-size="18" font-family="Arial" fill="#646566">{{ this.rS.svgdonut['dnis20']['percent'] | percent:'1.1-1'}}</text>
                    </g>
                    <g fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="1" transform="translate(500,-5)"
                    font-family="Helvetica" font-size="12" font-weight="400" font-style="normal">
                    <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M241,1055 L241,1079, L242,1079, L242,1055"/>
                    </g>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="640" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis20']['rate'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/ kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="640" y="1230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis20']['kg'] | number:'1.2-2'}} kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="640" y="1255" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis20']['rate'] * this.rS.svgdonut['dnis20']['kg'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} Total</text>
                    
                    <g transform="translate(845,1135)">
                      <path *ngFor="let slice of this.rS.svgdonut['dnis18']['donut'] | svgdonut:46:0:15; let index = index"
                        [attr.fill]="slice.color"
                        [attr.d]="slice.commands"
                        [attr.transform]="'rotate(' + slice.offset + ')'"
                      >
                      </path>
                      <text text-anchor="middle" x="0" y="6" font-size="18" font-family="Arial" fill="#646566">{{ this.rS.svgdonut['dnis18']['percent'] | percent:'1.1-1'}}</text>
                    </g>
                    <g fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="1" transform="translate(703,-5)"
                    font-family="Helvetica" font-size="12" font-weight="400" font-style="normal">
                    <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M241,1055 L241,1079, L242,1079, L242,1055"/>
                    </g>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="845" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis18']['rate'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/ kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="845" y="1230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis18']['kg'] | number:'1.2-2'}} kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="845" y="1255" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.svgdonut['dnis18']['rate'] * this.rS.svgdonut['dnis18']['kg'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} Total</text>

                    <g transform="translate(1040,1135)">
                      <path *ngFor="let slice of this.rS.svgdonut['total']['donut'] | svgdonut:46:0:15; let index = index"
                        [attr.fill]="slice.color"
                        [attr.d]="slice.commands"
                        [attr.transform]="'rotate(' + slice.offset + ')'"
                      >
                      </path>
                      <text text-anchor="middle" x="0" y="6" font-size="18" font-family="Arial" fill="#646566">{{ this.rS.svgdonut['total']['percent'] | percent:'1.0-0'}}</text>
                    </g>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="1040" y="1205" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.fees?.ratenetfees | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/ kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="1040" y="1230" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.fees['dnis'] | number:'1.2-2'}} kg DNIS</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" text-anchor="middle" xml:space="preserve" x="1040" y="1255" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ this.rS.fees?.pricenetfees | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} Total</text>

                  </g>

                </g>

                <g transform="translate(0,60)">

                  <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="translate(0,145)"
                  font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
                  >
                  <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1054 L1132,1054 L1132,1080 L19,1080 L19,1054"/>
                  </g>


                  <text fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1217" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                   >Income & Fees</text>
                  <text fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1217" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                   >Rate</text>
                  <text fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1217" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                   >Quantity</text>
                  <text fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1217" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                   >Total (excl. VAT)</text>
                  <text fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1217" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                   >VAT</text>
                  <text fill="#fff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1217" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                   >Total (inc. VAT)</text>

                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1245" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >Income - Premium</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1245" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.ratenetfees | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1245" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees['dnis'] | number:'1.2-2' }} kg</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1245" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.pricenetfees | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1245" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.pricenetfees * +this.rS.fees?.vatable * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1245" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.pricenetfees * ( 1 + +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1270" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >Income - Smalls</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1270" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.smallsrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1270" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ this.rS.fees['smallsdnis'] | number:'1.2-2' }} kg</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1270" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.smallsprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1270" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.smallsprice * +this.rS.fees?.vatable * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1270" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.smallsprice * ( 1 + +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1295" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >Income - Darks</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1295" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.darkkernelrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg kernel</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1295" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.loadedEntry['dkr'] * this.rS.fees['dnis'] | number:'1.2-2' }} kg</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1295" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.darkprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1295" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.darkprice * +this.rS.fees?.vatable * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                  <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1295" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                   >{{ +this.rS.fees?.darkprice * ( 1 + +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                  <ng-container *ngIf="this.rS.fees?.modelnetsamac">
                  
                   <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >SAMAC Levy (incl. in gross)</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.samacrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS + VAT</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees['dnis'] + this.rS.fees['smallsdnis'] | number:'1.2-2' }} kg</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.samacexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.samacexvat * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.samacincvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                   </ng-container>

                  <g [attr.transform]="(this.rS.fees?.modelnetsamac ? 'translate(0,25)' : 'translate(0,0)' )">

                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >Drying</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.dryrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg moisture removed</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.moistrem | number:'1.2-2' }} kg  ({{ +this.rS.fees?.moistrem / +this.rS.loadedEntry?.twnis | percent:'1.2-2' }})</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees?.dryfee | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees?.dryfee * ( +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1320" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees?.dryfee * ( 1 + +this.rS.fees?.vatable * +this.rS.fees?.vatrate ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1345" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ (this.rS.fees['transportdepot'] ? 'Depot Handling Fee' : 'Transport') }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1345" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                    >
                       <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeebase']>0">{{ +this.rS.fees['transportfeebase'] | currency:this.rS.fees['transportkmcurr']:'symbol-narrow' }}
                      </ng-container>
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeekm']>0 && +this.rS.fees['transportfeebase']>0">{{ " + " }}
                      </ng-container>
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeekm']>0">{{ +this.rS.fees['transportfeekm'] | currency:this.rS.fees['transportkmcurr']:'symbol-narrow' }}/km
                      </ng-container>
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeewnis']>0 && +this.rS.fees['transportfeebase']>0">{{ " + " }}
                      </ng-container>
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeewnis']>0">{{ +this.rS.fees['transportfeewnis'] | currency:this.rS.fees['transportcurr']:'symbol-narrow' }}/kg WNIS
                      </ng-container>
                      <ng-container *ngIf="this.rS.fees['transportdepot']">{{ +this.rS.fees['transportdepotdnis'] | currency:this.rS.fees['transportcurr']:'symbol-narrow' }}/kg DNIS
                      </ng-container>
                    </text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1345" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeewnis']>0">{{ +this.rS.loadedEntry?.wnis + +this.rS.loadedEntry?.smalls | number:'1.2-2' }} kg
                      </ng-container>
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeekm']>0 && +this.rS.fees['transportfeewnis']>0">{{ ", " }}
                      </ng-container>
                      <ng-container *ngIf="!this.rS.fees['transportdepot'] && +this.rS.fees['transportfeekm']>0">{{ +this.rS.fees['transportkm'] | number:'1.0-0' }} km
                      </ng-container>
                      <ng-container *ngIf="this.rS.fees['transportdepot']">{{ +this.rS.fees['dnis'] + this.rS.fees['smallsdnis'] | number:'1.2-2' }} kg
                      </ng-container>
                    </text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1345" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees['transportfee'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1345" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees['transportfee'] * ( +this.rS.fees['vatable'] * +this.rS.fees['vatrate'] ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1345" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees['transportfee'] * ( 1 + +this.rS.fees['vatable'] * +this.rS.fees['vatrate'] ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >SAMAC Levy</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees?.samacrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}/kg DNIS + VAT</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >{{ +this.rS.fees['dnis'] + this.rS.fees['smallsdnis'] | number:'1.2-2' }} kg</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees?.samacexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees?.samacexvat * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >-{{ +this.rS.fees?.samacincvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                     <!--
                    <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1370" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                     >Included in base rate</text>-->

                    <ng-container *ngIf="this.rS.fees?.mrlclass=='i'">
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1395" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >MRL Fee</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="280" y="1395" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >{{ this.rS.fees['mrlexvat'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }} + VAT</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="510" y="1395" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >Seasonal (per farm)</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="680" y="1395" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >-{{ this.rS.fees['mrlexvat'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1395" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >-{{ this.rS.fees['mrlincvat'] - this.rS.fees['mrlexvat'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1395" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >-{{ this.rS.fees['mrlincvat'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                    </ng-container>

                    <g [attr.transform]="(this.rS.fees?.mrlclass=='i' ? 'translate(0,0)' : 'translate(0,-27)')">

                      <g fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="1" font-family="Helvetica" font-size="12" font-weight="400" font-style="normal">
                      <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1408 L1132,1408, L1132,1409, L19,1409"/>
                      </g>

                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1430" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >SUBTOTAL</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1430" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >{{ this.rS.fees['netprice'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1455" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >VAT</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1455" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >{{ this.rS.fees['vatpayable'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1480" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >TOTAL</text>
                      <text fill="#000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1480" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                       >{{ this.rS.fees['netvat'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>

                    </g>
                  
                  </g>

                </g>

                <ng-container *ngIf="+this.rS.fees?.financingrate > 0">
                  <g [attr.transform]="(this.rS.fees?.modelnetsamac ? 'translate(0,22)' : 'translate(0,0)' )">
                    <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,0)' : 'translate(0,12)')">
                      <g transform="translate(0,10)">

                        <g transform="translate(0,250)">
                          <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
                          font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" 
                          >
                          <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1265 L1132,1265 L1132,1289 L19,1289 L19,1265"/>
                          </g>

                          <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
                          font-family="Helvetica" font-size="12" font-weight="400" font-style="normal" [attr.transform]="(this.rS.fees?.mrlclass=='i' ? 'translate(0,27)' : 'translate(0,0)')"
                          >
                          <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1321 L1132,1321 L1132,1322 L19,1322 L19,1321"/>
                          </g>

                          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                          font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                          >
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1255" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                           >FINANCING{{ (+this.rS.fees?.financingrate > 0 ? ' - Flat rate of ' : '' ) }}{{ (+this.rS.fees?.financingrate > 0 ? +this.rS.fees?.financingrate : '' ) | percent:'1.1' }}</text>
                          </g>

                          <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                          font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                          >
                          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                           >Term</text>
                          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="311" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                           >Rate</text>
                          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                           >Total to Finance</text>
                          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                           >Finance Cost</text>
                          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                           >VAT</text>
                          <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1282" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                           >Total</text>
                          </g>
                        </g>

                        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                        font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                        >
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                         >Flat rate for all payments</text>
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="311" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                         >{{ +this.rS.fees?.financingrate | percent:'1.1' }}</text>
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="427" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                         >{{ this.rS.fees['netprice'] | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                         >{{ (+this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0))) * +this.rS.fees?.financingrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="847" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                         >{{ (+this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0))) * +this.rS.fees?.financingrate / (1 + +this.rS.fees?.vatrate) * +this.rS.fees?.vatrate | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="997" y="1561" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                         >{{ (+this.rS.fees?.totalgrossprice - (+this.rS.fees?.netfees - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0))) * +this.rS.fees?.financingrate * (1 + +this.rS.fees?.vatrate) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                        </g>

                      </g>
                    </g>
                  </g>
                </ng-container>

                <g [attr.transform]="(this.rS.fees?.modelnetsamac ? 'translate(0,22)' : 'translate(0,0)' )">
                  <g [attr.transform]="(this.rS.fees?.financingrate > 0 ? 'translate(0,83)' : 'translate(0,0)' )">
                    <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-17)' : 'translate(0,25)')">
                      <g [attr.transform]="(this.rS.fees?.mrlclass=='i' ? 'translate(0,25)' : 'translate(0,0)')">

                        <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="0" transform="matrix(1,0,0,1,0,0)"
                        font-family="Helvetica" font-size="12" font-weight="400" font-style="normal"
                        >
                        <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1531 L1132,1531 L1132,1555 L19,1555 L19,1531"/>
                        </g>

                        <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                        font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                        >
                        <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1521" font-family="Arial" font-size="19" font-weight="700" font-style="normal" 
                         >PAYMENTS</text>
                        </g>

                        <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                        font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                        >
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >Payment Date</text>
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >Gross</text>
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >Fees</text>
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >Gross</text>
                        </g>

                        <g fill="#ffffff" fill-opacity="1" stroke="#ffffff" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                        font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                        >
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >Fees</text>
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >VAT</text>
                        <text fill="#ffffff" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1548" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                         >Total (inc. VAT)</text>
                        </g>

                        <ng-container *ngIf="this.rS.fees?.mrlclass=='p'">

                          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                          >
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ this.rS.payments.m?.paydate | date:'d MMMM yyyy' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >MRL Fee</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-{{ +this.rS.fees?.mrlexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-{{ +this.rS.fees?.mrlincvat - +this.rS.fees?.mrlexvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-{{ +this.rS.fees?.mrlincvat | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          </g>

                        </ng-container>

                        <g [attr.transform]="(this.rS.fees?.mrlclass=='p' ? 'translate(0,25)' : 'translate(0,0)')">
                          
                          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                          font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                          >
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ this.rS.payments.one?.paydate | date:'d MMMM yyyy' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.one?.paypercent | percent:'1.0' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ ( this.rS.fees?.upfrontfees ? 1 : +this.rS.payments.one?.paypercent ) | percent:'1.0' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.one?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 1 : +this.rS.payments.one?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ ( this.rS.fees?.mrlclass=='p' ? 0 : +this.rS.fees?.vatable * +this.rS.payments.one?.vatdue - (1 - +this.rS.fees?.vatable) * +this.rS.fees?.feevat ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1575" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.one?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          </g>

                          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                          font-family="Arial" font-size="16" font-weight="400" font-style="normal"  *ngIf="+this.rS.payments.two?.paypercent > 0"
                          >
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ this.rS.payments.two?.paydate | date:'d MMMM yyyy' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.two?.paypercent | percent:'1.0' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.two?.paypercent ) | percent:'1.0' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.two?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.two?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.two?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1600" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.two?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          </g>

                          <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                          font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.three?.paypercent > 0"
                          >
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ this.rS.payments.three?.paydate | date:'d MMMM yyyy' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.three?.paypercent | percent:'1.0' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.three?.paypercent ) | percent:'1.0' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.three?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.three?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.three?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1625" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                           >{{ +this.rS.payments.three?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                          </g>

                          <ng-container *ngIf="this.rS.payments?.count>3">

                             <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                            font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.four?.paypercent > 0"
                            >
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ this.rS.payments.four?.paydate | date:'d MMMM yyyy' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.four?.paypercent | percent:'1.0' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.four?.paypercent ) | percent:'1.0' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.four?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.four?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.four?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1650" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.four?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            </g>


                             <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                            font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.five?.paypercent > 0"
                            >
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ this.rS.payments.five?.paydate | date:'d MMMM yyyy' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.five?.paypercent | percent:'1.0' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.five?.paypercent ) | percent:'1.0' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.five?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.five?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.five?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1675" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.five?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            </g>


                             <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                            font-family="Arial" font-size="16" font-weight="400" font-style="normal" *ngIf="+this.rS.payments.six?.paypercent > 0"
                            >
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="34" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ this.rS.payments.six?.paydate | date:'d MMMM yyyy' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="204" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.six?.paypercent | percent:'1.0' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="321" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.six?.paypercent ) | percent:'1.0' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="438" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.six?.paypercent * +this.rS.fees?.totalgrossprice | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >-{{ ( +this.rS.fees?.netfees - (this.rS.fees?.mrlclass=='p' ? +this.rS.fees?.mrlfee : 0) ) * ( this.rS.fees?.upfrontfees ? 0 : +this.rS.payments.six?.paypercent ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.six?.vatdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1700" font-family="Arial" font-size="16" font-weight="400" font-style="normal" 
                             >{{ +this.rS.payments.six?.amountdue | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                            </g>

                          </ng-container>

                        </g>

                      </g>
                    </g>
                  </g>
                </g>

              </g> <!-- Payments > 3 -->
            </g>

          </g>
        </g>
        
        <g [attr.transform]="(this.rS.fees?.modelnetsamac ? 'translate(0,22)' : 'translate(0,0)' )">
          <g [attr.transform]="(this.rS.fees?.financingrate > 0 ? 'translate(0,37)' : 'translate(0,0)' )">
            <g [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-10)' : 'translate(0,0)')">

              <g [attr.transform]="(this.rS.fees?.mrlclass=='i' || this.rS.fees?.mrlclass=='p' ? 'translate(0,27)' : 'translate(0,0)')">

                <g fill="#000000" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="1" stroke-linecap="square" stroke-linejoin="bevel" transform="matrix(1,0,0,1,0,0)"
                font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                >
                <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="627" y="1656" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >NET</text>
                <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="850" y="1656" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >{{ ( +this.rS.fees?.netvat==0 ? 0 : ( this.rS.fees?.mrlclass=='p' ? -(this.rS.fees?.mrlincvat - this.rS.fees?.mrlexvat) : +this.rS.fees?.totalgrossprice * +this.rS.fees?.vatable * +this.rS.fees?.vatrate - +this.rS.fees?.feevat ) ) | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                <text fill="#000000" fill-opacity="1" stroke="none" stroke-opacity="0" xml:space="preserve" x="980" y="1656" font-family="Arial" font-size="16" font-weight="700" font-style="normal" 
                 >{{ 
                 ( +this.rS.payments.one?.amountdue>0 ? +this.rS.payments.one?.amountdue : 0 ) + ( +this.rS.payments.two?.amountdue>0 ? +this.rS.payments.two?.amountdue : 0 ) + ( +this.rS.payments.three?.amountdue>0 ? +this.rS.payments.three?.amountdue : 0 ) + ( +this.rS.payments.four?.amountdue>0 ? +this.rS.payments.four?.amountdue : 0 ) + ( +this.rS.payments.five?.amountdue>0 ? +this.rS.payments.five?.amountdue : 0 ) + ( +this.rS.payments.six?.amountdue>0 ? +this.rS.payments.six?.amountdue : 0 )
                 - (this.rS.fees?.mrlclass=='p' ? this.rS.fees?.mrlincvat : 0)
                 | currency:this.rS.loadedEntry?.invcurrency:'symbol-narrow' }}</text>
                </g>

                <g fill="#7c7c7c" fill-opacity="1" stroke="none" stroke-opacity="1" transform="matrix(1,0,0,1,0,0)"
                font-family="Helvetica" font-size="12" font-weight="400" font-style="normal"
                >
                <path vector-effect="non-scaling-stroke" fill-rule="evenodd" d="M19,1638 L1132,1638 L1132,1639 L19,1639 L19,1638"/>
                </g>

              </g>
            
            </g>
          </g>

          <g [attr.transform]="(this.rS.fees?.financingrate > 0 ? 'translate(0,25)' : 'translate(0,0)' )">
            <text fill="#000000" fill-opacity="0.5" stroke="none" stroke-opacity="0" xml:space="preserve" x="19" y="1683" font-family="Arial" font-size="12" font-weight="300" font-style="normal">Date Generated: {{ this.d | date:'d MMMM yyyy' }}</text>
          </g>

        </g>

        <g  [attr.transform]="(this.rS.payments?.count>3 ? 'translate(0,-27)' : 'translate(0,0)')">

          <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='wide'" [attr.xlink:href]="this.pS.invoicelogo" x="70px" y="10" height="223px" width="455px"/>
          <image *ngIf="this.pS.portalMod()['invoicelogoformat']=='square'" [attr.xlink:href]="this.pS.invoicelogo" x="160px" y="40" height="223px" width="255px"/>

        </g>

      </g>
    </g>

  </svg>
</div> 