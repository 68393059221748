import { Injectable } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';

let prodmode = "dev-";

//*

let portalRoot = {
	"dev" : "",
	"root" : "https://ambermacs.ddns.net/", // Cloud Prod
	//"root" : "http://ambermacs-local.ddns.net/", // Local Pi
	//"root" : "https://10.0.0.220/", // Local Pi
	//"root" : "https://105.247.154.147:8883/", // Hardwire local
	"logo" : "logo.png",
	"externallogo" : "ambercircle.png",
	"icon" : "icon-ambermacs.png",
 	"invoicelogo" : "/assets/invoicelogo.png",
}

let portalModule = {
	"version": "2024-12-121",
	"confirm" : 'K5PgwZ+v^RY6rktUx*sfgWD&cCVsT@',
	"portal" : "AmberMacs",
	"header" : "AmberMacs",
	"externallink" : "https://www.ambercircle.co.za/home/",
	"externallinkname" : "",
	"mod-clients" : true,
	"mod-logistics" : true,
	"mod-buying" : true,
	"mod-qr": true
};
/*/

let portalRoot = {
	"dev" : "",
	"root" : "https://mayomacs-test.ddns.net/",
	//"root" : "http://mayomacs-test.ddns.net:8880/",
	"logo" : "Mayo-Mac-Logo.png",
	"externallogo" : null,
	"icon" : "Mayo-Favicon.png",
 	"invoicelogo" : "/assets/Mayo Logo wide.png",
}

let portalModule = {
	"version": "2024-12-091",
	"confirm" : 'K4gYkL6H})h[Z`!-v7y;ewscfr@u',
	"portal" : "Mayo Macs Macadamias",
	"header" : "Mayo Macs",
	"externallink" : null,
	"mod-clients" : true,
	
};

//*/

/*
	"mod-calc" : true,
	"mod-qr": true,
	"mod-processing": true,
	"mod-inventory": true,
	"mod-silos": true,
	"mod-orders": true,
	"mod-siloselect": false,
	"mod-depot": false,
	"mod-trace": true,
	"mod-clients": true,
	"mod-currency": true,
	"mod-messages": true,
	"mod-query": false,
	"mod-smalldelivery" : false,
	"mod-selectouts" : false,
	"mod-dryfloor": false,
	"mod-dryfloorextra": false,
	"mod-schedule": true,
	"mod-resample": false,
	"mod-invoice" : false,
	"mod-pallet" : false,
	*/

@Injectable()
export class PortalService{

	public mode: string = prodmode;
	public dev: string = portalRoot['dev'];
	public version: string = portalRoot['version'];
	public root: string = portalRoot['root'];
	public logo: string = portalRoot['logo'];
	public icon: string = portalRoot['icon'];
	public invoicelogo: string = portalRoot['invoicelogo'];
	public externallogo: string = portalRoot['externallogo'];
	public externallink: string = portalModule['externallink'];
	public externallinkname: string = portalModule['externallinkname'];
	public portal: string = portalModule['portal'];
	public header: string = portalModule['header'];
	public companyname: string = portalModule['companyname'];
	public footer: string = portalModule['footer'];
	public footerName: string = portalModule['footerName'];
	public blog: string = portalModule['blog'];
	public invoicelogoformat: string = portalModule['invoicelogoformat'];
	public vat: string = portalModule['vat'];
	public regno: string = portalModule['regno'];
	public fulladdress: string = portalModule['fulladdress'];
	public address: string = portalModule['address'];
	public area: string = portalModule['area'];
	public state: string = portalModule['state'];
	public postcode: string = portalModule['postcode'];
	public country: string = portalModule['country'];
	public tel: string = portalModule['tel'];
	public mobile: string = portalModule['mobile'];
	public email: string = portalModule['email'];
	public invoiceemail: string = portalModule['invoiceemail'];
	public plistemail: string = portalModule['plistemail'];
	public chineseregno: string = portalModule['chineseregno'];
	public chineseregcode: string = portalModule['chineseregcode'];
	public director: string = portalModule['director'];
	public startyear: number = portalModule['startyear'];
	public palletsize: number = portalModule['palletsize'];
	public lines: any = portalModule['palletlines'];

	private postBody: {};
	public http = {
		team : (this.mode=='dev' ?  'api/' + this.dev + 'jwt-team/' : this.root + 'api/jwt-team/index.php'),
		buying : (this.mode=='dev' ?  'api/' + this.dev + 'jwt-buying/' : this.root + 'api/jwt-buying/index.php'),
		logistics : (this.mode=='dev' ?  'api/' + this.dev + 'jwt-logistics/' : this.root + 'api/jwt-logistics/index.php'),
		client : (this.mode=='dev' ?  'api/' + this.dev + 'jwt-client/' : this.root + 'api/jwt-client/index.php')
	};

	constructor(
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    ) {}
	
	public portalMod() {
		return portalModule;
	}

	public calMoisture(kernel: number, dmoist: number = 0.0363) {
		var moisture = 1 - (1 - (Math.log(kernel * 100) + 0.142) / 15.1) / (1 - dmoist);
		return	moisture;
	}

	public calInvMoisture(moisture: number, dmoist: number = 0.0363) {
		var kernel = Math.exp(15.1 * (1 - (1 - moisture) * (1 - dmoist)) - 0.142) / 100;
		return kernel;
	}

	public dnis(wnis:number, moisture: number, dryfloor: number = 0.015, type: string = 'formula', dmoist: number = 0) {
		if (type=='formula') {
			var dryfloor = this.calMoisture(dryfloor, dmoist);
			//dryfloor = 0.03;
			var dnis = wnis * (1 - moisture) / (1 - dryfloor);
		} else {
			var dnis = wnis * (1 - moisture + dryfloor);
		}
		return	dnis;
	}

	public wnis(dnis:number, moisture: number, dryfloor: number = 0.015, type: string = 'formula', dmoist: number = 0) {
		if (type=='formula') {
			var dryfloor = this.calMoisture(dryfloor, dmoist);
			//dryfloor = 0.03;
			var wnis = dnis / (1 - moisture) * (1 - dryfloor);
		} else {
			var wnis = dnis / (1 - moisture + dryfloor);
		}
		return	wnis;
	}

	public addDaysToDate(days, startdate = '2000-01-01') {
	  var startDate = new Date(startdate);
	  var endDate = new Date(startDate.getTime() + ((days) * 24 * 60 * 60 * 1000));
	  var year = endDate.getFullYear();
	  var month = String(endDate.getMonth() + 1).padStart(2, '0');
	  var day = String(endDate.getDate()).padStart(2, '0');
	  return year + '-' + month + '-' + day;
	}

	public updatePortal(portal) {
		portalModule = portal;
	}

	async loadData(portal: string, api: string, param) {
		const response = await this.dataRequest(portal, api, param);
    this.setSession(portal, response);
		return response['response']['result'];
	}

  async dataRequest(portal: string, api: string, param) {
		this.postBody = {
			"name": api,
			"param": param
		};
		var token = this.tokenId(portal);
		let response = await fetch(
			this.http[portal],
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem(token['id'])
				}
			}
		)
		return response.json();
	}

	setSession(portal, authResult) {		
		if (authResult != undefined) {
	    if (authResult.response.result.token != null) {
	    	var token = this.tokenId(portal);
			  localStorage.setItem(token['id'], authResult.response.result.token);
			  localStorage.setItem(token['mship'], JSON.stringify(authResult.response.result.ms));
			  return true;
			} else {
				return false;
			}
		} else {
			return false
		}
	}

	tokenId(portal) {
		var id;
		var mship;
		switch (portal) {
			case "team":
				id = 'team_id_token';
				mship = 'team_memberships';
				break;
			case "logistics":
				id = 'logistics_id_token';
				mship = 'logistics_memberships';
				break;
			case "buying":
				id = 'buying_id_token';
				mship = 'buying_memberships';
				break;
			case "client":
				id = 'id_token';
				mship = 'memberships';
				break;
		}
		return {'id': id, 'mship': mship};
	}

	toast(summary, detail, severity = 'success', life = 2000, key = 'tc') {
		var sticky = (life==0 ? true : false);	
    this.messageService.add({key: key, severity:severity, life:life, summary:summary, detail:detail, sticky:sticky});
	}

	confirmBool(message, acceptLabel, rejectLabel): Promise<boolean> {
    return new Promise(resolve => {
      this.confirmationService.confirm({
        message,
	      acceptLabel: acceptLabel,
	      rejectLabel: rejectLabel,
        accept: () => {
          resolve(true);
        },
        reject: () => {
          resolve(false);
        }
      })
    });
  }

  lineBreak(field: string, maxchar: number) {
    var result = [];
    result[0] = (field==null ? '' : field);
    for (var i=0; i<result.length; i++) {
      if (result[i].length>maxchar) {
        var thisstr = result[i];
        var searchstr = thisstr.substr(0,maxchar);
        var startchar = searchstr.lastIndexOf(', ');
        startchar = (startchar == -1 ? searchstr.lastIndexOf(' ') : startchar+1);
        result[i] = thisstr.substr(0, startchar);
        result[i+1] = thisstr.substr(startchar+1);
      }
    }
    return result;
  }

  transdetail(i, pixels = 10.2) {
    return 'translate(0 ' + pixels * i + ')';
  }


} 

export function portalM() {
	return portalModule;
}

