<div class="container">
  <div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
    <div class="row justify-content-between">
      <div class="col-xs-6 main-header">
        <h2>Shipping Instruction</h2>
      </div>
      <div class="ml-3 col-auto">
        <button mdbBtn class="btn btn-secondary" (click)="svgPDF()">Save PDF</button>
      </div>
    </div>
    <br>
  
    <div class="p-4 mb-4" align="center" style="border: 2px solid #7c7c7c">
      <svg id="shippinginstruction"
       viewBox="0 0 550 800"
       xmlns="http://www.w3.org/2000/svg" 
       xmlns:xlink="http://www.w3.org/1999/xlink"  
       version="1.2" 
       baseProfile="tiny">
        <defs>
          <style>
            .cls-1 {
              fill: gray;
            }

            .cls-2 {
              font-size: 9.96px;
            }

            .cls-10, .cls-19, .cls-2, .cls-30, .cls-31, .cls-33 {
              font-family: Arial-Bold, Arial;
              font-weight: 700;
            }

            .cls-10, .cls-19, .cls-3, .cls-8, .cls-33 {
              font-size: 7.8px;
            }

            .cls-3, .cls-8 .cls-35 .cls-36 {
              font-family: Arial, Arial;
            }

            .cls-4 {
              letter-spacing: 9.76em;
            }

            .cls-5 {
              letter-spacing: 8.64em;
            }

            .cls-6 {
              letter-spacing: 6.69em;
            }

            .cls-7 {
              letter-spacing: 0em;
            }

            .cls-8, .cls-9 {
              fill: #0563c1;
            }

            .cls-10 {
              fill: #fff;
            }

            .cls-11 {
              letter-spacing: 8.1em;
            }

            .cls-12 {
              letter-spacing: 7.11em;
            }

            .cls-13 {
              letter-spacing: 2.38em;
            }

            .cls-14 {
              letter-spacing: 5.76em;
            }

            .cls-15 {
              letter-spacing: 7.39em;
            }

            .cls-16 {
              letter-spacing: 2.77em;
            }

            .cls-17 {
              letter-spacing: 9.68em;
            }

            .cls-18 {
              letter-spacing: 4.13em;
            }

            .cls-20 {
              letter-spacing: 3.55em;
            }

            .cls-21 {
              letter-spacing: 9.97em;
            }

            .cls-22 {
              letter-spacing: 7.16em;
            }

            .cls-23 {
              letter-spacing: 3.07em;
            }

            .cls-24 {
              letter-spacing: 0em;
            }

            .cls-25 {
              letter-spacing: 5.31em;
            }

            .cls-26 {
              letter-spacing: 8.72em;
            }

            .cls-27 {
              letter-spacing: 9.27em;
            }

            .cls-28 {
              letter-spacing: 6.28em;
            }

            .cls-29 {
              letter-spacing: 0em;
            }

            .cls-30 {
              font-size: 14.22px;
            }

            .cls-31 {
              font-size: 19.86px;
              fill: #c00000;
            }

            .cls-32 {
              fill: #8ec5e769;
            }

            .cls-33 {
              fill: #9ce78e69;
            }

            .cls-34 {
              font-family: Arial, Arial;
              font-size: 7px;
            }

            .cls-35 {
              font-size: 7px;
            }
            
            .cls-36 {
              font-size: 7.8px;
              font-weight: 700;
            }

            .cls-38 {
              fill: #ffe2ac69;
            }

            .cls-39 {
              fill: #ff2a2a;
            }

          </style>
        </defs>
        <title>Shipping Instruction</title>

        <g transform="translate(0 10)">
          <g>
            <rect x="10" y="10" width="530" height="30" class="cls-32"></rect>
            <rect x="10" y="10" width="530" height="0.6"/>
            <rect x="10" y="40" width="530" height="0.6"/>
            <rect x="10" y="10" width="0.6" height="30"/>
            <rect x="540" y="10" width="0.6" height="30"/>
            <text class="cls-2" transform="translate(275 22.5)" text-anchor="middle">{{ this.pS.portalMod()['companyname'] | uppercase }}</text>
            <text class="cls-2" transform="translate(275 35)" text-anchor="middle">Shipping Instruction</text>
          </g>
          <g transform="translate(0 -20)">
            <g>
              <rect x="10" y="60" width="0.6" height="720"/>
              <rect x="200" y="60" width="0.6" height="460"/>
              <rect x="540" y="60" width="0.6" height="720"/>
            </g>
            <g ><!--[attr.transform]="transline(0)">-->
              <rect x="10" y="75" width="530" height="0.6"/>
              <text class="cls-36" transform="translate(15 70)">Freight Forwarder</text>
              <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.freight }}</text>
            </g>
            <g transform="translate(0 15)">
              <rect x="10" y="75" width="530" height="0.6"/>
              <text class="cls-36" transform="translate(15 70)">Mode of Transport</text>
              <text class="cls-3" transform="translate(210 70)">Sea</text>
            </g>
            <g transform="translate(0 30)">
              <rect x="10" y="75" width="530" height="0.6"/>
              <text class="cls-36" transform="translate(15 70)">Exporters Custom Code</text>
              <text class="cls-3" transform="translate(210 70)">21845464</text>
            </g>
            <g transform="translate(0 45)">
              <rect x="10" y="135" width="530" height="0.6"/>
              <text class="cls-36" transform="translate(15 70)">Exporter / Shipper</text>
              <text class="cls-3" transform="translate(210 70)">{{ this.pS.portalMod()['companyname'] }}</text>
              <text class="cls-3" transform="translate(210 80)">{{ this.pS.portalMod()['address'] }}</text>
              <text class="cls-3" transform="translate(210 90)">{{ this.pS.portalMod()['area'] }} {{ this.pS.portalMod()['postcode'] }}</text>
              <text class="cls-3" transform="translate(210 100)">{{ this.pS.portalMod()['state'] }}</text>
              <text class="cls-3" transform="translate(210 110)">{{ this.pS.portalMod()['country'] }}</text>
              <text class="cls-3" transform="translate(210 120)">logistics@ambermacs.com</text>
              <text class="cls-3" transform="translate(210 130)">VAT {{ this.pS.portalMod()['vat'] }}</text>
            </g>
            <g transform="translate(0, -15)">
              <g transform="translate(0 135)">
                <rect x="10" y="120" width="530" height="0.6"/>
                <rect x="10" y="60" width="530" height="120" class="cls-38"></rect>
                <text class="cls-36" transform="translate(15 70)">Consignee / Notifying Party Address</text>
                <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consignee }}</text>
                <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.longconaddr==''">
                  <text class="cls-3" transform="translate(210 80)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeaddr }}</text>
                  <text class="cls-3" transform="translate(210 90)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneearea }} {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneepostcode }}</text>
                  <text class="cls-3" transform="translate(210 100)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneestate }}</text>
                  <text class="cls-3" transform="translate(210 110)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecountry }}</text>
                </ng-container>
                <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.longconaddr!=''">
                  <text class="cls-3" transform="translate(210 80)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longconarr[0] }}</text>
                  <text class="cls-3" transform="translate(210 90)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longconarr[1] }}</text>
                  <text class="cls-3" transform="translate(210 100)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longconarr[2] }}</text>
                  <text class="cls-3" transform="translate(210 110)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longconarr[3] }}</text>
                </ng-container>
              </g>
              <g transform="translate(0 195)">
                <rect x="10" y="75" width="530" height="0.6"/>
                <text class="cls-36" transform="translate(15 70)">USCI</text>
                <text class="cls-3" transform="translate(210 70)">0</text>
              </g>
              <g transform="translate(0 210)">
                <rect x="10" y="75" width="530" height="0.6"/>
                <text class="cls-36" transform="translate(15 70)">Contact Name</text>
                <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneecon }}</text>
              </g>
              <g transform="translate(0 225)">
                <rect x="10" y="75" width="530" height="0.6"/>
                <text class="cls-36" transform="translate(15 70)">Tel</text>
                <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneetel }}</text>
              </g>
              <g transform="translate(0 240)">
                <rect x="10" y="75" width="530" height="0.6"/>
                <text class="cls-36" transform="translate(15 70)">Email</text>
                <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.consigneeemail }}</text>
              </g>
              <g transform="translate(0 255)">
                <rect x="10" y="75" width="530" height="0.6"/>
                <rect x="10" y="60" width="530" height="85" class="cls-33"></rect>
                <text class="cls-36" transform="translate(15 70)">2nd Notifying Party</text>
                <text class="cls-3" transform="translate(210 70)">Company Name</text>
              </g>
              <g transform="translate(0 270)">
                <rect x="10" y="115" width="530" height="0.6"/>
                <text class="cls-36" transform="translate(15 70)">2nd Notifying Party Address</text>
                <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyer }}</text>
                <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.longbuyaddr==''">
                  <text class="cls-3" transform="translate(210 80)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyeraddr }}</text>
                  <text class="cls-3" transform="translate(210 90)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerarea }} {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerpostcode }}</text>
                  <text class="cls-3" transform="translate(210 100)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyerstate }}</text>
                  <text class="cls-3" transform="translate(210 110)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyercountry }}</text>
                </ng-container>
                <ng-container *ngIf="this.tS['orders'][this.tS.clickedIndex['orders']]?.longbuyaddr!=''">
                  <text class="cls-3" transform="translate(210 80)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longbuyarr[0] }}</text>
                  <text class="cls-3" transform="translate(210 90)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longbuyarr[1] }}</text>
                  <text class="cls-3" transform="translate(210 100)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longbuyarr[2] }}</text>
                  <text class="cls-3" transform="translate(210 110)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.longbuyarr[3] }}</text>
                </ng-container>
              </g>
              <g transform="translate(0 -10)">
                <g transform="translate(0 335)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">2nd Notifying Party Tel</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.buyertel }}</text>
                </g>
                <g transform="translate(0 350)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Invoice No</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}</text>
                </g>
                <g transform="translate(0 365)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Buyer's Reference</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.ordernumber }}</text>
                </g>
                <g transform="translate(0 380)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Vessel &amp; Voyage No</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vesselname }} {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.vesselno }}</text>
                </g>
                <g transform="translate(0 395)">
                  <rect x="10" y="60" width="530" height="0.6"/>
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Port of Loading</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.expport }}</text>
                </g>
                <g transform="translate(0 410)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Port of Discharge</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.delport }}</text>
                </g>
                <g transform="translate(0 425)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Terms of Delivery</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.paymentterms }}</text>
                </g>
                <g transform="translate(0 440)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Gross Mass</text>
                  <text class="cls-3" transform="translate(210 70)">{{ ( +this.tS['orders'][this.tS.clickedIndex['orders']].quantity > 25000 ? 80 : 60 ) + +this.tS['orders'][this.tS.clickedIndex['orders']].quantity | number:'1.0-0' }} kg</text>
                </g>
                <g transform="translate(0 455)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">Net Mass</text>
                  <text class="cls-3" transform="translate(210 70)">{{ +this.tS['orders'][this.tS.clickedIndex['orders']].quantity | number:'1.0-0' }} kg</text>
                </g>
                <g transform="translate(0 470)">
                  <rect x="10" y="60" width="530" height="15" class="cls-32"></rect>
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(275 70)" text-anchor="middle">CONSIGNEE DETAILS</text>
                </g>
                <g transform="translate(0 485)">
                  <rect x="10" y="85" width="530" height="0.6"/>
                  <text class="cls-3" transform="translate(275 75)" text-anchor="middle">Please ensure consignee name, address, tel and USCI (if given) is indicated on the BOL</text>
                </g>
                <g transform="translate(0 510)">
                  <rect x="10" y="60" width="530" height="15" class="cls-32"></rect>
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(275 70)" text-anchor="middle">BODY OF THE BILL</text>
                </g>
                <g transform="translate(0 525)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-3" transform="translate(275 70)" text-anchor="middle">1 x {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.containertype }} HC Container containing Macadamia Nuts in Shell crop {{ this.tS['orders'][this.tS.clickedIndex['orders']]?.season }}</text>
                </g>
                <g transform="translate(0 540)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <rect x="200" y="60" width="0.6" height="105"/>
                  <text class="cls-36" transform="translate(15 70)">SIZE</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.sizes }}</text>
                </g>
                <g transform="translate(0 555)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">CONTENTS</text>
                  <text class="cls-3" transform="translate(210 70)">{{ +this.tS['orders'][this.tS.clickedIndex['orders']].quantity / +this.tS['orders'][this.tS.clickedIndex['orders']].weight | number:'1.0-0' }} x {{ this.tS['orders'][this.tS.clickedIndex['orders']].weight }} kg Bags</text>
                </g>
                <g transform="translate(0 570)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">GROSS WEIGHT</text>
                  <text class="cls-3" transform="translate(210 70)">{{ ( +this.tS['orders'][this.tS.clickedIndex['orders']].quantity > 25000 ? 80 : 60 ) + +this.tS['orders'][this.tS.clickedIndex['orders']].quantity | number:'1.0-0' }} kg</text>
                </g>
                <g transform="translate(0 585)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">NET WEIGHT</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.quantity | number:'1.0-0' }} kg</text>
                </g>
                <g transform="translate(0 600)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">INVOICE NUMBER</text>
                  <text class="cls-3" transform="translate(210 70)">{{ this.tS['orders'][this.tS.clickedIndex['orders']]?.orderref }}</text>
                </g>
                <g transform="translate(0 615)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">OCEAN FREIGHT PREPAID</text>
                  <text class="cls-3" transform="translate(210 70)"></text>
                </g>
                <g transform="translate(0 630)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(15 70)">HS CODE</text>
                  <text class="cls-3" transform="translate(210 70)">080261</text>
                </g>
                <g transform="translate(0 645)">
                  <rect x="10" y="60" width="530" height="15" class="cls-32"></rect>
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-36" transform="translate(275 70)" text-anchor="middle">PHYTO AND COO</text>
                </g>
                <g transform="translate(0 660)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-3" transform="translate(275 70)" text-anchor="middle">Please indicate the container number &amp; Invoice No on the COO &amp; Phyto.</text>
                </g>
                <g transform="translate(0 676)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-3" transform="translate(275 70)" text-anchor="middle">The COO, Phyto and Bill of Lading must list the gross &amp; net weight, total bags shall be matched (we also allow if Phyto only shows NET weight)</text>
                </g>
                <g transform="translate(0 690)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-3" transform="translate(275 70)" text-anchor="middle">The COO, Phyto and Bill of Lading must list the Shipper (Exporter) name and address etc</text>
                </g>
                <g transform="translate(0 706)">
                  <rect x="10" y="75" width="530" height="0.6"/>
                  <text class="cls-3" transform="translate(275 70)" text-anchor="middle">The COO, Phyto and Bill of Lading must list the Consignee name and address etc. </text>
                </g>
                <g transform="translate(0 720)">
                  <rect x="10" y="85" width="530" height="0.6"/>
                  <text class="cls-3 cls-39" transform="translate(275 70)" text-anchor="middle">NB: If the issuing date of Certificate of origin is later than the date of bill of lading,</text>
                  <text class="cls-3 cls-39" transform="translate(275 80)" text-anchor="middle">it is necessary to add the words "Issued Retroactively" in the place of "Remark:" on the certificate of origin.</text>
                </g>
              </g>
            </g>
          </g>
        </g>

      </svg>
    </div>     

    <br>
    
  </div>
</div>