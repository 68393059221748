import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as moment from 'moment';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { SplitTermsService } from '../../../services/splitterms.service';
import { PortalService } from '../../../auth/portal.service';
import { Price, Cultivar, Model, PriceTerm, DepotTrans } from './pricemodel.interface';


import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class PriceModelService{
	private postBody: {};
	httpRes: any;
	public editmode: boolean = false;
	public loadedEntry: any;
	public default: boolean = false;
	public defaultDuplicate: boolean = false;
	public canChangeDefault: boolean = true;
	public schedulebool: boolean = false;
	public pricemodeledit: number;
	public pricemodelname: string;
	public pricemodeleditname: string;
	public season: number;
	public format: string;
	public method: string;
	public estimated: string = '4';
	public grosspricecurrency: string = 'ZAR';
	public feecurrency: string = 'ZAR';
	public invcurrency: string = 'ZAR';
	public paycurrency: string = 'ZAR';
	public smallscurrency: string = 'ZAR';
	public darkscurrency: string = 'ZAR';
	public selectoutscurrency: string = 'ZAR';
	public drycurr: string = "ZAR";
	public transportcurr: string = "ZAR";
	public transportdepotcurr: string = "ZAR";
	public grossprices: Price[] = [
  	{"skr": null, "uskr": null, "style": null, "rate": null}
	];
	public grossdnis18: Price[] = [
  	{"skr": null, "uskr": null, "style": null, "rate": null}
	];
	public grossdnis20: Price[] = [
  	{"skr": null, "uskr": null, "style": null, "rate": null}
	];
	public grossdnis22: Price[] = [
  	{"skr": null, "uskr": null, "style": null, "rate": null}
	];
	public grossdnis25: Price[] = [
  	{"skr": null, "uskr": null, "style": null, "rate": null}
	];
	public processfees: Price[] = [
  	{"skr": null, "uskr": "0", "style": null, "rate": null}
	];
	public estgross: Price[] = [
  	{"skr": null, "uskr": null, "style": null, "rate": null}
	];
	public mincultivarskr: Cultivar[] = [
  	{"cultivar": "0", "skr": 0.3, "min": 0, "currency": "ZAR"}
	];
	public cultivarbonus: Cultivar[] = [
  	{"cultivar": null, "bonus": 0, "type": "nominal"}
	];
	public transportdepots: DepotTrans[] = [
  	{"depotname": null, "depotfee": null, "depotdryfee": false}
	];
	public depotsdryfee: DepotTrans[] = [];
	public cultbonustype: string = 'nominal';
	public grossbool: boolean = false;
	public grossestbool: boolean = false;
	public processbool: boolean = false;
	public cultivarbool: boolean = false;
	public mincultivarbool: boolean = false;
	public baseoverridebool: boolean = false;
	public smallspricebool: boolean = false;
	public darkspricebool: boolean = false;
	public selectoutpricebool: boolean = false;
	public processlimitsobool: boolean = false;
	public processlimit: string = "0.5";
	public processlimitso: string = "0.35";
	public baseoverride: string = "0";
	public baseoverridehigh: string = "1";
	public baseoverridelow: string = "0";
	public smallsrate: string = "0";
	public smallsuskrlimit: string = "1";
	public smallsmoisture: string = "0.022";
	public smallstotalmoisture: string = this.portalService.calMoisture(+this.smallsmoisture) + '';
	public darksrate: string = "0";
	public selectoutrate: string = "0";
	public selectoutmoisture: string = "0.022";
	public selectouttotalmoisture: string = this.portalService.calMoisture(+this.selectoutmoisture) + '';
	public processnis: string = "0";
	public dryfloor: string = "0.015";
	public dryfloornis: string = "0.0363";
	public dryfloorextra: string = "0.015";
	public drymethod: string = "none";
	public drythreshold: string = "0.1";
	public dryrate: string = "0";
	public dryfeehigh: string = "0";
	public dryfeelow: string = "0";
	public transport: string = "0";
	public transportbase: string = "0";
	public transportkm: string = "0";
	public transportdepotbool: boolean = false;
	public transshipins: string = "0";
	public processing: string = "0";
	public mrl: string = "0";
	public mrlsplit: string = 'upfront';
	public marketing: string = "0";
	public logistics: string = "0";
	public packaging: string = "0";
	public samac: string = "0.56";
	public modelnetsamac: boolean = false;
	public modelnetfee: boolean = false;
	public modelnetunsound: boolean = false;
	public vatable: boolean = true;
	public vatrate: string = "0.15";
	public vatsplit: string = "all";
	public upfrontfees: string= "1";
	public financingbool: boolean = false;
	public financingtype: string= "simple";
	public financingrate: string= "0";
	public fxrate: string;
	public invoicing: boolean = true;
	public invoicetype: string = "multiple";
	public monthlycutoffbool: boolean = false;
	public latebool: boolean = false;
	public smallsbool: boolean = false;
	public selectoutbool: boolean = false;
	public paydatecutoff = this.loginService.season + '-10-01';
	public minpaydate: string = this.loginService.season + '-03-31';
	public newitems: PriceTerm[] = [
  	{"estimate": 0, "type": "", "percent": "", "timing": "", "mdate": "1", "weekday": "0"}
	];
	public mthitems: PriceTerm[] = [
  	{"estimate": 0, "type": "c", "percent": "100", "timing": "0", "mdate": "1", "weekday": "0"}
	];
	public lateitems: PriceTerm[] = [
  	{"estimate": 0, "type": "", "percent": "", "timing": "0", "mdate": "1", "weekday": "0"}
	];
	public smallitems: PriceTerm[] = [
  	{"estimate": 0, "type": "", "percent": "", "timing": "0", "mdate": "1", "weekday": "0"}
	];
	public selectoutitems: PriceTerm[] = [
  	{"estimate": 0, "type": "", "percent": "", "timing": "0", "mdate": "1", "weekday": "0"}
	];
	public note: string;

	constructor(
		private http: HttpClient,
		private loginService: TeamLoginService,
		public splitTermsService: SplitTermsService,
		public portalService: PortalService,
		public dDDS: DropDownDataService) {}

	addItem(itemGroup: string, type: string = null) {
		var fields: {};
		switch(type) {
			case 'cultivar':
				fields = {"cultivar": null, "bonus": 0, "type": this.cultbonustype};
				break;
			case 'mincultivar':
				fields = {"cultivar": null, "skr": 0.3, "min": 0, "currency": "ZAR"};
				break;
			case 'pricing':
				fields = {"skr": null, "uskr": null, "style": null, "rate": null};
				break;
			default:
				fields = {"estimate": 0, "type": "", "percent": "", "timing": "", "weekday": "0"};
				break;
		}
		this[itemGroup.slice(0)] = this.splitTermsService.addItem(this[itemGroup.slice(0)],fields);
	}

	removeItem(itemGroup: string, index: number) {
		this[itemGroup.slice(0)] = this.splitTermsService.removeItem(this[itemGroup.slice(0)], index);
	}

	addPriceModel() {
		this.fixDate();
		this.fixDepotFees();
		var paydates = this.splitTermsService.combineTerms(this.newitems);
		var latedates = this.splitTermsService.combineTerms(this.lateitems);
		var smalldates = this.splitTermsService.combineTerms(this.smallitems);
		var selectoutdates = this.splitTermsService.combineTerms(this.selectoutitems);
		this.postBody = {
			"name": "addPModel",
			"param":{
				"defaultDuplicate" : (this.default ? 1 : 0),
				"pricemodelname": this.pricemodelname,
				"season": this.season,
				"method": this.method,
				"format": this.format,
				"estimated": this.estimated,
				"grosspricecurrency": this.grosspricecurrency,
				"feecurrency": this.feecurrency,
				"invcurrency": this.invcurrency,
				"smallscurrency": this.smallscurrency,
				"darkscurrency": this.darkscurrency,
				"selectoutscurrency": this.selectoutscurrency,
				"drycurr": this.drycurr,
				"transportcurr": this.transportcurr,
				"paycurrency": this.paycurrency,
				"grossprices": this.grossprices,
				"grossdnis18": this.grossdnis18,
				"grossdnis20": this.grossdnis20,
				"grossdnis22": this.grossdnis22,
				"grossdnis25": this.grossdnis25,
				"process": this.processfees,
				"estgross": (this.format=='1' ? this.estgross : []),
				"cultivarbool": (this.cultivarbool ? 1 : 0),
				"cultivarbonus": (this.cultivarbool ? this.cultivarbonus : []),
				"mincultivarbool": (this.mincultivarbool ? 1 : 0),
				"mincultivarskr": (this.mincultivarbool ? this.mincultivarskr : []),
				"baseoverridebool": (this.baseoverridebool ? 1 : 0),
				"baseoverride": (this.baseoverridebool ? this.baseoverride : 0 ),
				"baseoverridehigh": (this.baseoverridebool ? this.baseoverridehigh : 1),
				"baseoverridelow": (this.baseoverridebool ? this.baseoverridelow : 0),
				"smallspricebool": (this.smallspricebool ? 1 : 0),
				"smallsrate": (this.smallspricebool ? this.smallsrate: 0),
				"smallsuskrlimit": (this.smallspricebool ? this.smallsuskrlimit : 1),
				"smalldefaultmoisture": (this.smallspricebool ? this.smallsmoisture : 0.015),
				"darkspricebool": (this.darkspricebool ? 1 : 0),
				"grossdarkkernel": (this.darkspricebool ? this.darksrate: 0),
				"selectoutpricebool": (this.selectoutpricebool ? 1 : 0),
				"selectoutrate": (this.selectoutpricebool ? this.selectoutrate: 0),
				"selectoutmoisture": (this.selectoutpricebool ? this.selectoutmoisture : 0.015),
				"processnis": this.processnis,
				"dryfloor": this.dryfloor,
				"dryfloornis": this.dryfloornis,
				"dryfloorextra": this.dryfloorextra,
				"drymethod": this.drymethod,
				"drythreshold": this.drythreshold,
				"dryrate": this.dryrate,
				"dryfeehigh": this.dryfeehigh,
				"dryfeelow": this.dryfeelow,
				"transport": this.transport,
				"transportbase": this.transportbase,
				"transportkm": this.transportkm,
				"transportdepotcurr": this.transportdepotcurr,
				"transportdepots": this.transportdepots,
				"transportdepot": (this.transportdepotbool ? 1 : 0),
				"depotsdryfee": this.depotsdryfee,
				"processlimit": this.processlimit,
				"processlimits": (this.processlimitsobool ? 1 : 0),
				"processlimitso": this.processlimitso,
				"mrl": this.mrl,
				"mrlsplit": this.mrlsplit,
				"marketing": this.marketing,
				"logistics": this.logistics,
				"packaging": this.packaging,
				"processing": this.processing,
				"transshipins": this.transshipins,
				"samac": this.samac,
				"modelnetsamac": (this.modelnetsamac ? 1 : 0),
				"modelnetfee": (this.modelnetfee ? 1 : 0),
				"modelnetunsound": (this.modelnetunsound ? 1 : 0),
				"fxrate": this.fxrate,
				"vatable": this.vatable,
				"vatrate": this.vatrate,
				"vatsplit": this.vatsplit,
				"upfrontfees": this.upfrontfees,
				"financingbool": (this.financingbool ? 1 : 0),
				"financingtype": this.financingtype,
				"financingrate": this.financingrate,
				"invoicing": (this.invoicing ? 1 : 0),
				"invoicetype": (this.invoicing ? this.invoicetype : 'delivery'),
		    "paydates": paydates,
				"monthlycutoffbool": (this.monthlycutoffbool ? 1 : 0),
				"mthitems": (this.monthlycutoffbool ? this.mthitems : []),
				"latebool": (this.latebool ? 1 : 0),
				"paydatecutoff": (this.latebool ? this.paydatecutoff : ''),
				"minpaydate": this.minpaydate,
				"latedates": (this.latebool ? latedates : []),
				"smallsbool": (this.smallsbool ? 1 : 0),
				"smalldates": (this.smallsbool ? smalldates : []),
				"selectoutbool": (this.selectoutbool ? 1 : 0),
				"selectoutdates": (this.selectoutbool ? selectoutdates : []),
		    "description": this.note
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Save Successful",response['response']['result']['reply']['message']);
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Save Unsuccessful","Price Model was not saved!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	saveChanges(duplicate: boolean = false) {
		this.fixDate();
		this.fixDepotFees();
		var paydates = this.splitTermsService.combineTerms(this.newitems);
		var latedates = this.splitTermsService.combineTerms(this.lateitems);
		var smalldates = this.splitTermsService.combineTerms(this.smallitems);
		var selectoutdates = this.splitTermsService.combineTerms(this.selectoutitems);
		var mthitems = this.splitTermsService.combineTerms(this.mthitems);
		this.postBody = {
			"name": (duplicate ? "addPModel" : "updatePModel"),
			"param":{
				"default": (this.default ? 1 : 0),
				"defaultDuplicate" : (this.defaultDuplicate ? 1 : 0),
				"pricemodelname": this.pricemodelname,
		    "pricemodeledit": this.pricemodeledit,
				"season": this.season,
				"method": this.method,
				"format": this.format,
				"estimated": this.estimated,
				"grosspricecurrency": this.grosspricecurrency,
				"feecurrency": this.feecurrency,
				"invcurrency": this.invcurrency,
				"smallscurrency": this.smallscurrency,
				"darkscurrency": this.darkscurrency,
				"selectoutscurrency": this.selectoutscurrency,
				"drycurr": this.drycurr,
				"transportcurr": this.transportcurr,
				"paycurrency": this.paycurrency,
				"grossprices": this.grossprices,
				"grossdnis18": this.grossdnis18,
				"grossdnis20": this.grossdnis20,
				"grossdnis22": this.grossdnis22,
				"grossdnis25": this.grossdnis25,
				"process": this.processfees,
				"estgross": (this.format=='1' ? this.estgross : []),
				"cultivarbool": (this.cultivarbool ? 1 : 0),
				"cultivarbonus": (this.cultivarbool ? this.cultivarbonus : []),
				"mincultivarbool": (this.mincultivarbool ? 1 : 0),
				"mincultivarskr": (this.mincultivarbool ? this.mincultivarskr : []),
				"baseoverridebool": (this.baseoverridebool ? 1 : 0),
				"baseoverride": (this.baseoverridebool ? this.baseoverride : 0),
				"baseoverridehigh": (this.baseoverridebool ? this.baseoverridehigh : 1),
				"baseoverridelow": (this.baseoverridebool ? this.baseoverridelow : 0 ),
				"smallspricebool": (this.smallspricebool ? 1 : 0),
				"smallsrate": (this.smallspricebool ? this.smallsrate: 0),
				"smallsuskrlimit": (this.smallspricebool ? this.smallsuskrlimit : 1),
				"smalldefaultmoisture": (this.smallspricebool ? this.smallsmoisture : 0.015),
				"darkspricebool": (this.darkspricebool ? 1 : 0),
				"grossdarkkernel": (this.darkspricebool ? this.darksrate: 0),
				"selectoutpricebool": (this.selectoutpricebool ? 1 : 0),
				"selectoutrate": (this.selectoutpricebool ? this.selectoutrate: 0),
				"selectoutmoisture": (this.selectoutpricebool ? this.selectoutmoisture : 0.015),
				"processnis": this.processnis,
				"dryfloor": this.dryfloor,
				"dryfloornis": this.dryfloornis,
				"dryfloorextra": this.dryfloorextra,
				"drymethod": this.drymethod,
				"drythreshold": this.drythreshold,
				"dryrate": this.dryrate,
				"dryfeehigh": this.dryfeehigh,
				"dryfeelow": this.dryfeelow,
				"transport": this.transport,
				"transportbase": this.transportbase,
				"transportkm": this.transportkm,
				"transportdepotcurr": this.transportdepotcurr,
				"transportdepots": this.transportdepots,
				"transportdepot": (this.transportdepotbool ? 1 : 0),
				"depotsdryfee": this.depotsdryfee,
				"processlimit": this.processlimit,
				"processlimits": (this.processlimitsobool ? 1 : 0),
				"processlimitso": this.processlimitso,
				"mrl": this.mrl,
				"mrlsplit": this.mrlsplit,
				"marketing": this.marketing,
				"logistics": this.logistics,
				"packaging": this.packaging,
				"processing": this.processing,
				"transshipins": this.transshipins,
				"samac": this.samac,
				"modelnetsamac": (this.modelnetsamac ? 1 : 0),
				"modelnetfee": (this.modelnetfee ? 1 : 0),
				"modelnetunsound": (this.modelnetunsound ? 1 : 0),
				"fxrate": this.fxrate,
				"vatable": (this.vatable ? 1 : 0),
				"vatrate": this.vatrate,
				"vatsplit": this.vatsplit,
				"upfrontfees": this.upfrontfees,
				"financingbool": (this.financingbool ? 1 : 0),
				"financingtype": this.financingtype,
				"financingrate": this.financingrate,
				"invoicing": (this.invoicing ? 1 : 0),
				"invoicetype": (this.invoicing ? this.invoicetype : 'delivery'),
		    "paydates": paydates,
				"monthlycutoffbool": (this.monthlycutoffbool ? 1 : 0),
				"mthitems": (this.monthlycutoffbool ? mthitems : []),
				"latebool": (this.latebool ? 1 : 0),
				"paydatecutoff": (this.latebool ? this.paydatecutoff : ''),
				"minpaydate": this.minpaydate,
				"latedates": (this.latebool ? latedates : []),
				"smallsbool": (this.smallsbool ? 1 : 0),
				"smalldates": (this.smallsbool ? smalldates : []),
				"selectoutbool": (this.selectoutbool ? 1 : 0),
				"selectoutdates": (this.selectoutbool ? selectoutdates : []),
		    "description": this.note
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Update Successful",response['response']['result']['reply']['message'],'success',5000);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Update Unsuccessful","Price Model was not updated!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

	deleteEntry() { 
		this.postBody = {
			"name": "deletePModel",
			"param":{
				"pricemodelname": this.pricemodelname,
		    "pricemodeledit": this.pricemodeledit,
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	try {
        		this.loginService.setSession(response);
	        	this.loginService.toast("Delete Successful",response['response']['result']['reply']['message']);
	        	this.editmode=false;
	        	this.resetForm();
	        } catch(e) {
	        	this.loginService.toast("Delete Unsuccessful","Price Model was not deleted!",'warn');
	        }
        },
        (error) => console.log(error)
      );
	}

  loadForEdit() {
  	for (var i = 0; i < this.dDDS.dropdown.priceModelListAll.length; i++) {
  		if (this.dDDS.dropdown.priceModelListAll[i]['modelno']==this.pricemodeledit) {
  			this.pricemodeleditname = this.dDDS.dropdown.priceModelListAll[i]['model'];
  		}
  	}
		this.postBody = {
			"name": "loadPriceModelFull",
			"param": {
				"pricemodel": this.pricemodeledit
			}
		};
		return this.http.post(
			this.loginService.httpRoot,
			this.postBody,
			{
				headers: new HttpHeaders({
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				})
			}
			)
			.subscribe(
        (response: any) => {
        	this.loginService.setSession(response);
          this.httpRes = this.loadEntry(response);
        },
        (error) => console.log(error)
      );
	}

	loadEntry(rawData) {
		this.loadedEntry = rawData.response.result.reply;
		this.default = (this.loadedEntry.default==1);
		this.canChangeDefault = (this.loadedEntry.default==0);
		this.pricemodelname = this.loadedEntry['model'];
		this.season = (this.loadedEntry.season==undefined ? this.pricemodelname.substring(0,4) : this.loadedEntry.season.substring(0,4) );
		this.method = +this.loadedEntry.method['value1'] + '';
		this.format = +this.loadedEntry.method['value2'] + '';
		this.estimated = +this.loadedEntry.method['value3'] + '';
		this.grosspricecurrency = (this.loadedEntry.grosspricecurrency==undefined ? 'ZAR' : this.loadedEntry.grosspricecurrency['value3']);
		this.feecurrency = (this.loadedEntry.feecurrency==undefined ? 'ZAR' : this.loadedEntry.feecurrency['value3']);
		this.invcurrency = (this.loadedEntry.invcurrency==undefined ? 'ZAR' : this.loadedEntry.invcurrency['value3']);
		this.paycurrency = (this.loadedEntry.paycurrency==undefined ? 'ZAR' : this.loadedEntry.paycurrency['value3']);
		this.fxrate = (this.loadedEntry.fxrate==undefined ? "14" : this.loadedEntry.fxrate['value1']);
		this.processnis = (this.loadedEntry.processnis==undefined ? "0" : this.loadedEntry.processnis['value1']);
		this.dryfeehigh = (this.loadedEntry.dryfeehigh==undefined ? "0" : this.loadedEntry.dryfeehigh['value1']);
		this.dryfeelow = (this.loadedEntry.dryfeelow==undefined ? "0" : this.loadedEntry.dryfeelow['value1']);
		this.dryfloor = (this.loadedEntry.dryfloor==undefined ? "0.015" : this.loadedEntry.dryfloor['value1']);
		this.dryfloornis = this.portalService.calMoisture(+this.dryfloor, 0) + '';
		this.dryfloorextra = (this.loadedEntry.dryfloor==undefined ? "0.015" : (+this.loadedEntry.dryfloor['value3']>0 ? this.loadedEntry.dryfloor['value3'] : '0.015' ) );
		this.drymethod = (this.loadedEntry.drymethod==undefined ? "aboveonly" : this.loadedEntry.drymethod['value3']);
		this.dryrate = (this.loadedEntry.dryrate==undefined ? "0" : this.loadedEntry.dryrate['value1']);
		this.drythreshold = (this.loadedEntry.drythreshold==undefined ? "0.01" : this.loadedEntry.drythreshold['value1']);
		this.drycurr = (this.loadedEntry.drythreshold==undefined ? "ZAR" : this.loadedEntry.drythreshold['value3']);
		this.mrl = (this.loadedEntry.mrl==undefined ? "0" : this.loadedEntry.mrl['value1']);
		this.mrlsplit = (this.loadedEntry.mrl==undefined ? "upfront" : this.loadedEntry.mrl['value3']);
		this.marketing = (this.loadedEntry.marketing==undefined ? "0" : this.loadedEntry.marketing['value1']);
		this.logistics = (this.loadedEntry.logistics==undefined ? "0" : this.loadedEntry.logistics['value1']);
		this.packaging = (this.loadedEntry.packaging==undefined ? "0" : this.loadedEntry.packaging['value1']);
		this.transport = (this.loadedEntry.transport==undefined ? "0" : this.loadedEntry.transport['value1']);
		this.transportbase = (this.loadedEntry.transportkm==undefined ? "0" : this.loadedEntry.transportkm['value1']);
		this.transportkm = (this.loadedEntry.transportkm==undefined ? "0" : this.loadedEntry.transportkm['value2']);
		this.transportcurr = (this.loadedEntry.transport==undefined ? (this.loadedEntry.transportkm==undefined ? "ZAR" : this.loadedEntry.transportkm['value3']) : this.loadedEntry.transport['value3']);
		this.processlimit = (this.loadedEntry.processlimit==undefined ? "0.5" : this.loadedEntry.processlimit['value1']);
		this.processlimitso = (this.loadedEntry.processlimitso==undefined ? "0.35" : this.loadedEntry.processlimitso['value1']);
		this.processlimitsobool = (this.loadedEntry.processlimitso!==undefined);
		this.transportdepotcurr = (this.loadedEntry.transportdepotcurr==undefined ? "ZAR" : this.loadedEntry.transportdepotcurr);
		this.transportdepotbool = this.loadedEntry.transportdepots!==undefined;
		this.transportdepots = (this.loadedEntry.transportdepots!==undefined ? this.loadedEntry.transportdepots : 
  	[{"depotname": null, "depotfee": null, "depotdryfee": false}] );
		if (this.transportdepots.length>0) {
			for (var j=0; j < this.transportdepots.length; j++) {
				this.transportdepots[j]['depotname'] = this.transportdepots[j]['depotname'] + '';
			}
		}
		this.depotsdryfee = (this.loadedEntry.depotsdryfee!==undefined ? this.loadedEntry.depotsdryfee : [] );
		if (this.depotsdryfee.length>0) {
			for (var j=0; j < this.depotsdryfee.length; j++) {
				for (var k=0; k < this.transportdepots.length; k++) {
					if (this.transportdepots[k]['depotname']==this.depotsdryfee[j]['depotname']) {
						this.transportdepots[k]['depotdryfee'] = this.depotsdryfee[j]['depotdryfee'];
					}
				}
			}
		}
		this.samac = (this.loadedEntry.samac==undefined ? "0" : this.loadedEntry.samac['value1']);
		this.modelnetsamac = (this.loadedEntry.modelnetsamac==undefined ? false : (+this.loadedEntry.modelnetsamac['value1']==0 ? false : true ));
		this.modelnetfee = (this.loadedEntry.modelnetfee==undefined ? false : (+this.loadedEntry.modelnetfee['value1']==0 ? false : true ));
		this.modelnetunsound = (this.loadedEntry.modelnetunsound==undefined ? false : (+this.loadedEntry.modelnetunsound['value1']==0 ? false : true ));
		this.vatrate = (this.loadedEntry.vatrate==undefined ? "0.15" : this.loadedEntry.vatrate['value1']);
		this.vatable = (this.loadedEntry.vatable==undefined ? true : (+this.loadedEntry.vatable['value1']==0 ? false : true ));
		this.vatsplit = (this.loadedEntry.vatsplit==undefined ? "all" : this.loadedEntry.vatsplit['value3']);
		this.upfrontfees = (this.loadedEntry.upfrontfees==undefined ? "1" : Math.round(this.loadedEntry.upfrontfees['value1']) + '');
		this.financingbool = (this.loadedEntry.financing!==undefined ? this.loadedEntry.financing['value1']>0 : false);
		this.financingtype = (this.loadedEntry.financing==undefined ? "simple" : this.loadedEntry.financing['value3']);
		this.financingrate = (this.loadedEntry.financing==undefined ? "0" : this.loadedEntry.financing['value1']);
		this.invoicing = (this.loadedEntry.invoicing==undefined ? false : (+this.loadedEntry.invoicing['value1']==0 ? false : true ));
		this.invoicetype = (this.loadedEntry.invoicing==undefined ? "multiple" : this.loadedEntry.invoicing['value3']);
		this.grossprices = (this.loadedEntry.grossprices==undefined ? [{"skr": null, "uskr": null, "style": null, "rate": null}] : this.loadedEntry.grossprices);
		this.grossdnis18 = (this.loadedEntry.grossdnis18==undefined ? [{"skr": null, "uskr": null, "style": null, "rate": null}] : this.loadedEntry.grossdnis18);
		this.grossdnis20 = (this.loadedEntry.grossdnis20==undefined ? [{"skr": null, "uskr": null, "style": null, "rate": null}] : this.loadedEntry.grossdnis20);
		this.grossdnis22 = (this.loadedEntry.grossdnis22==undefined ? [{"skr": null, "uskr": null, "style": null, "rate": null}] : this.loadedEntry.grossdnis22);
		this.grossdnis25 = (this.loadedEntry.grossdnis25==undefined ? [{"skr": null, "uskr": null, "style": null, "rate": null}] : this.loadedEntry.grossdnis25);
		this.estgross = (this.format == '1' && this.loadedEntry.estgross !== undefined ? this.loadedEntry.estgross : [{"skr": null, "uskr": null, "style": null, "rate": null}] );
		this.processfees = this.loadedEntry.process;
		this.processing = (this.loadedEntry.processing==undefined ? "0" : this.loadedEntry.processing['value1']);
		this.transshipins = (this.loadedEntry.transshipins==undefined ? "0" : this.loadedEntry.transshipins['value1']);
		this.cultivarbool = (this.loadedEntry.cultivarbonus!==undefined);
		this.cultivarbonus = (this.loadedEntry.cultivarbonus!==undefined ? this.loadedEntry.cultivarbonus : 
  	[{"cultivar": null, "bonus": 0}] );
		this.mincultivarbool = (this.loadedEntry.mincultivarskr!==undefined);
		this.mincultivarskr = (this.loadedEntry.mincultivarskr!==undefined ? this.loadedEntry.mincultivarskr : 
  	[{"cultivar": "0", "skr": 0.3, "min": 0, "currency": "ZAR"}] );
  	this.cultbonustype = (this.loadedEntry.cultivarbonus!==undefined ? this.loadedEntry.cultivarbonus[0]['type'] : "nominal" );
		this.baseoverridebool = (this.loadedEntry.baseoverride!==undefined);
		this.baseoverride = (this.loadedEntry.baseoverride==undefined ? "0" : this.loadedEntry.baseoverride['value1']);
		this.baseoverridelow = (this.loadedEntry.baseoverride==undefined ? "0" : this.loadedEntry.baseoverride['value2']);
		this.baseoverridehigh = (this.loadedEntry.baseoverride==undefined ? "1" : this.loadedEntry.baseoverride['value3']);
		this.smallspricebool = (this.loadedEntry.smallsrate!==undefined ? this.loadedEntry.smallsrate['value1']>0 : false);
		this.smallsrate = (this.loadedEntry.smallsrate==undefined ? "0" : this.loadedEntry.smallsrate['value1']);
		this.smallscurrency = (this.loadedEntry.smallsrate==undefined ? "ZAR" : this.loadedEntry.smallsrate['value3']);
		this.smallsuskrlimit = (this.loadedEntry.smallsuskrlimit==undefined ? "1" : this.loadedEntry.smallsuskrlimit['value1']);
		this.smallsmoisture = (this.loadedEntry.smalldefaultmoisture==undefined ? "0.015" : this.loadedEntry.smalldefaultmoisture['value1']);
		this.smallstotalmoisture = this.portalService.calMoisture(+this.smallsmoisture, 0) + '';
		this.darkspricebool = (this.loadedEntry.grossdarkkernel!==undefined ? this.loadedEntry.grossdarkkernel['value1']>0 : false);
		this.darksrate = (this.loadedEntry.grossdarkkernel==undefined ? "0" : this.loadedEntry.grossdarkkernel['value1']);
		this.darkscurrency = (this.loadedEntry.grossdarkkernel==undefined ? "ZAR" : this.loadedEntry.grossdarkkernel['value3']);
		this.selectoutpricebool = (this.loadedEntry.selectoutrate!==undefined);
		this.selectoutrate = (this.loadedEntry.selectoutrate==undefined ? "0" : this.loadedEntry.selectoutrate['value1']);
		this.selectoutscurrency = (this.loadedEntry.selectoutrate==undefined ? "ZAR" : this.loadedEntry.selectoutrate['value3']);
		this.selectoutmoisture = (this.loadedEntry.selectoutmoisture==undefined ? "0.015" : this.loadedEntry.selectoutmoisture['value1']);
		this.selectouttotalmoisture = this.portalService.calMoisture(+this.selectoutmoisture, 0) + '';
		var estitems = (this.loadedEntry.epaypercent==undefined ? [] : this.splitTermsService.splitTerms(this.loadedEntry.epaypercent['value3'], this.loadedEntry.epayday['value3'], true));
		var items = this.splitTermsService.splitTerms(this.loadedEntry.paypercent['value3'], this.loadedEntry.payday['value3']);
		this.newitems = [];
		for (var i in estitems) {
			this.newitems.push(estitems[i]);
		}
		for (var i in items) {
			this.newitems.push(items[i]);
		}
		this.smallsbool = (this.loadedEntry.smallpayday!==undefined);
		this.smallitems = (this.loadedEntry.smallpayday!==undefined ? this.splitTermsService.splitTerms(this.loadedEntry.smallpaypercent['value3'], this.loadedEntry.smallpayday['value3']) : [{"estimate": 0, "type": "m", "percent": "", "timing": "0"}]);
		this.selectoutbool = (this.loadedEntry.selectoutpayday!==undefined);
		this.selectoutitems = (this.loadedEntry.selectoutpayday!==undefined ? this.splitTermsService.splitTerms(this.loadedEntry.selectoutpaypercent['value3'], this.loadedEntry.selectoutpayday['value3']) : [{"estimate": 0, "type": "m", "percent": "", "timing": "0"}]);
		this.latebool = (this.loadedEntry.latepayday!==undefined);
		this.lateitems = (this.loadedEntry.latepayday!==undefined ? this.splitTermsService.splitTerms(this.loadedEntry.latepaypercent['value3'], this.loadedEntry.latepayday['value3']) : [{"estimate": 0, "type": "m", "percent": "", "timing": "0"}]);
		this.paydatecutoff = (this.loadedEntry.latepayday!==undefined ? this.loadedEntry.paydatecutoff['value3'] : this.loginService.season + '-10-01');
		this.minpaydate = (this.loadedEntry.minpaydate!==undefined ? this.loadedEntry.minpaydate['value3'] : this.loginService.season + '-03-31');
		this.monthlycutoffbool = (this.loadedEntry.monthlycutoff!==undefined);
		this.mthitems = (this.loadedEntry.monthlycutoff!==undefined ? this.splitTermsService.splitTerms('1', this.loadedEntry.monthlycutoff['value3']) : [{"estimate": 0, "type": "m", "percent": "100", "timing": "0"}]);
		this.note = (this.loadedEntry.description==undefined ? "" : this.loadedEntry.description['value3']);
	}

  checkTerms() {
  	var list = ['newitems'];
  	if (this.latebool) {
  		list.push('lateitems');
  	}
  	if (this.smallsbool) {
  		list.push('smallitems');
  	}
  	if (this.selectoutbool) {
  		list.push('selectoutitems');
  	}
  	if (this.monthlycutoffbool) {
  		list.push('mthitems');
  	}
  	for (var i = 0; i < list.length; i++) {
	  	if (this.splitTermsService.checkTerms(this[list[i].slice(0)], false) == false) {      	
	  		return false;
	  	}
	  }
		return true;
	}

	showError() {
    if (this.splitTermsService.message!=null) {
			this.loginService.toast('Payment Terms',this.splitTermsService.message,'error');
		}
	}

	changeMethod() {
		this.estimated = undefined;
		this.cultivarbool = false;
		this.baseoverridebool = false;
		this.grossprices = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis18 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis20 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis22 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis25 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.estgross = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.cultivarbonus = [
	  	{"cultivar": null, "bonus": 0, "type": "nominal"}
		];
	}

	updateCultBType() {
		for (var i = 0; i < this.cultivarbonus.length; i++) {
			this.cultivarbonus[i]['type'] = this.cultbonustype;
			this.cultivarbonus[i]['bonus'] = 0;
		}
	}

	setVATprorata() {
		if (this.invoicetype=='month' || this.invoicetype=='week') {
			this.vatsplit = 'all';
		}
	}

	setInvoicingMonthly() {
		if (this.vatsplit=='firstonly') {
			this.invoicetype = 'multiple';
		}
	}
	
	convCurr($event,itemGroup,itemindex = null,key = null,percent = false,posonly = true) {
  
  }

  resetForm(keepRef: boolean = false) {
    window.scroll(0,0);
    this.editmode = false;
    this.default = false;
    this.defaultDuplicate = false;
    this.schedulebool = false;
    this.canChangeDefault = true;
    this.dDDS.getDropdown("priceModelListAll");
    this.pricemodelname = undefined;
    this.pricemodeleditname = undefined;
    this.pricemodeledit = (keepRef ? this.pricemodeledit : undefined);
		this.season = this.loginService.season;
		this.method = undefined;
		this.format = undefined;
		this.estimated = undefined;
		this.grosspricecurrency = 'ZAR';
		this.feecurrency = 'ZAR';
		this.invcurrency = 'ZAR';
		this.paycurrency = 'ZAR';
		this.smallscurrency = 'ZAR';
		this.darkscurrency = 'ZAR';
		this.selectoutscurrency = 'ZAR';
		this.drycurr = 'ZAR';
		this.grossprices = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis18 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis20 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis22 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.grossdnis25 = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.processfees = [
	  	{"skr": null, "uskr": "0", "style": null, "rate": null}
		];
		this.estgross = [
	  	{"skr": null, "uskr": null, "style": null, "rate": null}
		];
		this.mincultivarskr = [
	  	{"cultivar": "0", "skr": 0.3, "min": 0, "currency": "ZAR"}
		];
		this.cultivarbonus = [
	  	{"cultivar": null, "bonus": 0, "type": "nominal"}
		];
		this.transportdepots = [
	  	{"depotname": null, "depotfee": null, "depotdryfee": false}
		];
		this.depotsdryfee = [];
		this.cultbonustype = "nominal";
		this.grossbool = false;
		this.grossestbool = false;
		this.processbool = false;
		this.cultivarbool = false;
		this.mincultivarbool = false;
		this.baseoverridebool = false;
		this.baseoverride = "0";
		this.baseoverridelow = "0";
		this.baseoverridehigh = "1";
		this.smallspricebool = false;
		this.smallsrate= "0";
		this.smallsuskrlimit = "1";
		this.smallsmoisture = "0.022";
		this.smallstotalmoisture = this.portalService.calMoisture(+this.smallsmoisture, 0) + '';
		this.darkspricebool = false;
		this.darksrate= "0";
		this.selectoutpricebool = false;
		this.selectoutrate= "0";
		this.selectoutmoisture = "0.022";
		this.selectouttotalmoisture = this.portalService.calMoisture(+this.selectoutmoisture, 0) + '';
		this.processnis = "0";
		this.dryfloor = "0.015";
		this.dryfloornis = "0.0363";
		this.dryfloorextra = "0.015";
		this.drymethod = "none";
		this.drythreshold = "0.1";
		this.dryrate = "0";
		this.dryfeehigh = "0";
		this.dryfeelow = "0";
		this.transport = "0";
		this.transportbase = "0";
		this.transportkm = "0";
		this.transportdepotcurr = 'ZAR';
		this.transportdepotbool = false;
		this.processlimit = '0.5';
		this.processlimitsobool = false;
		this.processlimitso = '0.35';
		this.transshipins = "0";
		this.processing = "0";
		this.mrl = "0";
		this.mrlsplit = 'upfront';
		this.marketing = "0";
		this.logistics = "0";
		this.packaging = "0";
		this.samac = "0.56";
		this.modelnetsamac = false;
		this.modelnetfee = false;
		this.modelnetunsound = false;
		this.fxrate = null;
		this.vatable = true;
		this.vatrate = "0.15";
		this.vatsplit = "all";
		this.upfrontfees = "1";
		this.financingbool = false;
		this.financingtype = "simple";
		this.financingrate = "0";
		this.invoicing = true,
		this.invoicetype = 'multiple',
		this.monthlycutoffbool = false;
		this.latebool = false;
		this.smallsbool = false;
		this.selectoutbool = false;
		this.paydatecutoff = this.loginService.season + '-10-01';
		this.minpaydate = this.loginService.season + '-03-31';
    this.note = null;
    this.newitems = [
	  	{"estimate": 0, "type": "", "percent": "", "timing": "", "weekday": "0"}
		];
		this.mthitems = [
	  	{"estimate": 0, "type": "c", "percent": "100", "timing": "0", "weekday": "0"}
		];
		this.lateitems = this.mthitems;
		this.smallitems = this.mthitems;
		this.selectoutitems = this.mthitems;
  }

	fixDate() {
	  this.minpaydate = moment(this.minpaydate).format('YYYY-MM-DD');
	  this.paydatecutoff = moment(this.paydatecutoff).format('YYYY-MM-DD');
	}

	fixDepotFees() {
		this.depotsdryfee = [];
		for (var i = 0; i < this.transportdepots.length; i++) {
			this.depotsdryfee[i] = {"depotname": this.transportdepots[i]['depotname'], "depotdryfee": this.transportdepots[i]['depotdryfee'] };
		}
	}


}