import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { RemittanceService } from '../remittance/remittance.service';
import { PortalService } from '../../../auth/portal.service';
import { CsvService } from '../../../services/csv.service';

import { Observable, Subscriber } from 'rxjs';
import { tap, map, filter } from 'rxjs/operators';

@Injectable()
export class TableDataService{
	private postBody: {};
	httpRes: any;
	public allData: any = [];
	public filteredData: any;
	public deliveries: any;
	public samples: any;
	public loading: boolean = true;
	public stopLoading: boolean = false;
	public stillLoading: boolean = true;
	public delivery: any;
  public clickedId: any = [];
	public clickedIndex: any[] = [];
	public clickedMoisture: number;
	public clickedSKR: number;
	public clickedUSKR: number;
	public clickedUnsound: number;
	public totalDeliveries: number;
	public paypercent: [];
	public payday: any [];
	public paydayRaw: any [];
	public epaypercent: [];
	public epayday: any [];
	public epaydayRaw: any [];
	public currency: string = 'ZAR';
	public bpanel: boolean = false;
	public selected: any;
	public clickedType: string;
  public indexLink = false;
  public addCur: boolean;
  public checkBoxesActive: boolean = false;
  public checkBoxes: boolean = false;
  public checkboxdata = [];
  public selectedRows = [];
  public batchpay = [];
  public tablename: string;
  public sampleUnA: any;
  public order: string = '';
  public orderdetails: [] = [];
  public quality: any;
  public ppecb: any;

	constructor(
		private http: HttpClient,
		private lS: TeamLoginService,
		private pS: PortalService,
		private dDDS: DropDownDataService,
		private rS: RemittanceService,
		public csvService: CsvService,
		)	{}

	async loadData(data: string, season = this.lS.season, part = 1) {
		this.loading = (part==1);
		this.stillLoading = true;
		var p = {
			"season": (season>0 ? season : this.lS.season),
			"part": part
		}
		const response = await this.lS.loadData(data, p);
		const httpRes = await this.storeResult(response, data);
		return true;
	}

	async storeResult(rawData, data: string) {
		if ((rawData['part']==undefined || rawData['part']==1) && rawData[data]!=null) {
			this.allData[data] = rawData;
			this.dDDS.columns[data] = this.allData[data]['columns'];
			this.checkBoxesActive = (this.allData[data]['checkboxes']==1);
			if (this.clickedIndex[data]==undefined) {
				this.clickedIndex[data] = null;
			}
			this.loading = false;
			this.indexLink = false;
		} else {
			this.allData[data][data].push(...rawData[data]);
			if (rawData[data].length>0) {
				this.sortDropdowns(data,rawData);
			}
		}
		if (rawData['part']!==undefined && !this.stopLoading) {
			if (rawData[data].length>0) {
				this.loadData(data, this.lS.season, +rawData['part'] + 1);
			} else {
				this.stillLoading = false;
				this.dDDS.columns[data] = this.allData[data]['columns'];
			}
		}
		if (rawData['part']==undefined) {
			this.stillLoading = false;
		}
		this[data] = await [...this.allData[data][data]];
		if (!this.stillLoading) {
			this.refreshSelectedRows(data, rawData['key']);
		}
	}

	sortDropdowns(data,rawData) {
		for (var i=0; i<this.allData[data]['columns'].length; i++) {
			if (this.allData[data]['columns'][i]['type']=='dropdown'){
				var dropdown = this.allData[data]['columns'][i]['field'];
				if (this.allData[data][dropdown]!==undefined) {
					var curDrop = [...this.allData[data][dropdown],...rawData[dropdown]];
					curDrop = this.removeDuplicates(curDrop,dropdown);
					curDrop.sort(this.compareValues(dropdown));
					this.allData[data][this.allData[data]['columns'][i]['field']] = curDrop;
				}
			}
		}
	}

	removeDuplicates(arr,k) {
		const uniqueK = new Set();
		return arr.filter(element => {
			const isDup = uniqueK.has(element[k]);
			uniqueK.add(element[k]);
			if (!isDup) {
				return true;
			}
			return false;
		});
	}

	compareValues(key, order = 'asc') {
	  return function innerSort(a, b) {
	    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
	      // property doesn't exist on either object
	      return 0;
	    }
	    const varA = (typeof a[key] === 'string')
	      ? a[key].toUpperCase() : a[key];
	    const varB = (typeof b[key] === 'string')
	      ? b[key].toUpperCase() : b[key];
	    let comparison = 0;
	    if (varA > varB) {
	      comparison = 1;
	    } else if (varA < varB) {
	      comparison = -1;
	    }
	    return (
	      (order === 'desc') ? (comparison * -1) : comparison
	    );
	  };
	}

	async loadStorages() {
		var api = 'deliveryStorages';
		var p = {
			"season": this.lS.season,
			"iddelivery": this.delivery.iddelivery
		}
		const response = await this.lS.loadData(api, p);
		return response;
	}

	async setDelivery(idd: any, data: string, season = null) {
		if (this[data]==undefined) {
			const ans = await this.loadData(data, season);
		}
		if (season>0 && this.allData[data]['season']!=season) {
			const ans = await this.loadData(data, season);
		}
		switch (data) {
			case "deliveries":
			case "samples":
				await this.cacheDelivery(idd);
				this.setD(idd, data);
				break;
			case "samplesunassigned":
				this.setId(idd, data);
				this.sampleUnA = this[data][this.clickedIndex[data]];
				break;
			case "remittances":
				this.setId(idd, data);
				this.getRemittance(idd);
    		this.rS.iddelivery = idd + '';
		    if (this.rS.iddelivery!==undefined) {
		      this.rS.loadRemittance();
		    }
				break;
			case "companies":
				this.setC(idd);
				break;
			case "":
				break;
			case "assignedinventory":
				this.setId(idd, data);
				var p = {
					"name": "orderRefList",
					"param": {
						"location": 1,
						"season": +this.lS.season,
						"date": '',
						"style": this[data][this.clickedIndex[data]]['style']
					}
				};
    		this.dDDS.getDropdown("orderRefList",1,'',p);
				break;
			case "orders":
				this.setId(idd, data);
				this.totalProducts();
				break;
			default:
				this.setId(idd, data);
				break;
		}
		this.indexLink = false;
	}

	async getData(idd: number) {
		this.postBody = {
			"name": 'calAllDelivery',
			"param": {
				"delivery": idd,
				"model": this['remittances'][this.clickedIndex['remittances']]['pricemodel'],
				"season": +this.lS.season,
				"currency": this.currency
			}
		};
		let response = await fetch(
			this.lS.httpRoot,
			{
				method: "post",
				body: JSON.stringify(this.postBody),
				headers: {
					'Content-Type': 'application/json',
					'Authorization': 'Bearer ' + localStorage.getItem('team_id_token')
				}
			}
		)
		return response.json();
	}

	getRemittance(idd: number) {
		this.getData(idd)
		  .then(response => {
		  	this.lS.setSession(response);
		  	this.selected = response['response']['result']['reply'];
		  })
		  .catch(error => console.log(error));
	}

	async setD(idd, data) {
		for (var i = 0; i < this[data].length; i++) {
			if (this[data][i][this.allData[data]['key']] == idd) {
				this.clickedIndex[data] = i;
			}
		}
		if (this.delivery['portion']!==this.delivery['scalewnis']) {
			this.loadStorages()
				.then(response => {
					this.delivery['storages'] = response;
			  })
	  		.catch(error => console.log(error));
		}
		this.paypercent = [];
		if (this.delivery['dcustompaypercent'] != null) {
			if (this.delivery['dcustompaypercent'].charAt(this.delivery['dcustompaypercent'].length - 1) == ";") {
				this.delivery['dcustompaypercent'] = this.delivery['dcustompaypercent'].slice(0,this.delivery['dcustompaypercent'].length - 1);
			}
			if (this.delivery['dcustompayday'].charAt(this.delivery['dcustompayday'].length - 1) == ";") {
				this.delivery['dcustompayday'] = this.delivery['dcustompayday'].slice(0,this.delivery['dcustompayday'].length - 1);
			}
			if (this.delivery['ecustompaypercent']!==null) {
				if (this.delivery['ecustompaypercent'].charAt(this.delivery['ecustompaypercent'].length - 1) == ";") {
					this.delivery['ecustompaypercent'] = this.delivery['ecustompaypercent'].slice(0,this.delivery['ecustompaypercent'].length - 1);
				}
				if (this.delivery['ecustompayday'].charAt(this.delivery['ecustompayday'].length - 1) == ";") {
					this.delivery['ecustompayday'] = this.delivery['ecustompayday'].slice(0,this.delivery['ecustompayday'].length - 1);
				}
			}
			this.paypercent = this.delivery['dcustompaypercent'].split(";");
			this.payday = this.delivery['dcustompayday'].split(";");
			this.epaypercent = (this.delivery['ecustompaypercent']==null ? null : this.delivery['ecustompaypercent'].split(";"));
			this.epayday = (this.delivery['ecustompayday']==null ? null : this.delivery['ecustompayday'].split(";"));
			this.paydayRaw = this.delivery['dcustompayday'].split(";");
			this.epaydayRaw = (this.epayday==null ? null : this.delivery['ecustompayday'].split(";"));
			this.payday = this.dDDS.transDay(this.paydayRaw);
			this.epayday = (this.epaydayRaw==null ? null : this.dDDS.transDay(this.epaydayRaw));
		}
	}

	setC(idd) {
		var data = 'companies';
		for (var i = 0; i < this[data].length ; i++) {
			if (+this[data][i].idcompany == idd) {
				this.clickedIndex['companies'] = i;
				this.clickedType = this[data][i]['companytype'];
				if (this[data][i]['companytype']=="producer") {
					if (this[data][i]['paypercent'].charAt(this[data][i]['paypercent'].length - 1) == ";") {
						this[data][i]['paypercent'] = this[data][i]['paypercent'].slice(0,this[data][i]['paypercent'].length - 1);
					}
					if (this[data][i]['payday'].charAt(this[data][i]['payday'].length - 1) == ";") {
						this[data][i]['payday'] = this[data][i]['payday'].slice(0,this[data][i]['payday'].length - 1);
					}
					if (this[data][i]['epaypercent']!==null) {
						if (this[data][i]['epaypercent'].charAt(this[data][i]['epaypercent'].length - 1) == ";") {
							this[data][i]['epaypercent'] = this[data][i]['epaypercent'].slice(0,this[data][i]['epaypercent'].length - 1);
						}
						if (this[data][i]['epayday'].charAt(this[data][i]['epayday'].length - 1) == ";") {
							this[data][i]['epayday'] = this[data][i]['epayday'].slice(0,this[data][i]['epayday'].length - 1);
						}
					}
					this.paypercent = this[data][i]['paypercent'].split(";");
					this.payday = this[data][i]['payday'].split(";");
					this.epaypercent = (this[data][i]['epaypercent']==null ? null : this[data][i]['epaypercent'].split(";"));
					this.epayday = (this[data][i]['epayday']==null ? null : this[data][i]['epayday'].split(";"));
					this.paydayRaw = this[data][i]['payday'].split(";");
					this.epaydayRaw = (this.epayday==null ? null : this[data][i]['epayday'].split(";"));
					this.payday = this.dDDS.transDay(this.paydayRaw);
					this.epayday = (this.epaydayRaw==null ? null : this.dDDS.transDay(this.epaydayRaw));
				}
			}
		}
	}

	setId(idd, data) {
		for (var i = 0; i < this[data].length; i++) {
			if (this[data][i][this.allData[data]['key']] == idd) {
				this.clickedIndex[data] = i;
			}
		}
	}

  async cacheDelivery(idd) {
    var p = {
      "iddelivery": idd,
      "season": this.lS.season
    };
    var del = await this.lS.loadData('deliverydetail',p);
    this.delivery = del[0];
  }

	reset(data: string) {
    delete this.clickedIndex[data];
    this.checkboxdata = null;
    this.selectedRows = null;
    this.filteredData = null;
	}

	loadReady(data) {
		if (this.allData[data.toLowerCase()]==undefined) {
			return false;
		}
		if (this.allData[data.toLowerCase()]['key']==undefined) {
			return false;
		}
		if (this[data.toLowerCase()][this.clickedIndex[data.toLowerCase()]]==undefined) {
			return false;
		}
		if (this[data.toLowerCase()][this.clickedIndex[data.toLowerCase()]][this.allData[data.toLowerCase()]['key']]==undefined) {
			return false;
		}
		return true;
	}

	clearData() {
		this.allData = [];
		this.checkBoxesActive = false;
		this.selectedRows = [];
		this.clickedIndex = [];
		this.filteredData = null;
		this.tablename = null;
		this['companies'] = null;
		this['contacts'] = null;
		this['deliveries'] = null;
		this['depotdeliveries'] = null;
		this['samples'] = null;
		this['remittances'] = null;
		this['payments'] = null;
		this['orders'] = null;
		this['bookings'] = null;
		this['batches'] = null;
		this['pricemodel'] = null;
		this['delivery'] = null;
		this['sampleUnA'] = null;
	}

	saveCSV(data) {
		var exportData = this.organiseExport(data, 'csv');
		this.csvService.rawCSV(exportData['title'], exportData['headers'], exportData['data']);
	}

	saveXLSX(data) {
		var exportData = this.organiseExport(data, 'xlsx');
		this.csvService.exportExcel(exportData['title'],exportData['data']);
	}

	organiseExport(data, type = 'csv') {
		var headers = [];
		var exportData = [];
		var footer = [];
		var count = [];
		var sum = [];
		var tabledata = 'filteredData';
		this.checkboxdata = (this.checkboxdata==null ? [] : this.checkboxdata);
		if (this.checkboxdata.length>0 && this.checkBoxesActive && this.checkBoxes) {
			tabledata = 'checkboxdata';
		}
		this.addCur = false;
		for (var i = 0; i < this[tabledata].length; i++) {
			if (exportData[i]==undefined) {
				exportData[i] = [];
			}
			for (var j = 0; j < this.dDDS.columns[data].length; j++) {
				headers[j] = this.dDDS.columns[data][j]['header'];
				var index = (type=='xlsx' ? headers[j] : j );
				if (this.dDDS.columns[data][j]['pipe']=='currency') {
					this.addCur = true;
					exportData[i][index] = this.fixFx(this[tabledata][i][this.dDDS.columns[data][j]['field']], this[tabledata][i][this.dDDS.columns[data][j]['fxrate']], this[tabledata][i][this.dDDS.columns[data][j]['currency']]);
				} else {
					exportData[i][index] = this[tabledata][i][this.dDDS.columns[data][j]['field']];
				}
				switch (this.dDDS.columns[data][j]['footer']) {
				 	case "text":
				 		footer[j] = this.dDDS.columns[data][j]['text']
				 		break;
				 	case "count":
				 		count[j] = (count[j]==undefined ? 0 : count[j]) + (this[tabledata][i][this.dDDS.columns[data][j]['field']]==null ? 0 : 1);
				 		footer[j] = count[j];
				 		break;
				 	case "sum":
				 		footer[j] = (footer[j]==undefined ? 0 : footer[j]) + +exportData[i][index];
				 		break;
				 	case "wavg":
				 		count[j] = (count[j]==undefined ? 0 : count[j]) + +this[tabledata][i][this.dDDS.columns[data][j]['base']];
				 		sum[j] = (sum[j]==undefined ? 0 : sum[j]) + +exportData[i][j] * this[tabledata][i][this.dDDS.columns[data][j]['base']];
				 		footer[j] = (i == this[tabledata].length - 1 ? sum[j] / count[j] : '');
				 		break;
				 	default:
				 		footer[j] = '';
				 		break;
				 } 
			}
		}
		var title = data.charAt(0).toUpperCase() + data.slice(1) + (this.addCur ? ' (' + this.currency + ')' : '');
		return {'title': title, 'headers': headers, 'data': exportData };
	}

  fixFx(value: number, fx: number, currency: string) {
    if (this.currency=='USD') {
      value = value * (currency=='USD' ? 1 : 1/fx );
    } else {
      value = value * (currency=='USD' ? fx : 1 );
    }
    return value;
  }

  async bankExport(id = null, disabled = false) {
  	const batch = await (id==null ? this.lS.confirm("Export as Batch or Singular?",'Batch','Singular') : true);
  	if (!disabled) {
  		var clearAfter = this.sortCheckBoxes(id);
  		if (!batch) {
  			for (var i=0; i<this.checkboxdata.length; i++) {
  				var p = {
						"season": this.lS.season,
						"type": 'Nedbank',
						"payments": [this.checkboxdata[i]]
			  	}
			  	this.bankExportRequest(p);
  			}
  		} else {
		  	var p = {
					"season": this.lS.season,
					"type": 'Nedbank',
					"payments": this.checkboxdata
		  	}
		  	this.bankExportRequest(p);
		  }
	  	if (clearAfter) {
	  		this.selectedRows = [];
	  		this.checkBoxes = false;
	  	}
	  }
  }

  async bankExportRequest(p) {
  	var response = await this.lS.loadData('paymentsBankExport', p);
  	var msgsent = false;
    if (response.unsuccessful.length>0) {
			this.csvService.rawCSV('ERROR REPORT: ' + response.title, response.headers, response.unsuccessful, true);
    	this.lS.toast('Payment Export Warning', response.message, 'warn');
    	msgsent = true;
    }
    if (response.export.length>0) {
    	this.csvService.rawCSV(response.title, response.headers, response.export, true);
    	if (!msgsent) {
    		this.lS.toast('Payment Export Successful', response.message);
    		msgsent = true;
    	}
    }
		if (!msgsent) {
    	this.lS.toast('Payment Export', response.message, 'warn');
    }
  }

  sortCheckBoxes(id) {
  	this.checkboxdata = [];
  	var clearAfter = true;
  	if (id==null) {
  		for (var i = 0; i < this.selectedRows.length; i++) {
  			if (this.selectedRows[i]['disabled']==0) {
	  			this.checkboxdata.push({"idpayment": this.selectedRows[i]['id'], "companyname": this.selectedRows[i]['companyname']});
	  		}
	  	}
	  } else {
	  	clearAfter = false;
	  	this.checkboxdata = [{"idpayment": id}];
	  }
	  return clearAfter;
  }

  async boxChecked(tablename,table,key,newamount = false) {
  	switch (tablename) {
  		case "payments":
		  	if (this.selectedRows.length==1) {
		  		this.batchpay['idpayment'] = this.selectedRows[0]['idpayment'];
		  		this.batchpay['iddelivery'] = this.selectedRows[0]['iddelivery'];
		  		this.batchpay['instalment'] = this.selectedRows[0]['instalment'];
		  		this.batchpay['fxrate'] = this.selectedRows[0]['fxrate'];
		  		this.batchpay['newpaid'] = (newamount ? this.batchpay['newpaid'] : this.selectedRows[0]['amountdue']);
		  		this.batchpay['amountpaid'] = (newamount ? this.batchpay['newpaid'] : this.selectedRows[0]['amountpaid']);
		  		this.batchpay['amountdue'] = this.selectedRows[0]['amountdue'];
		  		this.batchpay['paycurrency'] = this.selectedRows[0]['paycurrency'];
		  		if (this.selectedRows[0]['paiddate']==null || this.selectedRows[0]['paiddate']=="") {
		  			var d = new Date;
		    		this.batchpay['paiddate'] = d.toISOString().substring(0,10);
		  		} else {
		  			this.batchpay['paiddate'] = this.selectedRows[0]['paiddate'];
					}
		  		if (this.batchpay['paycurrency'] == 'USD') {
			      this.batchpay['amountpaidUSD'] = +this.batchpay['newpaid'];
			      this.batchpay['amountpaidZAR'] = +this.batchpay['newpaid'] * this.batchpay['fxrate'];
			    } else {
			      this.batchpay['amountpaidUSD'] = +this.batchpay['newpaid'] / this.batchpay['fxrate'];
			      this.batchpay['amountpaidZAR'] = +this.batchpay['newpaid'];
			    }
		  	} else if (this.selectedRows.length>1) {
					var d = new Date;
		    	this.batchpay['paiddate'] = d.toISOString().substring(0,10);
		  	}
		  	break;
		  case 'pickinglist':
		  case 'pickedlist':
				this.checkboxdata = table._selection;
				var fullSelect = [];
				if (this.checkboxdata.length==1) {
			    var proceed = false;
			    for (var i = 0; i < this.checkboxdata.length; i++) {
			    	if (this.checkboxdata[i]['id']==key) {
			    		proceed = true;
			    	}
			    }
	    		if (proceed) {
						if (this.checkboxdata[0]['orderref']!==null && this.checkboxdata[0]['orderref']!=='') {
							const res = await this.lS.confirm("Do you want to select all pallets with this order reference?");
					    if (res) {
					    	for (var i=0; i < this[tablename].length; i++) {
									if (this[tablename][i]['orderref']==this.checkboxdata[0]['orderref']) {
										fullSelect.push(this[tablename][i]);
									}
								}
								if (fullSelect.length>1) {
									this.selectedRows = await [...fullSelect];
						    }
						  }
						}
					}
				}
		  	const finish = await this.loadOrder(tablename);
			  break;
	  	default:
				this.checkboxdata = table._selection;
  			break;
	  	}
  }

  async loadOrder(tablename) {
  	this.getPickingOrders();
    for (var i = 0; i < this.orderdetails.length; i++) {
    	if (this.orderdetails[i]['orderref']==this.selectedRows[0]['orderref']) {
    		this.order = i.toString();
    	}
    }
  }

  async getPickingOrders() {
    var p = {
      "location": 1,
      "season": +this.lS.season,
      "date": '',
      //"style": this.checkboxdata[0]['abbr']
    };
    this.orderdetails = await this.lS.loadData("orderPickingList",p);  	
  }

  refreshSelectedRows(tablename, key) {
  	if (this.selectedRows!=undefined) {
	  	for (var i=0; i<this.selectedRows.length; i++) {
	  		for (var j=0; j<this[tablename].length; j++) {
	  			if (this[tablename][j][key]==this.selectedRows[i][key]) {
	  				this.selectedRows[i] = this[tablename][j];
	  			}
	  		}
	  	}
	  }
  }

  updateRow(tablename, id, changes) {
	  var key = this.allData[tablename]['key'];
  	for (var i=0; i<changes.length; i++) {
  		var field = changes[i]['field'];
  		var value = changes[i]['value'];
			for (var j=0; j<this[tablename].length; j++) {
				if (this[tablename][j][key]==id) {
					this[tablename][j][field] = value;
				}
		  }
		}
		this.refreshSelectedRows(tablename, key);
  }

  totalProducts() {
    this['orders'][this.clickedIndex['orders']].original = true;
    this['orders'][this.clickedIndex['orders']].buyeradd = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.buyeraddr, 44);
    this['orders'][this.clickedIndex['orders']].buyern = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.buyer, 32);
    this['orders'][this.clickedIndex['orders']].buyernl = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.buyer, 35);
    this['orders'][this.clickedIndex['orders']].consigneeadd = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.consigneeaddr, 44);
    this['orders'][this.clickedIndex['orders']].consigneeadds = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.consigneeaddr, 35);
    this['orders'][this.clickedIndex['orders']].consigneen = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.consignee, 32);
    this['orders'][this.clickedIndex['orders']].consigneenl = this.pS.lineBreak(this['orders'][this.clickedIndex['orders']]?.consignee, 35);
    this['orders'][this.clickedIndex['orders']].longbuyarr = this.splitAddress(this['orders'][this.clickedIndex['orders']]?.longbuyaddr);
    this['orders'][this.clickedIndex['orders']].longconarr = this.splitAddress(this['orders'][this.clickedIndex['orders']]?.longconaddr);
    this['orders'][this.clickedIndex['orders']].tunit = 0;
    this['orders'][this.clickedIndex['orders']].tweight = 0;
    this['orders'][this.clickedIndex['orders']].ttotal = 0;
    this['orders'][this.clickedIndex['orders']].sizes = '';
    for (var i=0; i<this['orders'][this.clickedIndex['orders']]?.details.length; i++) {
      this['orders'][this.clickedIndex['orders']].tunit += this['orders'][this.clickedIndex['orders']]?.details[i]['quantity'] / ( this['orders'][this.clickedIndex['orders']]?.details[i]['type']=='nis' ? 25 : 1 );
      this['orders'][this.clickedIndex['orders']].tweight += this['orders'][this.clickedIndex['orders']]?.details[i]['quantity'] * ( this['orders'][this.clickedIndex['orders']]?.details[i]['type']=='nis' ? 1 : 11.34 );
      this['orders'][this.clickedIndex['orders']].ttotal += this['orders'][this.clickedIndex['orders']]?.details[i]['quantity'] * this['orders'][this.clickedIndex['orders']]?.details[i]['price'] * ( this['orders'][this.clickedIndex['orders']]?.details[i]['type']=='nis' ? 1 : 11.34 );
      this['orders'][this.clickedIndex['orders']].sizes += (this['orders'][this.clickedIndex['orders']].sizes=='' ? '' : ', ') + this['orders'][this.clickedIndex['orders']].fullstyle.substring(4);
    }
    this['orders'][this.clickedIndex['orders']].vattotal = 0;
    for (var i=0; i<this['orders'][this.clickedIndex['orders']]?.fees.length; i++) {
      this['orders'][this.clickedIndex['orders']].ttotal += +this['orders'][this.clickedIndex['orders']]?.fees[i]['amount'];
      this['orders'][this.clickedIndex['orders']].vattotal += +this['orders'][this.clickedIndex['orders']]?.fees[i]['amount'] * this['orders'][this.clickedIndex['orders']]?.fees[i]['vatable'] * this['orders'][this.clickedIndex['orders']]?.fees[i]['vatrate'];
    }
    this.loadQuality();
    this.loadPPECB();
  }

  splitAddress(address) {
  	if (address==null) {
  		return [];
  	}
  	let buyaddr = address.split(', ');
    if (buyaddr.length>4) {
    	let remaining = buyaddr.slice(3).join(', ');
    	buyaddr = buyaddr.slice(0,3);
    	buyaddr.push(remaining);
    }
    for (let i=0; i<buyaddr.length-1; i++) {
    	buyaddr[i] += ',';
    }
    return buyaddr;
  }

  async loadQuality() {
    var p = {
      "season": this.lS.season,
      "orderref": this['orders'][this.clickedIndex['orders']].orderref,
    }
    this.quality = await this.lS.loadData('loadQCert', p);
    var seasonlist = [];
    var seasons = '';
    for (var i=0; i<this.quality.length; i++) {
    	seasonlist[this.quality[i]['season']] = 1;
    }
		for (const [k, v] of Object.entries(seasonlist)) {
		  seasons = (seasons.length>0 ? seasons + ', ' : '') + k.toString();
		}
    this.quality[0]['seasons'] = seasons;
  }

  async loadPPECB() {
    var p = {
      "season": this.lS.season,
      "orderref": this['orders'][this.clickedIndex['orders']].orderref,
    }
    this.ppecb = await this.lS.loadData('loadPPECB', p);
  }

}