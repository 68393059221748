import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule, WavesModule, ButtonsModule } from 'angular-bootstrap-md'

import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { coerceNumberProperty } from '@angular/cdk/coercion';

import { TeamLoginService } from '../../auth/login/teamlogin.service';
import { PortalService } from '../../../auth/portal.service';
import { DropDownDataService } from '../../logging/dropdown.service';
import { PriceModelService } from './pricemodel.service';

@Component({
  selector: 'app-pricemodel',
  templateUrl: './pricemodel.component.html',
  styleUrls: ['./pricemodel.component.scss']
})
export class PriceModelComponent implements OnInit {

  constructor(
    public loginService: TeamLoginService,
    public dDDS: DropDownDataService,
    public pMS: PriceModelService,
    public portalService: PortalService
    ) { }

  ngOnInit() {
    window.scrollTo(0,0);
    this.pMS.editmode = false;
    this.dDDS.getDropdown("priceModelListAll");
    this.dDDS.getDropdown("cultivarList");
    this.dDDS.getDropdown("depotList");
    this.pMS.season = this.loginService.season;
  }

  ngOnDestroy() {
    this.pMS.resetForm();
    this.dDDS.getDropdown("priceModelListAll");
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  canSave() {
    if (
        this.pMS.pricemodelname !== undefined &&
        this.pMS.pricemodelname !== '' &&  
        +this.pMS.season > 0 && 
        this.pMS.grosspricecurrency !== undefined && 
        this.pMS.feecurrency !== undefined && 
        this.pMS.invcurrency !== undefined && 
        this.pMS.paycurrency !== undefined &&
        +this.pMS.fxrate > 0
      ) {
      if (this.pMS.method !== undefined && this.pMS.format !== undefined) {
        if (
          (this.pMS.drymethod=='aboveonly' && this.pMS.dryrate<'0') ||
          (this.pMS.drymethod=='highorlow' && (this.pMS.dryfeehigh<'0' || this.pMS.dryfeelow<'0'))
          ) {
          return false;
        }
        if (+this.pMS.method!=8) {
          if (this.checkList('grossprices',(this.pMS.method=='4' ? 'skr' : 'style' ),false)=='false') {
            var ans=false;
            if (this.pMS.grossdnis18.length>0 && this.checkList('grosssdnis18','min',true)=='true') {
              ans = true;
            } else if(this.pMS.grossdnis20.length>0 && this.checkList('grosssdnis20','min',true)=='true') {
              ans = true;
            } else if(this.pMS.grossdnis22.length>0 && this.checkList('grosssdnis22','min',true)=='true') {
              ans = true;
            } else if(this.pMS.grossdnis25.length>0 && this.checkList('grosssdnis25','min',true)=='true') {
              ans = true;
            }
            if (!ans) {
              return false;
            }
          }
          if (this.checkList('processfees','uskr',false)=='false') {
            return false;
          }
          if (this.pMS.format=='1') {
            if (this.checkList('estgross',(this.pMS.estimated=='1' ? 'skr' : 'style' ))=='false') {
              return false;
            }
            if (this.pMS.estimated == undefined) {
              return false;
            }
          }
        }
        if (this.pMS.cultivarbool) {
          if (this.checkList('cultivarbonus','cultivar')=='false') {return false;}
          if (this.checkList('cultivarbonus','bonus')=='false') {return false;}
        }
        if (+this.pMS.processlimit <= 0) {return false;}
        if (this.pMS.processlimitsobool && (+this.pMS.processlimitso<0 || +this.pMS.processlimitso>1)) {return false;}
        if (this.pMS.transportdepotbool) {
          if (this.checkList('transportdepots','depotfee')=='false') {return false;}
          if (this.checkList('transportdepots','depotname')=='false') {return false;}
        }
        var ans = this.pMS.checkTerms();
        if (ans == false) {
          return false
        }
      } else {
        return false;
      }
      return true;
    } else {
      return false;
    }
  }

  canUpdate() {
    return this.canSave() && this.pMS.pricemodelname!==this.pMS.pricemodeleditname;
  }

  editModeOn(state) {
    this.pMS.resetForm(state);
    this.pMS.editmode = state;
  }

  editSelected() {
    return (this.pMS.editmode == false || (this.pMS.editmode == true && this.pMS.pricemodeledit>0));
  }

  onCalculate() {
    if (this.canSave()) {
      this.pMS.dryfloorextra = (this.isAuthenticated('mod-dryfloorextra') ? this.pMS.dryfloorextra : this.pMS.dryfloor);
      this.pMS.addPriceModel();
      window.scroll(0,0);
      this.pMS.resetForm();
    }
  }

  async onDelete() {
    const res = await this.loginService.confirm("Are sure you want to delete this Price Model?");
    if (res) {
      this.pMS.deleteEntry();
      this.pMS.resetForm();
    }
  }

  saveChanges(duplicate: boolean = false) {
    this.pMS.dryfloorextra = (this.isAuthenticated('mod-dryfloorextra') ? this.pMS.dryfloorextra : this.pMS.dryfloor);
    this.pMS.saveChanges(duplicate);
  }

  changeAmount($event,itemGroup,itemindex = null,key = null,percent = false,posonly = true) {
    try {
      var value = $event.replace(/[^\d\.\-]/g, "").replace(/\./g, (c, i, text) => text.indexOf(c) === i ? c : '').replace(/\-/g, (c, i, text) => text.indexOf(c) === i ? c : '');
      if (posonly) {
        value = Math.abs(value);
      }
      value = (percent ? value/100 : value);
    }
    catch(e) {
      value = null;
    }    
    if (itemindex==null) {
      this.pMS[itemGroup.slice(0)] = value;
    } else {
      this.pMS[itemGroup.slice(0)][itemindex][key] = value;
    }
    switch (itemGroup) {
      case "dryfloor":
        this.pMS.dryfloornis = this.portalService.calMoisture(+value, 0) + '';
        break;
      case "dryfloornis":
        this.pMS.dryfloor = this.portalService.calInvMoisture(+value, 0) + '';
        break;
      case "smallsmoisture":
        this.pMS.smallstotalmoisture = this.portalService.calMoisture(+value, 0) + '';
        break;
      case "smallstotalmoisture":
        this.pMS.smallsmoisture = this.portalService.calInvMoisture(+value, 0) + '';
        break;
      case "selectoutmoisture":
        this.pMS.selectouttotalmoisture = this.portalService.calMoisture(+value, 0) + '';
        break;
      case "selectouttotalmoisture":
        this.pMS.selectoutmoisture = this.portalService.calInvMoisture(+value, 0) + '';
        break;
    }
  }

  checkEntry($event,itemGroup,itemindex = null,key = null,nonzero = false,allowDupiclate = false) {
    var ans = 'false';
    if (this.pMS[itemGroup.slice(0)][itemindex][key]==null) {
      return true;
    }
    if (nonzero) {
      ans = (+this.pMS[itemGroup.slice(0)][itemindex][key]==0 ? 'true' : ans );
    }
    if (itemindex!==null && !allowDupiclate) {
      for (var i = 0; i < this.pMS[itemGroup.slice(0)].length; i++) {
        if (itemindex!==i) {
          if (key=='style' && itemGroup=='grossprices' && this.pMS[itemGroup.slice(0)][itemindex]['style'].slice(0,1)=='N') {
            ans = (this.pMS[itemGroup.slice(0)][itemindex]['code'] == undefined ? 'true' : ans );
            if (this.pMS[itemGroup.slice(0)][itemindex]['code']==undefined || this.pMS[itemGroup.slice(0)][itemindex]['code']==null || this.pMS[itemGroup.slice(0)][itemindex]['skr']==undefined || this.pMS[itemGroup.slice(0)][itemindex]['skr']==null || this.pMS[itemGroup.slice(0)][itemindex]['uskr']==undefined || this.pMS[itemGroup.slice(0)][itemindex]['uskr']==null) {
              ans = 'true';
            }
            if (this.pMS[itemGroup.slice(0)][i]['style']!==null) {
              if (this.pMS[itemGroup.slice(0)][i]['style'].slice(0,1)=='N') {
                ans = (this.pMS[itemGroup.slice(0)][i]['code'] == this.pMS[itemGroup.slice(0)][itemindex]['code'] ? 'true' : ans );
              }
            }
          } else {
            ans = (this.pMS[itemGroup.slice(0)][i][key] == this.pMS[itemGroup.slice(0)][itemindex][key] ? 'true' : ans ); 
          }
        }
      }
    }
    return (ans=='true' ? true : false);
  }

  checkList(itemGroup,key,nonzero = false) {
    var ans = 'true';
    if (this.pMS[itemGroup.slice(0)]!==undefined) {
      var total = this.pMS[itemGroup.slice(0)].length;
      if (total > 0) {
        for (var i = 0; i < total; i++) {
          if (this.pMS[itemGroup.slice(0)][i][key]==null) {
            ans = 'false';
          }
          if (this.pMS[itemGroup.slice(0)][i]['rate']!==undefined && nonzero) {
            if (this.pMS[itemGroup.slice(0)][i]['rate']<=0) {
              ans = 'false';
            }
          }
          for (var j = i + 1; j < total; j++) { 
            if (this.pMS[itemGroup.slice(0)][j][key]==null) {
              ans = 'false';
            }
            if (key=='style' && itemGroup=='grossprices' && this.pMS[itemGroup.slice(0)][i]['style'].slice(0,1)=='N') {
              ans = (this.pMS[itemGroup.slice(0)][i]['code'] == undefined ? 'true' : ans );
              if (this.pMS[itemGroup.slice(0)][i]['code']==undefined || this.pMS[itemGroup.slice(0)][i]['code']==null || this.pMS[itemGroup.slice(0)][i]['skr']==undefined || this.pMS[itemGroup.slice(0)][i]['skr']==null || this.pMS[itemGroup.slice(0)][i]['uskr']==undefined || this.pMS[itemGroup.slice(0)][i]['uskr']==null) {
                ans = 'true';
              }
              if (this.pMS[itemGroup.slice(0)][j]['style']!==null) {
                if (this.pMS[itemGroup.slice(0)][j]['style'].slice(0,1)=='N') {
                  ans = (this.pMS[itemGroup.slice(0)][j]['code'] == this.pMS[itemGroup.slice(0)][i]['code'] ? 'true' : ans );
                }
              }
            } else {
              ans = (this.pMS[itemGroup.slice(0)][j][key] == this.pMS[itemGroup.slice(0)][i][key] ? 'true' : ans ); 
            }
          }
        }
      }
    }
    return ans;
  }

  setStyleCode(itemindex) {
    if (this.pMS.grossprices[itemindex]['skr']==null || this.pMS.grossprices[itemindex]['uskr']==null) {
      this.pMS.grossprices[itemindex]['code']==this.pMS.grossprices[itemindex]['style'];
    } else {
      var skr: number = +this.pMS.grossprices[itemindex]['skr'];
      var uskr: number = +this.pMS.grossprices[itemindex]['uskr'];
      this.pMS.grossprices[itemindex]['code'] = this.pMS.grossprices[itemindex]['style'] + ';' + skr.toFixed(2) + ';' + uskr.toFixed(2);
    }
  }

  changeAmountCode($event,itemGroup,itemindex = null,key = null,percent = false,posonly = true) {
    this.changeAmount($event,itemGroup,itemindex,key,percent,posonly);
    this.setStyleCode(itemindex);
  }

}