<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
	<br>
	<div class="row justify-content-between">
		<div class="col main-header">
			<h2>Pricing Calculator</h2>
		</div>
		<div class="dropdown col-auto" dropdown>
      <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
      {{ this.cS.selectedCurrency }}<span class="caret"></span></a>
      <ul *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
        <button 
        	class="dropdown-item waves-light py-3" 
        	mdbWavesEffect 
        	(click)="onCurrencySelected('ZAR')">ZAR
        </button>
        <button 
        	class="dropdown-item waves-light py-3" 
        	mdbWavesEffect 
        	(click)="onCurrencySelected('USD')">USD
        </button>
      </ul>
    </div>
	</div>
	<br>
	<h4>
		<div style="line-height:1.8em;">
			<span style="display:inline-block;" class="pr-1">Pricing below is based on:</span><span style="display:inline-block;">{{ this.cS.currentModel }}</span>
		</div>
	</h4>
	<br>
		
	<ng-template [ngIf]="calComplete">
		<h4>Calculated Pricing</h4>
		<br>
	</ng-template>
	
	<ng-container *ngIf="this.cS.priceModel['method']==6">
		<div class="col col-lg-10 col-xl-8 pl-0">
			<hr class="hrthick">
		  <div class="form-group row align-items-center justify-content-center mb-4">
		    <div class="col-3 d-flex flex-column align-items-center">
	        <label for="knob25" class="col-form-label">>25mm</label>
	        <div class="d-flex justify-content-center">
            <p-knob [(ngModel)]="this.cS.sizes['dnis25']" #knob25 (onChange)="this.showknob('dnis25',$event)" [disabled]="calComplete" valueTemplate="{value}%" style="margin-top: 10px;"></p-knob>
	        </div>
	        <ng-container *ngIf="calComplete">
            <div class="flex gap-2 mt-4">
						   <p>{{ this.cS.dataStore.sizepricesinv['dnis25'] | currency:this.cS.selectedCurrency:'symbol-narrow' }}</p>
						</div>
					</ng-container>
	        <ng-container *ngIf="!calComplete">
            <div class="flex gap-2 mt-4">
					    <p-button class="me-2" icon="pi pi-plus" (click)="this.plus('dnis25')" [disabled]="this.cS.sizes['dnis25'] >= 100" />
					    <p-button icon="pi pi-minus" (click)="this.minus('dnis25')" [disabled]="this.cS.sizes['dnis25'] <= 0" />
						</div>
					</ng-container>
		    </div>
		    <div class="col-3 d-flex flex-column align-items-center">
	        <label for="knob22" class="col-form-label">>22mm</label>
	        <div class="d-flex justify-content-center">
            <p-knob [(ngModel)]="this.cS.sizes['dnis22']" #knob22 (onChange)="this.showknob('dnis22',$event)" [disabled]="calComplete" valueTemplate="{value}%" style="margin-top: 10px;"></p-knob>
	        </div>
	        <ng-container *ngIf="calComplete">
            <div class="flex gap-2 mt-4">
						   <p>{{ this.cS.dataStore.sizepricesinv['dnis22'] | currency:this.cS.selectedCurrency:'symbol-narrow' }}</p>
						</div>
					</ng-container>
	        <ng-container *ngIf="!calComplete">
            <div class="flex gap-2 mt-4">
					    <p-button class="me-2" icon="pi pi-plus" (click)="this.plus('dnis22')" [disabled]="this.cS.sizes['dnis22'] >= 100" />
					    <p-button icon="pi pi-minus" (click)="this.minus('dnis22')" [disabled]="this.cS.sizes['dnis22'] <= 0" />
						</div>
					</ng-container>
		    </div>
		    <div class="col-3 d-flex flex-column align-items-center">
	        <label for="knob20" class="col-form-label">>20mm</label>
	        <div class="d-flex justify-content-center">
            <p-knob [(ngModel)]="this.cS.sizes['dnis20']" #knob20 (onChange)="this.showknob('dnis20',$event)" [disabled]="calComplete" valueTemplate="{value}%" style="margin-top: 10px;"></p-knob>
	        </div>
	        <ng-container *ngIf="calComplete">
            <div class="flex gap-2 mt-4">
						   <p>{{ this.cS.dataStore.sizepricesinv['dnis20'] | currency:this.cS.selectedCurrency:'symbol-narrow' }}</p>
						</div>
					</ng-container>
	        <ng-container *ngIf="!calComplete">
            <div class="flex gap-2 mt-4">
					    <p-button class="me-2" icon="pi pi-plus" (click)="this.plus('dnis20')" [disabled]="this.cS.sizes['dnis20'] >= 100" />
					    <p-button icon="pi pi-minus" (click)="this.minus('dnis20')" [disabled]="this.cS.sizes['dnis20'] <= 0" />
						</div>
					</ng-container>
		    </div>
		    <div class="col-3 d-flex flex-column align-items-center">
	        <label for="knob18" class="col-form-label">>18mm</label>
	        <div class="d-flex justify-content-center">
            <p-knob [(ngModel)]="this.cS.sizes['dnis18']" #knob18 [disabled]="true" valueTemplate="{value}%" style="margin-top: 10px;"></p-knob>
	        </div>
	        <ng-container *ngIf="calComplete">
            <div class="flex gap-2 mt-4">
						   <p>{{ this.cS.dataStore.sizepricesinv['dnis18'] | currency:this.cS.selectedCurrency:'symbol-narrow' }}</p>
						</div>
					</ng-container>
	        <ng-container *ngIf="!calComplete">
            <div class="flex gap-2 mt-4">
					    <p-button class="me-2" icon="pi pi-plus" [disabled]="true" />
					    <p-button icon="pi pi-minus" [disabled]="true" />
						</div>
					</ng-container>
		    </div>
			</div>
		</div>
	</ng-container>

	<ng-template [ngIf]="!calComplete">
		<div class="col col-lg-9 col-xl-7 pl-0">
			<hr class="hrthick">
			<ng-container *ngIf="this.cS.needCultivar">
				<div class="form-group row align-items-center justify-content-center">
			    <label for="cultivar" class="col-4 col-xs-4 col-md-3 col-form-label">Cultivar</label>
			    <div class="form-container col-8 col-xs-8 col-md-8 align-items-left">
			    	<mat-form-field class="col">
					    <mat-select [(ngModel)]="cultivarIndex">
					      <mat-option *ngFor="let cult of this.cS.cultivarList; let cultIndex = index;" value="{{ cultIndex }}" (click)="onChoose()">{{ cult.cultivar }}</mat-option>
					    </mat-select>
					  </mat-form-field>
			    </div>
			  </div>
			  <hr>
			</ng-container>
		  <div class="formform-group row align-items-center justify-content-center">
		    <!-- Material input -->
		    <label for="skr" class="col-4 col-xs-4 col-md-3 col-form-label">SKR (%)</label>
		    <div class="col-4 col-xs-3 col-md-3 align-items-center">
		      <div class="md-form mt-2 mb-0">
		        <input mdbInput type="number" style="text-align:center;" step="0.1" min="15" max="50" class="form-control" id="skr" value="10" [(ngModel)]="skr">
		      </div>
		    </div>
		    <div class="col-4 col-xs-5 col-md-5 align-items-center">
		    	<mat-slider
		    		id="skr"
		        class="example-margin"
		        style="width:100%;max-width:300px;min-width:75px;"
		        [max]="50"
		        [min]="15"
		        [step]="0.1"
		        [(ngModel)]="skr" #ngSlider><input matSliderThumb (input)="onInputChangeSKR({source: ngSliderThumbskr, parent: ngSlider, value: ngSliderThumbskr.value})" #ngSliderThumbskr="matSliderThumb" />
		    	</mat-slider>
		    </div>
		  </div>
			<hr>
			<div class="form-group row align-items-center justify-content-center">
		    <!-- Material input -->
		    <label for="uskr" class="col-4 col-xs-4 col-md-3 col-form-label">USKR (%)</label>
		    <div class="col-4 col-xs-3 col-md-3 align-items-center">
		      <div class="md-form mt-2 mb-0">
		        <input mdbInput type="number" style="text-align:center;" step="0.1" min="0" max="10" class="form-control" id="uskr" value="1.6" [(ngModel)]="uskr">
		      </div>
		    </div>
		    <div class="col-4 col-xs-5 col-md-5 align-items-center">
		    	<mat-slider
		        class="example-margin"
		        style="width:100%;max-width:300px;min-width:75px;"
		        [max]="15"
		        [min]="0"
		        [step]="0.1"
		        [(ngModel)]="uskr" #ngSlider><input matSliderThumb (input)="onInputChangeUSKR({source: ngSliderThumbuskr, parent: ngSlider, value: ngSliderThumbuskr.value})" #ngSliderThumbuskr="matSliderThumb" />
		    	</mat-slider>
		    </div>
		  </div>
			<hr>
			<div class="form-group row align-items-center justify-content-center">
		    <!-- Material input -->
		    <label for="darks" class="col-4 col-xs-4 col-md-3 col-form-label">Darks (%)</label>
		    <div class="col-4 col-xs-3 col-md-3 align-items-center">
		      <div class="md-form mt-2 mb-0">
		        <input mdbInput type="number" style="text-align:center;" step="0.1" min="0" max="10" class="form-control" id="darks" value="1.6" [(ngModel)]="darks">
		      </div>
		    </div>
		    <div class="col-4 col-xs-5 col-md-5 align-items-center">
		    	<mat-slider
		        class="example-margin"
		        style="width:100%;max-width:300px;min-width:75px;"
		        [max]="10"
		        [min]="0"
		        [step]="0.1"
		        [(ngModel)]="darks" #ngSlider><input matSliderThumb (input)="onInputChangeDarks({source: ngSliderThumbdarks, parent: ngSlider, value: ngSliderThumbdarks.value})" #ngSliderThumbdarks="matSliderThumb" />
		    	</mat-slider>
		    </div>
		  </div>
			<hr>
			<div class="form-group row align-items-center justify-content-center">
		    <label for="moisture" class="col-4 col-xs-4 col-md-3 col-form-label">Moisture (%)</label>
		    <div class="col-4 col-xs-3 col-md-3 align-items-center">
		      <div class="md-form mt-2 mb-0">
		        <input mdbInput type="number" style="text-align:center;" step="0.1" min="0" max="30" class="form-control" id="moisture" value="10" [(ngModel)]="moisture">
		      </div>
		    </div>
		    <div class="col-4 col-xs-5 col-md-5 align-items-center">
		    	<mat-slider
		        class="example-margin"
		        style="width:100%;max-width:300px;min-width:75px;"
		        [max]="30"
		        [min]="0"
		        [step]="0.1"
		        [(ngModel)]="moisture" #ngSlider><input matSliderThumb (input)="onInputChangeMoisture({source: ngSliderThumbmoisture, parent: ngSlider, value: ngSliderThumbmoisture.value})" #ngSliderThumbmoisture="matSliderThumb" />
		    	</mat-slider>
		    </div>
		  </div>
			<hr>
		  <div class="form-group row align-items-center justify-content-center">
		    <!-- Material input -->
		    <label for="collection" class="col-4 col-xs-4 col-md-3 col-form-label">Paid Collection?</label>
		    <div class="col-4 col-xs-3 col-md-3 align-items-center" style="text-align:center;">
		    	{{ ( checked ? "Yes" : "No" ) }}
		    </div>
		    <div class="col-4 col-xs-5 col-md-5 align-items-center">
		    	<mat-slide-toggle
		          class="example-margin"
		          [color]="color"
		          [checked]="checked"
		          [(ngModel)]="checked"
		          [disabled]="disabled"> 
		      </mat-slide-toggle>
		    </div>
		  </div>
		  <ng-template [ngIf]="this.checked">
			  <hr>
				<div class="form-group row align-items-center justify-content-center">
			    <label for="moisture" class="col-4 col-xs-4 col-md-3 col-form-label">Return Distance (km)</label>
			    <div class="col-4 col-xs-3 col-md-3 align-items-center">
			      <div class="md-form mt-2 mb-0">
			        <input mdbInput type="number" style="text-align:center;" step="5" min="0" max="300" class="form-control" id="moisture" value="10" [(ngModel)]="transportkm">
			      </div>
			    </div>
			    <div class="col-4 col-xs-5 col-md-5 align-items-center">
			    	<mat-slider
			        class="example-margin"
			        style="width:100%;max-width:300px;min-width:75px;"
			        [max]="300"
			        [min]="0"
			        [step]="5"
			        [(ngModel)]="transportkm" #ngSlider><input matSliderThumb (input)="onInputChangeKM({source: ngSliderThumbtransport, parent: ngSlider, value: ngSliderThumbtransport.value})" #ngSliderThumbtransport="matSliderThumb" />
			    	</mat-slider>
			    </div>
			  </div>
		  	<hr>
				<div class="form-group row align-items-center justify-content-center">
			    <label for="moisture" class="col-4 col-xs-4 col-md-3 col-form-label">WNIS (kg)</label>
			    <div class="col-4 col-xs-3 col-md-3 align-items-center">
			      <div class="md-form mt-2 mb-0">
			        <input mdbInput type="number" style="text-align:center;" step="50" min="4000" max="8000" class="form-control" id="moisture" value="10" [(ngModel)]="wnis">
			      </div>
			    </div>
			    <div class="col-4 col-xs-5 col-md-5 align-items-center">
			    	<mat-slider
			        class="example-margin"
			        style="width:100%;max-width:300px;min-width:75px;"
			        [max]="8000"
			        [min]="4000"
			        [step]="250"
			        [(ngModel)]="wnis" #ngSlider><input matSliderThumb (input)="onInputChangeWNIS({source: ngSliderThumbwnis, parent: ngSlider, value: ngSliderThumbwnis.value})" #ngSliderThumbwnis="matSliderThumb" />
			    	</mat-slider>
			    </div>
			  </div>
		  </ng-template>
		  <hr>
		  <div class="form-group row align-items-center justify-content-center">
		    <!-- Material input -->
		    <label for="collection" class="col-4 col-xs-4 col-md-3 col-form-label">VAT Registered?</label>
		    <div class="col-4 col-xs-3 col-md-3 align-items-center" style="text-align:center;">
		    	{{ ( checkedVAT ? "Yes" : "No" ) }}
		    </div>
		    <div class="col-4 col-xs-5 col-md-5 align-items-center">
		    	<mat-slide-toggle
		          class="example-margin"
		          [color]="color"
		          [checked]="checkedVAT"
		          [(ngModel)]="checkedVAT"
		          [disabled]="disabled"> 
		      </mat-slide-toggle>
		    </div>
		  </div>
			<hr class="hrthick">
			<br>
			<button mdbBtn class="btn btn-primary" (click)="onCalculate()">Calculate</button>
			<br><br>
		</div>
	</ng-template>
	
	<!-- Calculated Pricing -->
	<ng-template [ngIf]="calComplete">
		<div class="col col-lg-10 col-xl-8 pl-0">
			<table class="table col-xs">
				<tbody>
					<tr class="tableseperator"><td></td><td></td></tr>
						<ng-template [ngIf]="this.checked">
							<tr>
								<td>WNIS (kg)</td>
								<td class="posnumber">{{ +this.wnis | number:'1.0-0' }} kg</td>
							</tr>
						</ng-template>
					<tr *ngIf="this.cS.needCultivar">
						<td>Cultivar</td>
						<td>{{ this.cultivar.cultivar }}</td>
					</tr>
					<tr>
						<td>SKR (%)</td>
						<td class="posnumber">{{ +this.skr/100 | percent:'1.2-2' }}</td>
					</tr>
					<tr>
						<td>USKR (%)</td>
						<td class="posnumber">{{ +this.uskr/100 | percent:'1.2-2' }}</td>
					</tr>
					<tr *ngIf="+this.darks > 0">
						<td>Darks (%)</td>
						<td class="posnumber">{{ +this.darks/100 | percent:'1.2-2' }}</td>
					</tr>
					<tr>
						<td>Moisture (%)</td>
						<td class="posnumber">{{ +this.moisture/100 | percent:'1.2-2' }}</td>
					</tr>
					<tr>
						<td>Collected?</td>
							<td class="posnumber">{{ ( this.checked ? "Yes" : "No" ) }}{{ ( this.checked && this.transportkm>0 ? " (" + this.transportkm + "km)" : "" ) }}</td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<!--
					<tr>
						<td>DNIS (kg)</td>
						<td class="posnumber">{{ +this.cS.dataStore?.dnis | number:'1.0-0' }} kg</td>
					</tr>
				-->
					<tr *ngIf="this.cS.priceModel['method']!==6">
						<td class="pl-4">Gross SK Price / kg of DNIS</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.gdnisprice | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					<ng-container *ngIf="this.cS.priceModel['method']==6">
						<tr>
							<td class="pl-4">Gross SK Price >25mm / kg of DNIS</td>
							<td class="pl-4 posnumber">{{ +this.cS.dataStore?.sizepricesinv['dnis25'] | currency:this.cS.selectedCurrency:'symbol-narrow' }} x {{ this.cS.dataStore?.sizepercents['dnis25'] | percent:'1.0' }}</td>
						</tr>
						<tr>
							<td class="pl-4">Gross SK Price >22mm / kg of DNIS</td>
							<td class="pl-4 posnumber">{{ +this.cS.dataStore?.sizepricesinv['dnis22'] | currency:this.cS.selectedCurrency:'symbol-narrow' }} x {{ this.cS.dataStore?.sizepercents['dnis22'] | percent:'1.0' }}</td>
						</tr>
						<tr>
							<td class="pl-4">Gross SK Price >20mm / kg of DNIS</td>
							<td class="pl-4 posnumber">{{ +this.cS.dataStore?.sizepricesinv['dnis20'] | currency:this.cS.selectedCurrency:'symbol-narrow' }} x {{ this.cS.dataStore?.sizepercents['dnis20'] | percent:'1.0' }}</td>
						</tr>
						<tr>
							<td class="pl-4">Gross SK Price >18mm / kg of DNIS</td>
							<td class="pl-4 posnumber">{{ +this.cS.dataStore?.sizepricesinv['dnis18'] | currency:this.cS.selectedCurrency:'symbol-narrow' }} x {{ this.cS.dataStore?.sizepercents['dnis18'] | percent:'1.0' }}</td>
						</tr>
					</ng-container>
					<tr *ngIf="+this.cS.dataStore?.cultivarbonus>0">
						<td class="pl-4">Cultivar Bonus / kg of DNIS</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.cultivarbonus | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.darkprice>0">
						<td class="pl-4">Gross Dark Price / kg of DNIS</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.darkprice / +this.cS.dataStore?.dnis | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					<tr>
						<td><strong>Total Gross Price / kg of DNIS</strong></td>
						<td class="posnumber"><strong>{{ +this.cS.dataStore?.totalgrossprice / +this.cS.dataStore?.dnis | currency:this.cS.selectedCurrency:'symbol-narrow' }}</strong></td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					
					<!--
					<tr>
						<td>Gross Total Price</td>
						<td class="posnumber">{{ +this.cS.dataStore?.totalgrossprice | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					<tr>
						<td class="pl-4">Gross SK Price</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.grossprice | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					<tr>
						<td class="pl-4">Gross Darks Price</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.darkprice | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					<tr>
						<td>Total Fees</td>
						<td class="negnumber">({{ +this.cS.dataStore?.grossfee | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr>
						<td class="pl-4">Process Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.processfee | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.marketingfee>0">
						<td class="pl-4">Marketing Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.marketingfee | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.dryfee>0">
						<td class="pl-4">Drying Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.dryfee | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.transportfee>0">
						<td class="pl-4">Transport Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.transportfee | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr>
						<td>SAMAC Levy ({{ +this.cS.dataStore?.samacrate | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
						<td class="negnumber">({{ +this.cS.dataStore?.samacfee | currency:this.cS.selectedCurrency:'symbol-narrow' }})
						</td>
					</tr>
					-->
					
					<tr *ngIf="+this.cS.dataStore?.marketingfee>0">
						<td class="pl-4">Marketing Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.marketingfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.logisticsfee>0">
						<td class="pl-4">Logistics Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.logisticsfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.packagingfee>0">
						<td class="pl-4">Packaging Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.packagingfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.processingfee>0">
						<td class="pl-4">Kernel Processing Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.processingfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.transshipinsfee>0">
						<td class="pl-4">Transport, Shipping, Insurance</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.transshipinsfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.dryfee>0">
						<td class="pl-4">Drying Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.dryfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.transportfee>0">
						<td class="pl-4">Transport Fee</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.transportfee / +this.cS.dataStore?.dnis | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</td>
					</tr>
					<tr>
						<td class="pl-4" *ngIf="+this.cS.dataStore?.vatable == 1">SAMAC Levy (ex VAT)</td>
						<td class="pl-4" *ngIf="+this.cS.dataStore?.vatable != 1">SAMAC Levy (inc VAT)</td>
						<td class="pl-4 negnumber">({{ +this.cS.dataStore?.samacrate * (1 + (1 - +this.cS.dataStore?.vatable) * +this.cS.dataStore?.vatrate) | currency:this.cS.selectedCurrency:'symbol-narrow' }})
						</td>
					</tr>
						<tr>
							<td><strong>Total Fees</strong></td>
							<td class="negnumber"><strong>({{ (+this.cS.dataStore?.netfees / +this.cS.dataStore?.dnis) | number | currency:this.cS.selectedCurrency:'symbol-narrow' }})</strong></td>
						</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
					<!--
					<tr class="highlightedrow">
						<td><strong>Net Price</strong></td>
						<td class="posnumber"><strong>{{ +this.cS.dataStore?.netprice | currency:this.cS.selectedCurrency:'symbol-narrow' }}</strong></td>
					</tr>
					<tr *ngIf="+this.cS.dataStore?.volumebonus>0">
						<td class="pl-4">Volume Bonus</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.volumebonus | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr
					<tr *ngIf="checkedVAT" class="highlightedrow">
						<td><strong>Net Price (inc. VAT)</strong></td>
						<td class="posnumber"><strong>{{ +this.cS.dataStore?.netvat | currency:this.cS.selectedCurrency:'symbol-narrow' }}</strong></td>
					</tr>
					<tr *ngIf="checkedVAT">
						<td class="pl-4">VAT Payable</td>
						<td class="pl-4 posnumber">{{ +this.cS.dataStore?.vatpayable | currency:this.cS.selectedCurrency:'symbol-narrow' }}</td>
					</tr>
					-->
					<tr>
						<td><strong>Net Price / kg of DNIS</strong></td>
						<td class="posnumber"><strong>{{ +this.cS.dataStore?.netprice / +this.cS.dataStore?.dnis | currency:this.cS.selectedCurrency:'symbol-narrow' }}</strong></td>
					</tr>
					<tr class="highlightedrow">
						<td><strong>Net Price inc. VAT / kg of DNIS</strong></td>
						<td class="posnumber"><strong>{{ +this.cS.dataStore?.netvat / +this.cS.dataStore?.dnis | currency:this.cS.selectedCurrency:'symbol-narrow' }}</strong></td>
					</tr>
					<tr class="tableseperator"><td></td><td></td></tr>
				</tbody>
			</table>
		</div>
		<button mdbBtn class="btn btn-info" (click)="onNewCalculation()">New Calculation</button>
		<br>
		<br>
	</ng-template>

</div>

<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
	<app-login></app-login>
</div>