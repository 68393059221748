import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../auth/login/login.service';
import { PortalService } from '../../auth/portal.service';
import { MenuItem } from 'primeng/api';

declare var require: any;

@Injectable()
export class HeaderService{
  items: MenuItem[];
  enditems: MenuItem[];
  fullitems: MenuItem[];
  loginitems: MenuItem[];
  blank: MenuItem[] = [];

	constructor(
		private loginService: LoginService,
		private portalService: PortalService,
		private router: Router) {}

  onLogout() {
  	this.loginService.logOut();
  }

  isAuthenticated(option = null) {
    if (option==null) {
      return this.loginService.isAuthenticated();
    }
    if (this.portalService.portalMod()[option] || this.portalService.portalMod()['mod-all']) {
      return this.loginService.isAuthenticated();
    } return false;
  }

  isActive(option, setting, access = null) {
    var ans = false;
    if (this.isAuthenticated(option)) {
      for (var i = 0; i < this.portalService.portalMod()['settings'].length; i++) {
        if (this.portalService.portalMod()['settings'][i]['setting']==setting) {
          ans = (this.portalService.portalMod()['settings'][i]['value']==1);
        }
      }
      ans = (access==null ? ans : this.hasAccess(access));
    }
    return ans;
  }

  hasAccess(access = null, auth = null) {
    var ans = true;
    if (auth) {
      ans = this.isAuthenticated(auth)
    }
    if (access && ans) {
      ans = (this.loginService.selectedaccess=='full' || this.loginService.selectedaccess==access);
    }
    return ans;
  }

  refreshMenu(origin = null) {
  	if (origin) {
    	switch (origin) {
    		case "payments":
    		case "remittances":
    		case "invoicing":
					if (this.loginService.selectedaccess!=='full' && this.loginService.selectedaccess!=='finance') {
						this.router.navigate(['/overview/summary']);
					}
    			break;
    	}
    }
    this.loginitems = [
      {
        label: 'Log In',
        command: (event: Event) => { this.onLogout() }
      }
    ];
    this.items = [
      {
        label: 'Overview',
        items: [
          {
            label: 'Summary',
            routerLink: '/overview/summary',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Deliveries',
            routerLink: '/table/deliveries',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Samples',
            routerLink: '/table/samples',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Remittances',
            disabled: !this.hasAccess('finance'),
            visible: this.hasAccess('finance'),
            routerLink: '/table/remittances',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            label: 'Payments',
            disabled: !this.isActive('mod-invoice','Client Invoice Generator', 'finance'),
            visible: this.isActive('mod-invoice','Client Invoice Generator', 'finance'),
            routerLink: '/table/payments',
            routerLinkActiveOptions: {
               exact: true
            },
            styleClass: 'menuindent' 
          },
          {
            styleClass: 'lastseparator' 
          }
        ]
      },
      {
        label: 'Invoicing',
        disabled: !this.isActive('mod-invoice','Client Invoice Generator', 'finance'),
        visible: this.isActive('mod-invoice','Client Invoice Generator', 'finance'),
        routerLink: '/invoicing',
        routerLinkActiveOptions: {
           exact: true
        }
      },
      {
        label: 'Schedule',
        disabled: !this.isActive('mod-schedule','Client Bookings Calendar'),
        visible: this.isActive('mod-schedule','Client Bookings Calendar'),
        routerLink: '/schedule',
        routerLinkActiveOptions: {
           exact: true
        }
      },
      {
        label: 'Farm',
        disabled: !this.isActive('mod-map','Client Map Editor'),
        visible: this.isActive('mod-map','Client Map Editor'),
        routerLink: '/farm',
        routerLinkActiveOptions: {
           exact: true
        }
      },
      {
        label: 'Calculator',
        disabled: !this.hasAccess('finance', 'mod-calc'),
        visible: this.hasAccess('finance', 'mod-calc'),
        routerLink: '/calculator',
        routerLinkActiveOptions: {
           exact: true
        }
      }
    ];
    this.enditems = [
      {
        label: 'Log Out',
        styleClass: 'logoutlabel',
        command: (event: Event) => { this.onLogout() }
      }
    ];
    this.fullitems = [...this.items,...this.enditems];
  }

}