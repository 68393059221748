<div class="container">
	<div class="col-xs-12 col-sm-12 col-md-12 pt-3" *ngIf="isAuthenticated()">
		<br>
		<div class="row justify-content-between">
			<div class="col-xs-6 ml-3 main-header">
				<h2 *ngIf="!this.aDS.editmode">Add Delivery</h2>
				<h2 *ngIf="this.aDS.editmode">Edit {{ (this.aDS.tempstatus=='draft' ? 'Draft ' : '') }} Delivery</h2>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="!this.aDS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/deliveries">View Deliveries</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(true)">Edit Delivery</button>
			</div>
			<div class="mr-4 ml-3 col-auto" *ngIf="this.aDS.editmode">
				<button mdbBtn class="btn btn-info" routerLink="/team/table/deliveries">View Deliveries</button>
				<button mdbBtn class="btn btn-secondary" (click)="editModeOn(false)">Add Delivery</button>
			</div>
		</div>
		<br>

		<div class="col col-lg-9 col-xl-7">
		 	
			<div class="form-container">	
			  
			  <mat-form-field *ngIf="this.aDS.editmode">
    			<mat-label>Delivery Number</mat-label>
			    <mat-select placeholder="Delivery Number" [(ngModel)]="this.aDS.delivery" (keydown.tab)="loadForEdit()">
			      <mat-option *ngFor="let delivery of this.dDS.dropdown.deliveryList" #prod (click)="loadForEdit()" [value]="delivery.iddelivery">{{ delivery.iddelivery | delivery }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <br *ngIf="this.aDS.editmode">
			  
			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Season</mat-label>
			    <input matInput type="number" placeholder="Season" [(ngModel)]="this.aDS.season">
			  </mat-form-field>

			  <mat-form-field *ngIf="!this.aDS.editmode">
    			<mat-label>Location</mat-label>
			    <mat-select placeholder="Location" [(ngModel)]="this.dDS.location['idlocation']">
			      <mat-option *ngFor="let location of this.dDS.dropdown.locationList" [value]="location.idlocation">{{ location.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

				<mat-form-field *ngIf="this.aDS.editmode && this.editSelected()">
    			<mat-label>Location</mat-label>
			    <mat-select placeholder="Location" [(ngModel)]="this.aDS.location">
			      <mat-option *ngFor="let location of this.dDS.dropdown.locationList" [value]="location.idlocation">{{ location.name }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="!this.aDS.editmode">
    			<mat-label>Delivery Number</mat-label>
			    <input matInput type="number" placeholder="Delivery Number" [(ngModel)]="this.dDS.getNewDeliveryNumber" value="{{ this.dDS.getNewDeliveryNumber }}">
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && +this.aDS.delivery>0">
    			<mat-label>Delivery Number</mat-label>
			    <input matInput type="number" placeholder="Delivery Number" [(ngModel)]="this.aDS.iddelivery" value="{{ this.aDS.iddelivery }}">
			  </mat-form-field>

			  <ng-container *ngIf="editSelected() && (this.aDS.iddeldepot>0 || this.aDS.iddepot>0)">
			    
				  <mat-form-field>
    				<mat-label>Depot Date</mat-label>
				    <input matInput [matDatepicker]="depotdate" [(ngModel)]="this.aDS.depotdate" (ngModelChange)="this.aDS.fixDate('depotdate')">
						<mat-datepicker-toggle matIconSuffix [for]="depotdate"></mat-datepicker-toggle>
				    <mat-datepicker #depotdate></mat-datepicker>
				  </mat-form-field>

				  <mat-form-field>
    				<mat-label>Load Date</mat-label>
				    <input matInput [matDatepicker]="deploaddate" [(ngModel)]="this.aDS.deliverydate" (ngModelChange)="this.aDS.fixDate('deliverydate')">
						<mat-datepicker-toggle matIconSuffix [for]="deploaddate"></mat-datepicker-toggle>
				    <mat-datepicker #deploaddate></mat-datepicker>
				  </mat-form-field>

			  </ng-container>

			  <hr *ngIf="editSelected() && !(this.aDS.iddeldepot>0 || this.aDS.iddepot>0)">

			  <mat-form-field *ngIf="editSelected() && !(this.aDS.iddeldepot>0 || this.aDS.iddepot>0)">
    			<mat-label>Delivery Date</mat-label>
			    <input matInput [matDatepicker]="deldate" [(ngModel)]="this.aDS.deliverydate" (ngModelChange)="this.aDS.fixDate('deliverydate')">
					<mat-datepicker-toggle matIconSuffix [for]="deldate"></mat-datepicker-toggle>
			    <mat-datepicker #deldate></mat-datepicker>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Time</mat-label>
    			<input matInput
           name="selected_time"
           [format]=24
           [(ngModel)]="this.aDS.deliverytime"
           [ngxMatTimepicker]="picker"
           placeholder="12:00"
           />
			    <mat-icon matSuffix (click)="picker.open()">watch_later</mat-icon>
			    <ngx-mat-timepicker #picker></ngx-mat-timepicker>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && !(this.aDS.iddeldepot>0 || this.aDS.iddepot>0)">
    			<mat-label>Loading Date</mat-label>
			    <input matInput [matDatepicker]="loaddate" [(ngModel)]="this.aDS.loaddate" (ngModelChange)="this.aDS.fixDate('loaddate')">
					<mat-datepicker-toggle matIconSuffix [for]="loaddate"></mat-datepicker-toggle>
			    <mat-datepicker #loaddate></mat-datepicker>
			  </mat-form-field>

			  <ng-container *ngIf="this.aDS.iddeldepot>0 || this.aDS.iddepot>0">
			  
			  	<hr *ngIf="editSelected	()">

				  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Depot Location</mat-label>
				    <mat-select placeholder="Depot Location" [(ngModel)]="this.aDS.iddepot">
			    		<mat-option (click)="clearFields()" value="0">Direct</mat-option>
				      <mat-option *ngFor="let depot of this.dDS.dropdown.depotList" [value]="depot.idlocation">{{ depot.name }}</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Depot Delivery Number</mat-label>
				    <input matInput type="number" placeholder="Depot Delivery Number" [(ngModel)]="this.aDS.iddeldepot" [value]="this.aDS.iddeldepot">
				  </mat-form-field>

			  </ng-container>

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Producer</mat-label>
			    <mat-select placeholder="Producer" [(ngModel)]="this.aDS.producer" (keydown.tab)="updateContacts(this.aDS.producer)">
			      <mat-option *ngFor="let producer of this.dDS.dropdown.producerList" #prod (click)="updateContacts(prod.value)" [value]="producer.idcompany">{{ producer.companyname }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected() && this.dDS.getFarmId(this.aDS.producer)!=null">
    			<mat-label>Farm ID</mat-label>
			    <mat-select placeholder="Farm ID" [(ngModel)]="this.aDS.producer" (keydown.tab)="updateContacts(this.aDS.producer)">
			      <ng-container *ngFor="let producer of this.dDS.dropdown.producerList">
			      	<mat-option *ngIf="producer.farmid!=null" #prod (click)="updateContacts(prod.value)" [value]="producer.idcompany">{{ producer.farmid }}</mat-option>
			      </ng-container>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Contact Name</mat-label>
			    <mat-select placeholder="Contact Name" [(ngModel)]="this.aDS.thisCompanyContact">
			      <mat-option *ngFor="let contact of this.aDS.thisCompanyContacts"  [value]="contact?.idcontact">{{ contact.firstname | titlecase }} {{ contact.lastname | titlecase }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Farmer Delivery Reference (Optional)</mat-label>
			    <input matInput placeholder="Farmer Delivery Reference (Optional)" [(ngModel)]="this.aDS.iddelfarm" value="{{ this.aDS.iddelfarm }}">
			  </mat-form-field>

				<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.aDS.selectout" *ngIf="editSelected() && isAuthenticated('mod-selectouts')" (ngModelChange)="this.aDS.setSelectout()">
	        Select-Outs?
	      </mat-slide-toggle> 

			  <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Cultivar</mat-label>
			    <mat-select placeholder="Cultivar" [(ngModel)]="this.aDS.cultivar">
			      <mat-option *ngFor="let cult of this.dDS.dropdown.cultivarList" [value]="cult.idcultivar">{{ cult.cultivar }}</mat-option>
			    </mat-select>
			  </mat-form-field> 

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>{{ (isAuthenticated('mod-smalldelivery') ? 'Scale WNIS (kg) - inc. Smalls' : 'Scale WNIS (kg)' )}}</mat-label>
			    <input matInput type="number" placeholder="{{ (isAuthenticated('mod-smalldelivery') ? 'Scale WNIS (kg) - inc. Smalls' : 'Scale WNIS (kg)' )}}" (keyup)="this.aDS.setwnis()" (blur)="this.aDS.setwnis()" [(ngModel)]="this.aDS.scalewnis">
			  </mat-form-field>		

			  <mat-form-field *ngIf="(editSelected() && isAuthenticated('mod-smalldelivery'))">
    			<mat-label>Smalls (kg)</mat-label>
			    <input matInput type="number" placeholder="Smalls (kg)" (keyup)="this.aDS.setwnis()" (blur)="this.aDS.setwnis()" [(ngModel)]="this.aDS.smalls">
			  </mat-form-field>		

			  <mat-form-field *ngIf="(editSelected() && isAuthenticated('mod-smalldelivery'))">
    			<mat-label>Total WNIS (kg)</mat-label>
			    <input matInput type="number" placeholder="Total WNIS (kg)" [disabled]=true [(ngModel)]="this.aDS.wnis">
			  </mat-form-field>
				 
			  <ng-container *ngIf="editSelected()">

					<hr>

				  <mat-form-field>
	    			<mat-label>NIS >18mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >18mm (kg)" [(ngModel)]="this.aDS.d18" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>NIS >20mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >20mm (kg)" [(ngModel)]="this.aDS.d20" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>NIS >22mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >22mm (kg)" [(ngModel)]="this.aDS.d22" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>NIS >25mm (kg)</mat-label>
				    <input matInput type="number" placeholder="NIS >25mm (kg)" [(ngModel)]="this.aDS.d25" (keyup)="this.aDS.sizednis()" (blur)="this.aDS.sizednis()">
				  </mat-form-field>

				  <mat-form-field>
	    			<mat-label>Total Sized NIS (kg)</mat-label>
				    <input matInput type="number" placeholder="Total Sized NIS (kg)" [disabled]=true [(ngModel)]="this.aDS.d1825">
				  </mat-form-field>

				</ng-container>

			  <ng-container *ngIf="editSelected()">

			  	<ng-container *ngIf="this.aDS.iddeldepot>0 || this.aDS.iddepot>0">

				  	<hr>

					  <mat-form-field>
    					<mat-label>Dispatch WNIS (kg)</mat-label>
					    <input matInput type="number" placeholder="Dispatch WNIS (kg)" [(ngModel)]="this.aDS.depotwnisd">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Accepted WNIS (kg)</mat-label>
					    <input matInput type="number" placeholder="Accepted WNIS (kg)" [(ngModel)]="this.aDS.depotwnis">
					  </mat-form-field>

					  <mat-form-field>
    					<mat-label>Smalls (kg)</mat-label>
					    <input matInput type="number" placeholder="Smalls (kg)" [(ngModel)]="this.aDS.depotsmalls">
					  </mat-form-field>
					  
					  <mat-slide-toggle class="slider-margin slider-label" [(ngModel)]="this.aDS.dehusking" *ngIf="editSelected()">
			        Dehusking?
			      </mat-slide-toggle> 

			      <br>

					  <mat-form-field *ngIf="this.aDS.dehusking">
    					<mat-label>Nuts in Husk (kg)</mat-label>
					    <input matInput type="number" placeholder="Nuts in Husk (kg)" [(ngModel)]="this.aDS.huskmass">
					  </mat-form-field>

					  <mat-form-field *ngIf="this.aDS.dehusking">
    				<mat-label>Reject from Husking (kg)</mat-label>
					    <input matInput type="number" placeholder="Reject from Husking (kg)" [(ngModel)]="this.aDS.rejecthuskmass">
					  </mat-form-field>

			  	</ng-container>

				  <div class="col px-0" *ngFor="let store of this.aDS.storages; let storeIndex=index;">

				  	<hr>

						<div class="col-9 px-0" style="display: inline-block;">
							<div class="form-container">
							  		  
							  <mat-form-field>
    							<mat-label>Storage</mat-label>
							    <mat-select placeholder="Storage" [(ngModel)]="this.aDS.storages[storeIndex]['idstorage']">
							    	<ng-container *ngFor="let storage of this.dDS.dropdown.storageListWNIS">
							      	<mat-option *ngIf="this.aDS.checkType(storeIndex, storage.idstorage)" value="{{ storage.idstorage }}">{{ storage.type | titlecase }} {{ storage.name }}</mat-option>
							      </ng-container>
							    </mat-select>
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-3 px-0" style="display: inline-block;">
							<div class="form-container">
							  
								<button mat-icon-button class="col" (click)="this.aDS.addItem(1)"><i class="material-icons addbutton">add_circle_outline</i></button>

							</div>
						</div>

						<div class="col-9 px-0" style="display: inline-block;">
							<div class="form-container">	
							  
							  <mat-form-field>
    							<mat-label>Load WNIS (kg)</mat-label>
					    		<input matInput type="number" placeholder="Load WNIS (kg)" [disabled]="this.aDS.lastStorage(storeIndex)" (ngModelChange)="this.aDS.setwnis(2, storeIndex, $event)" [(ngModel)]="this.aDS.storages[storeIndex]['wnis']">
							  </mat-form-field>

							</div>
						</div>
							 
						<div class="col-3 px-0" style="display: inline-block;">
							<div class="form-container">

						  	<button mat-icon-button class="col" *ngIf="this.aDS.storages.length>1" (click)="this.aDS.removeItem(storeIndex)"><i class="material-icons removebutton">remove_circle_outline</i></button>

							</div>
						</div>

					</div>
				</ng-container>

				<hr *ngIf="editSelected()">

				<mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.aDS.collected" *ngIf="editSelected()">
	        Collected by {{ this.pS.portalMod()['portal'] }}
	      </mat-slide-toggle>  

	      <mat-slide-toggle class="slider-margin slider-label my-4" [(ngModel)]="this.aDS.paidCollection" *ngIf="editSelected()">
	        Paid Collection
	      </mat-slide-toggle>  

				<hr *ngIf="editSelected()">

				<mat-form-field *ngIf="editSelected()">
    			<mat-label>Price Model</mat-label>
			    <mat-select placeholder="Price Model" [(ngModel)]="this.aDS.pricemodel" (keydown.tab)="this.dDS.setPModel(this.aDS.pricemodel)">
			    	<mat-option value="0" (click)="this.dDS.setPModel()">Default</mat-option>
			      <mat-option *ngFor="let price of this.dDS.dropdown.priceModelList" [value]="price.modelno" (click)="this.dDS.setPModel(price.modelno)">{{ price.model }}</mat-option>
			    </mat-select>
			  </mat-form-field>

			  <ng-container *ngIf="isAuthorised('finance')">

				  <mat-form-field *ngIf="editSelected()">
    				<mat-label>Payment Terms</mat-label>
				    <mat-select placeholder="Payment Terms" [(ngModel)]="this.dDS.payterms">
				      <mat-option value="default">Default</mat-option>
				      <mat-option value="custom">Custom</mat-option>
				    </mat-select>
				  </mat-form-field>

				  <ng-container *ngIf="this.dDS.payterms=='custom'">
			      <div *ngFor="let item of this.dDS.items; let itemindex=index">

			      	<hr>

							<div class="row justify-content-between">
								<div class="col-xs-6 ml-3">
									<p>Payment - {{ itemindex + 1 }}</p>
								</div>
								<div *ngIf="this.pS.portalMod()['batch']" class="mr-4 ml-3">
								  <mat-slide-toggle class="slider-margin slider-label pb-3" [(ngModel)]="this.dDS.items[itemindex]['estimate']">
						        Based on estimate?
						      </mat-slide-toggle>  
								</div>
							</div>

							<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <mat-form-field>
	    							<mat-label>Period Type</mat-label>
								    <mat-select placeholder="Period Type" [(ngModel)]="this.dDS.items[itemindex]['type']">
								      <mat-option value="#">Specific Date</mat-option>
								      <mat-option value="m">Months</mat-option>
										  <mat-option value="w">Weeks</mat-option>
								      <mat-option value="d">Calendar Days</mat-option>
								      <mat-option value="b">Business Days</mat-option>
								    </mat-select>
								  </mat-form-field>

								  <mat-form-field *ngIf="this.dDS.items[itemindex]['type'] == 'm'">	
	    							<mat-label>Occurence in Month</mat-label>			    
								    <mat-select placeholder="Occurence in Month" [(ngModel)]="this.dDS.items[itemindex]['order']">
								      <mat-option value="f">First</mat-option>
								      <mat-option value="s">Second</mat-option>
								      <mat-option value="t">Third</mat-option>
								      <mat-option value="l">Last</mat-option>
											<mat-option value="x">Specific Day(s)</mat-option>
								    </mat-select>
								  </mat-form-field>
								  
								  <mat-form-field *ngIf="this.dDS.items[itemindex]['type'] == '#'">
	    							<mat-label>Date</mat-label>
								    <input matInput [matDatepicker]="specificdate" [(ngModel)]="this.dDS.items[itemindex]['timingDate']">
										<mat-datepicker-toggle matIconSuffix [for]="specificdate"></mat-datepicker-toggle>
								    <mat-datepicker #specificdate></mat-datepicker>
								  </mat-form-field>

								  <mat-form-field *ngIf="this.dDS.items[itemindex]['type'] != '#' && this.dDS.items[itemindex]['type'] != 'm'">
	    							<mat-label>Days after {{ this.dDS.items[itemindex]['datetype'] | titlecase }}</mat-label>
								    <input matInput type="number" min="0" step="1" placeholder="Days after {{ this.dDS.items[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.dDS.items[itemindex]['timing']">
								  </mat-form-field>

								  <mat-form-field *ngIf="this.dDS.items[itemindex]['type'] == 'm'">
	    							<mat-label>Months after {{ this.dDS.items[itemindex]['datetype'] | titlecase }}</mat-label>
								    <input matInput type="number" min="0" step="1" placeholder="Months after {{ this.dDS.items[itemindex]['datetype'] | titlecase }}" [(ngModel)]="this.dDS.items[itemindex]['timing']">
								  </mat-form-field>

								  <mat-form-field *ngIf="(this.dDS.items[itemindex]['type'] != '#' && this.dDS.items[itemindex]['type'] != 'm' && this.dDS.items[itemindex]['type'] != '')">
	  								<mat-label>Date Anchor</mat-label>
								    <mat-select placeholder="Date Anchor" [(ngModel)]="this.dDS.items[itemindex]['datetype']">
								      <mat-option value="delivery">From Delivery Date</mat-option>
								      <mat-option value="sample">From Sample Date</mat-option>
								      <ng-container *ngIf="itemindex>0">
								      	<ng-container *ngFor="let p of this.dDS.items; let pindex=index">
								      		<ng-container *ngIf="pindex<itemindex">
								      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
								      		</ng-container>
								      	</ng-container>
								      </ng-container>
								    </mat-select>
								  </mat-form-field>

								</div>
							</div>

							<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <mat-form-field>
	    							<mat-label>{{ (this.pS.portalMod()['batch'] ? (this.dDS.items[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}</mat-label>
								    <input matInput type="number" min="0" max="100" step="10" placeholder="{{ (this.pS.portalMod()['batch'] ? (this.dDS.items[itemindex]['estimate'] ? 'Percent of Estimate' : 'Percent of Total') : 'Percent') }}" [(ngModel)]="this.dDS.items[itemindex]['percent']">
								  </mat-form-field>

								  <mat-form-field *ngIf="(this.dDS.items[itemindex]['type'] == 'm' && this.dDS.items[itemindex]['order'] !== 'x') || this.dDS.items[itemindex]['type'] == 'b' || this.dDS.items[itemindex]['type'] == 'd' || this.dDS.items[itemindex]['type'] == 'w'">				    
	    							<mat-label>On Day</mat-label>
								    <mat-select placeholder="On Day" [(ngModel)]="this.dDS.items[itemindex]['weekday']">
										  <mat-option *ngIf="this.dDS.items[itemindex]['type'] != 'm'" value="0">Any</mat-option>
										  <mat-option *ngIf="this.dDS.items[itemindex]['type'] != '#' && this.dDS.items[itemindex]['type'] != 'm'" value="x">Specific Days of the Month</mat-option>
								      <mat-option value="1">Monday</mat-option>
								      <mat-option value="2">Tuesday</mat-option>
								      <mat-option value="3">Wednesday</mat-option>
								      <mat-option value="4">Thursday</mat-option>
								      <mat-option value="5">Friday</mat-option>
								      <mat-option value="6">Saturday</mat-option>
								      <mat-option value="7">Sunday</mat-option>
								    </mat-select>
								  </mat-form-field>

									 <mat-form-field *ngIf="this.dDS.items[itemindex]['type'] !== '#' && ((this.dDS.items[itemindex]['type']=='m' && this.dDS.items[itemindex]['order']=='x') || this.dDS.items[itemindex]['weekday']=='x')">
	  								<mat-label>Day(s) of Month (comma separated)</mat-label>
								    <input matInput placeholder="Day(s) of Month (comma separated)" [(ngModel)]="this.dDS.items[itemindex]['mdate']">
								  </mat-form-field>

								  <mat-form-field *ngIf="this.dDS.items[itemindex]['type'] == ''">
	  								<mat-label>Date Anchor</mat-label>
								    <mat-select placeholder="Date Anchor" [(ngModel)]="this.dDS.items[itemindex]['datetype']">
								      <mat-option value="delivery">From Delivery Date</mat-option>
								      <mat-option value="sample">From Sample Date</mat-option>
								      <ng-container *ngIf="itemindex>0">
								      	<ng-container *ngFor="let p of this.dDS.items; let pindex=index">
								      		<ng-container *ngIf="pindex<itemindex">
								      			<mat-option value="p{{pindex + 1}}">From Payment Date No. {{ pindex + 1 }}</mat-option>
								      		</ng-container>
								      	</ng-container>
								      </ng-container>
								    </mat-select>
								  </mat-form-field>
								  
								  <div class="row">
									  <button mat-icon-button class="col" (click)="this.dDS.addItem()"><i class="material-icons addbutton">add_circle_outline</i></button>
									  <button mat-icon-button class="col" *ngIf="this.dDS.items.length>1" (click)="this.dDS.removeItem(itemindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
									</div>

								</div>
							</div>
						</div>
					</ng-container>

				</ng-container>

			  <ng-container *ngIf="editSelected() && (this.aDS.iddeldepot>0 || this.aDS.iddepot>0)">

					<hr>

			  	<ng-container>
			      <p>Tag Serial Numbers:</p>
			      <div *ngFor="let tag of this.dDS.tags; let tagindex=index">
							<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <mat-form-field>
    								<mat-label>Tag Serial Number {{ tagindex + 1 }}</mat-label>
								    <input matInput type="text" min="0" step="1" placeholder="Tag Serial Number {{ tagindex + 1 }}" [(ngModel)]="this.dDS.tags[tagindex]['serial']">
								  </mat-form-field>

								</div>
							</div>

							<div class="col-6" style="display: inline-block;" *ngIf="editSelected()">
								<div class="form-container">

								  <div class="row">
									  <button mat-icon-button class="col" (click)="this.dDS.addTag()"><i class="material-icons addbutton">add_circle_outline</i></button>
									  <button mat-icon-button class="col" *ngIf="this.dDS.tags.length>1" (click)="this.dDS.removeTag(tagindex)"><i class="material-icons removebutton">remove_circle_outline</i></button>
									</div>

								</div>
							</div>
						</div>
					</ng-container>

			  </ng-container>

	      <hr *ngIf="editSelected()">

			  <mat-form-field *ngIf="editSelected()">
    			<mat-label>Notes</mat-label>
			    <textarea matInput placeholder="Notes" [(ngModel)]="this.aDS.note"></textarea>
			  </mat-form-field>
			  
			</div>

			<hr *ngIf="editSelected()">

			<button *ngIf="!this.aDS.editmode" mdbBtn class="btn btn-primary" [disabled]="!canSave()" (click)="saveDelivery()">Save</button>

			<button *ngIf="this.aDS.editmode && this.editSelected() && this.aDS.tempstatus=='draft'" mdbBtn class="btn btn-primary" [disabled]="!canUpdate()" (click)="this.saveChanges()">Accept</button>

			<button *ngIf="this.aDS.editmode && this.editSelected() && this.aDS.tempstatus!='draft'" mdbBtn class="btn btn-primary" [disabled]="!canUpdate()" (click)="this.saveChanges()">Update</button>

			<button *ngIf="this.aDS.editmode && this.editSelected()" mdbBtn class="btn btn-danger" (click)="onDelete()">Delete</button>

			<br><br>
		</div>

	</div>

	<div class="col-xs-12 col-sm-10 col-md-7 col-sm-offset-1 col-md-offset-2 pt-3" *ngIf="!isAuthenticated()">
		<app-teamlogin></app-teamlogin>
	</div>
</div>